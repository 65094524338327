.edit-panel.sirius .MuiTreeItem-label {
  height: 34px;
  font-size: 14px;
  display: flex;
  align-items: center;
  transition: 0.4s ease;
}

.edit-panel.sirius .MuiTreeItem-group .Mui-selected {
  /* transition: 0.4s ease;- */
  background-color: #e7f7f3 !important;
  color: #068965 !important;
  font-weight: 600 !important;

  > .MuiTreeItem-label {
    font-weight: 600 !important;
  }
}
