/*!
 * settings css for react v.17
 *
 * Builder UI Setting에 따라 강제로 변경해야하는 css에 대한 정의
 * 
 *   Author: Bizentro
 *   Date: 2021-02
 */

/*
 * Component Drop 영역 미 표시 - none-dropZoneDisplay
 * -----------------------------------------------------------------------------------
 */
/*drop 영역 hidden 처리*/
.edit-panel .page.none-dropZoneDisplay .drop-zone,
.edit-panel.preview-panel .drop-zone {
  display: none;
}
/*첫번째 drop 영역은 display*/
.edit-panel .page.none-dropZoneDisplay > .drop-zone {
  display: block;
}

/* 모든 height과 padding을 초기화 함 (Grid 제외)*/
.edit-panel
  .page.none-dropZoneDisplay
  [class*="editor-"]:not([class*="editor-grid-"]),
.edit-panel.preview-panel [class*="editor-"]:not([class*="editor-grid-"]) {
  min-height: 26px;
  padding: 0px;
}
/* runtime과 유사하게 필요한 항목만 padding을 부여한다. start ***/
/* column 간 공간*/
.edit-panel .page.none-dropZoneDisplay .editor-columns .editor-column,
.edit-panel.preview-panel .editor-columns .editor-column {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.edit-panel
  .page.none-dropZoneDisplay
  .alpha-grid
  .editor-columns
  .editor-column,
.edit-panel.preview-panel .alpha-grid .editor-columns .editor-column {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

/* .edit-panel .page.none-dropZoneDisplay .alpha-grid .form-group.row,
.edit-panel.preview-panel .alpha-grid .form-group.row {
  border-bottom: 1px solid #dadada;
} */
.edit-panel .page.none-dropZoneDisplay .alpha-grid .editor-row,
.edit-panel.preview-panel .alpha-grid .editor-row {
  border-bottom: 1px solid #dadada !important;
}

.edit-panel .page.none-dropZoneDisplay .alpha-grid .form-group .form-label,
.edit-panel.preview-panel .alpha-grid .form-group .form-label {
  min-height: 36px;
  border-right: 1px solid #dadada;
  border-left: 1px solid #dadada;
}

.edit-panel .page.none-dropZoneDisplay .alpha-grid .form-group .input-col,
.edit-panel.preview-panel .alpha-grid .form-group .input-col {
  padding-top: 3px;
  padding-right: 4px;
  padding-left: 4px;
}

/*  검색은 Padding 10px */
.edit-panel
  .page.none-dropZoneDisplay
  .search-wrap
  .editor-columns
  .editor-column {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
}
.edit-panel .page.none-dropZoneDisplay .search-wrap {
  margin-bottom: 16px;
}

/* alpha-grid style일 경우 column 간 선*/
.edit-panel .page.none-dropZoneDisplay .alpha-grid .editor-column {
  border-bottom: 1px solid #dadada;
  border-right: 1px solid #dadada;
}

.edit-panel
  .page.none-dropZoneDisplay
  .editor-columns
  .editor-column
  .editor-mui-button,
.edit-panel.preview-panel .editor-columns .editor-column .editor-mui-button {
  padding-left: 5px !important;
}

.edit-panel .page-container.none-dropZoneDisplay .editor-headling {
  align-items: end;
}

/*
 * Component 선 미 표시 - none-lineDisplay (Grid 제외)
 * -----------------------------------------------------------------------------------
 */
.edit-panel
  .page.none-lineDisplay
  [class*="editor-"]:not([class*="editor-grid-"]),
.edit-panel.preview-panel [class*="editor-"]:not([class*="editor-grid-"]) {
  border: 0px;
  height: 100%;
}
.edit-panel .page.none-lineDisplay .form-group.row,
.edit-panel.preview-panel .form-group.row {
  height: 100%;
}
/*
 * Component watermark 미 표시 - none-watermarkDisplay
 * -----------------------------------------------------------------------------------
 */
.edit-panel .page.none-watermarkDisplay [class*="builder-"],
.edit-panel.preview-panel [class*="builder-"] {
  background-image: unset !important;
}

/*
 * Component toolbar 미 표시 - none-toolbarDisplay
 * -----------------------------------------------------------------------------------
 */
.edit-panel .page.none-toolbarDisplay .comp-toolbar,
.edit-panel.preview-panel .comp-toolbar {
  display: none !important;
}

/*
 * Component title 미 표시 - none-titleDisplay
 * -----------------------------------------------------------------------------------
 */
.edit-panel .page.none-titleDisplay .comp-title,
.edit-panel.preview-panel .comp-title {
  display: none !important;
}

/*
 * 미리보기
 * -----------------------------------------------------------------------------------
 */
.edit-panel.preview-panel .alpha-grid {
  border-bottom: none !important;
  border-left: none !important;
}

.edit-panel.preview-panel .page-container {
  display: inherit;
}
.edit-panel.preview-panel .editor-base {
  cursor: default;
}
.edit-panel.preview-panel .editor-headling {
  pointer-events: none;
}

.edit-panel.preview-panel,
.edit-panel.preview-panel .page-container {
  width: 100%;
}

.edit-panel.preview-panel .page-container {
  height: unset;
}
.edit-panel.preview-panel .page-container .page .layout-collapse {
  height: unset !important;
  min-height: unset !important;
  border: unset !important;
}

.edit-panel.preview-panel .page-container .page .layout-collapse::before {
  display: none !important;
}

.edit-panel.preview-panel
  .page-container
  .page
  .layout-collapse
  > [class*="editor-"] {
  display: unset !important;
}
