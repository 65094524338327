/** popup **/
.edit-panel.sirius .ui-modal-footer {
  border: none;
  background: transparent;
  position: relative;
  left: 50%;
  bottom: 37px;
  padding: 0px 32px 0px 32px;
  transform: translateY(-100%) translateX(-50%);
  max-height: 100%;
  height: auto;
}

.edit-panel.sirius .ui-modal-footer .editor-column {
  border: 1px solid #bcb0b0;
}
.edit-panel .sirius .editor-form-group {
  height: auto;
}
.edit-panel.sirius .page-container.popup-container {
  padding-top: 0.5rem;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  padding-top: 32px;
  padding-right: 32px;
  padding-bottom: 100px;
  padding-left: 32px;
  overflow: hidden;
  border-radius: 12px;
}

/** label **/
.edit-panel.sirius .form-group .search-form-label {
  text-align: left !important;
  white-space: nowrap;
  padding-right: 6px;
  font-size: 13px;
  color: #747474;
  font-weight: 500;
}

.edit-panel.sirius .form-group .search-form-label.required:after {
  content: "*";
  margin-left: 3px;
  color: #f04349;
  font-weight: 500;
  font-size: 12px;
}
.edit-panel.sirius .form-group .save-form-label {
  background: #ffffff;
  text-align: left !important;
  font-size: 13px;
  color: #747474;
  font-weight: 500;
}
.edit-panel.sirius .form-group .save-form-label.required:after {
  content: "*";
  margin-left: 3px;
  color: #f04349;
  font-weight: 500;
  font-size: 12px;
}
.edit-panel.sirius .form-group .form-label {
  margin-bottom: 6px;
  padding-top: 4px;
  text-align: left;
  font-size: 14px;
  color: #747474;
  font-weight: 600;
}

.edit-panel.sirius .number-type .form-group .form-label {
  text-align: right !important;
}

.edit-panel.sirius .form-group .form-depend-label.required:after {
  content: "*";
  margin-left: 3px;
  color: #f04349;
  font-weight: 500;
  font-size: 12px;
}

.icon-popup-body {
  margin-top: 5px;
  max-height: 520px;
  overflow: auto;
}

.icon-popup-body p {
  margin-top: 5px;
  font-size: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
}

/* .edit-panel .sirius .form-check .form-check-input {
  margin-right: 15px;
  margin-left: 0px;
} */

.edit-panel .sirius {
  /* font-family: "Pretendard"; */
  font-family: "Pretendard", Pretendard, -apple-system, BlinkMacSystemFont,
    "Noto Sans KR", sans-serif !important;
}

.edit-panel .sirius .form-control {
  height: 38px;
  border-radius: 8px;
  padding-left: 16px;
  border: 1px solid #dfdfdf;
}

.edit-panel .sirius .form-control:disabled,
.edit-panel .sirius .form-control[readonly] {
  background-color: #f5f5f5;
}

.edit-panel .sirius .input-group .form-control {
  border-right: none;
}

.edit-panel.sirius .text-control {
  height: 38px;
  border-radius: 8px;
}

.edit-panel.sirius .input-group .btn {
  z-index: 0;
}

.edit-panel.sirius .heading-wrap {
  display: flex;
}
