.edit-panel.sirius .editor-step-wizard .step-wrap .step-item.on {
  background: #068965;
}
.edit-panel.sirius .editor-step-wizard .step-wrap .step-item {
  background: #e7f7f3;
}

.edit-panel.sirius
  .editor-step-wizard
  .step-wrap
  .step-item.on
  .cont-step
  .tit-step {
  color: #e7f7f3 !important;
}
.edit-panel.sirius
  .editor-step-wizard
  .step-wrap
  .step-item
  .cont-step
  .tit-step {
  color: #068965 !important;
}

.edit-panel.sirius .editor-step-wizard .step-wrap .step-item .no-step {
  color: #068965 !important;
}

/*button*/

.edit-panel .editor-step-wizard.step-simple .step-wizard-buttons .btn {
  height: 38px;
  border-radius: 8px;
  color: #ffffff;
}
