/*!
 * Entity Builder CSS for react v.17
 * 
 * Entity Builder에서 사용하는 main css
 * - main 화면 (top, left, right sidebar ...)
 * - Entity Builder editor
 *
 *   Author: Bizentro
 *   Date: 2021-02
 */
.control-sidebar.entity {
  position: absolute;
  z-index: 1010;
  top: 50px;
  right: 0;
  width: 360px;
  background: #3c3c3c;
  color: #b8c7ce;
  transition: 0.5s;
  display: block;
  & .tab-content {
    background-color: #222222;
  }
}

.control-sidebar .sidebar-item {
  display: flex;
  -webkit-user-select: none;
  user-select: none;
  align-items: flex-start;
  align-content: flex-start;
  line-height: 1.5;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: 0.3rem;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 1px solid #303030;
  background-color: inherit;
  color: inherit;
  box-shadow: inherit;
}
.entity-sidebar {
  height: calc(100vh - 50px - 60px);
}

.entity-sidebar .mt-3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.entity-sidebar .mt-3 label {
  width: 100%;
  max-width: 45%;
  margin-bottom: 0 !important;
  color: white;
}

.control-sidebar .data-Model-content {
  width: 100%;
  height: calc(100vh - 50px - 40px);
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.control-sidebar .table-list {
  border-top: 1px solid #303030;
  overflow-y: auto;
  width: 100%;
  height: calc(100vh - 50px - 40px);
}
.table-list .table-filter {
  width: 100%;
  padding: 0 5px;
  display: flex;
  align-items: center;
}
.table-list::-webkit-scrollbar {
  width: 3px;
  background-color: lightgray;
}
.table-list::-webkit-scrollbar-thumb {
  background-color: gray;
}
.table-list::-webkit-scrollbar-track {
  background-color: #303030;
}
.control-sidebar .table-list .table-wrapper {
  width: 100%;
  border-bottom: 1px solid #303030;
  background-color: #252526;
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  padding: 10px 0px 10px 20px;
  color: white;
}

@keyframes condemned_blink_effect {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

span.blink {
  animation: 1.5s linear infinite condemned_blink_effect;
}
.blink {
  animation: 1.5s linear infinite condemned_blink_effect;
}

.warning-text {
  color: tomato;
}

/* resizeable */

.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="white" transform="rotate(90)" class="bi bi-arrows-angle-expand" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707z"/></svg>');
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}

.patch-wrapper {
  min-height: 600px;
}
.patch-wrapper .step-title {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  gap: 15px;
  align-items: center;
  transition: 0.5s;
}
.patch-wrapper .step-title.step1 {
  color: tomato;
}

.patch-wrapper .step-title.step1.connected {
  transition: 0.5s;
  color: limegreen;
}
.patch-wrapper .step1-desc {
  display: flex;
  align-items: center;
  gap: 15px;
}

.patch-wrapper .step2-desc {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.patch-wrapper .step2-desc .desc {
  display: flex;
}
.patch-wrapper .step2-desc .desc .entity {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 350px;
}
.patch-wrapper .step2-desc .desc .entity .title {
  font-weight: 500;
  font-size: 15px;
}

.patch-wrapper .step2-desc .desc .entity .step {
  font-size: 15px;
  color: tomato;
  transition: 0.3s;
}

.patch-wrapper .step2-desc .desc .entity .step.done {
  font-weight: bold;
  color: limegreen;
  transition: 0.3s;
}
.patch-wrapper .step2-desc .desc .arrow {
  width: 100px;
}
.patch-wrapper .step2-desc .desc .data-model {
  width: calc(100% - 350px - 200px);
  display: flex;
  justify-content: space-between;
}
.patch-wrapper .step2-desc .desc .data-model .input {
  width: 40%;
  display: flex;
  align-items: flex-end;
  gap: 20px;
}
.patch-wrapper .step2-desc .desc .data-model .input div:last-child {
  flex: 0 0 50%;
}
.patch-wrapper .step2-desc .desc .data-model .action {
  width: 40%;
  display: flex;
  align-items: flex-end;
  gap: 10px;
  justify-content: flex-end;
}

.patch-wrapper .step2-desc .process {
  width: 100%;
  display: flex;
}
.patch-wrapper .step2-desc .process .entity-wrapper {
  height: 500px;
  width: 350px;
}
.patch-wrapper .step2-desc .process .entity-wrapper .entity-list {
  margin-top: 15px;
  height: 450px;
  border: 1px solid #3c3c3c;
  overflow-y: auto;
  border-radius: 5px;
}
.patch-wrapper .step2-desc .process .entity-wrapper .entity-list .empty {
  width: 100%;
  height: 100%;
  background-color: #3c3c3c88;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-scroll::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: lightgray;
}
.custom-scroll::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 50px;
}
.custom-scroll::-webkit-scrollbar-track {
  background-color: lightgray;
  border-radius: 50px;
}

.patch-wrapper .step2-desc .process .entity-wrapper .entity-list .entity {
  height: 30px;
  border-top: 1px solid lightgray;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-weight: 500;
  cursor: pointer;
  gap: 10px;
}

.patch-wrapper .step2-desc .process .entity-wrapper .entity-list .entity:hover {
  background-color: gray;
}

.patch-wrapper
  .step2-desc
  .process
  .entity-wrapper
  .entity-list
  .entity.selected {
  background-color: #0096cc;
  color: white;
  border-top: 1px solid #0b5ed7;
  border-bottom: 1px solid #0b5ed7;
}
.patch-wrapper .step2-desc .arrow {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.patch-wrapper .step2-desc .data-model-list {
  width: calc(100% - 350px - 200px);
}
.patch-wrapper .step2-desc .data-model-list .empty {
  background-color: #3c3c3c88;
  display: flex;
  border-radius: 15px;
  height: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.field-compare {
}
.field-compare .highlight {
  color: #0096cc;
}
.field-compare .field-list {
  width: 100%;
  height: 500px;
  border: 1px solid;
  border-radius: 5px;
  overflow-y: auto;
  box-shadow: 1px 1px 3px gray;
}

.field-compare .field-list .field-item {
  height: 30px;
  border-top: 1px solid lightgray;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-weight: 500;
  cursor: pointer;
  gap: 10px;
}
.field-compare .field-list .field-item:hover {
  background-color: lightgray;
}
.field-compare .field-list .field-item.selected {
  background-color: #0096cc;
  color: white;
  border-top: 1px solid #0b5ed7;
  border-bottom: 1px solid #0b5ed7;
}
.field-compare .compare-area {
  border: 1px solid #3c3c3c;
  height: 520px;
  border-radius: 5px;
}
.field-compare .compare-area .header.field-row {
  border-bottom: 1px solid #3c3c3c;
}
.field-compare .compare-area .field-row {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  height: 30px;
}

.field-compare .compare-area .field-row:hover {
  background-color: lightgray;
}

.field-compare .compare-area .field-row.last {
  border-bottom: 1px solid lightgray;
}

.field-compare .compare-area .field-row div {
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid lightgray;
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
}
.field-compare .compare-area .field-row div:first-child {
  border: none;
}
.field-compare .compare-area .info {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.field-compare .compare-area .body {
  height: calc(100% - 30px);
  overflow-y: overlay;
  overflow-x: hidden;
}

/* 신규 데이터 모델 라이브러리 react flow 관련 스타일*/

.reactflow-wrapper {
  width: calc(100% - 360px);
  height: calc(100vh - 50px);
  transition: 0.5s;
}
.reactflow-wrapper.sidebar-collapse {
  width: 100%;
  transition: 0.5s;
}
/* 
.react-flow__node-selectorNode {
  font-size: 12px;
  background: #eee;
  border: 1px solid #555;
  border-radius: 5px;
  text-align: center;
} */

.floatingedges {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  /* height: 100%; */
}
.reactflow-wrapper .flow-editor-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}
.floatingedges .react-flow__handle {
  opacity: 0;
}
.react-flow__node-default,
.react-flow__node-input,
.react-flow__node-output,
.react-flow__node-group {
  padding: 0 !important;
}

.flow-editor-wrapper .editor-loading-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reactflow-wrapper .flow-entity-list {
  position: absolute;
  width: 200px;
  height: 200px;
  background: lightgray;
  z-index: 5;
  margin: 15px;
  border-radius: 5px;
  border: 1px solid gray;
  box-shadow: 1px 1px 3px black;
  color: #3c3c3c;
  transition: 0.3s;
}
.reactflow-wrapper .flow-entity-list.flow-collapse {
  height: 30.5px;
  transition: 0.3s;
}

.reactflow-wrapper .flow-entity-list .header {
  font-weight: bold;
  padding: 5px;
  border-bottom: 1px solid #3c3c3c;
  height: 30.5px;
  display: flex;
  justify-content: space-between;
}

.reactflow-wrapper .flow-entity-list.flow-collapse .header {
  border-bottom: none;
}

.reactflow-wrapper .flow-entity-list .header span {
  cursor: pointer;
  user-select: none;
}
.reactflow-wrapper .flow-entity-list .body-list {
  max-height: 170px;
  overflow-y: auto;
  padding-bottom: 10px;
}
.reactflow-wrapper .flow-entity-list .body-list .node {
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 3px;
  cursor: pointer;
}
.reactflow-wrapper .flow-entity-list .body-list .node:hover {
  color: white;
  background-color: #3c3c3c;
  border-radius: 3px;
}

.reactflow-wrapper .entity-table {
  background-color: #333333;
  color: white;
  border: 2px solid #181f23;
  position: relative;
  min-width: 330px;
  border-radius: 5px;
  box-shadow: 3px 3px 7px gray;
}
.reactflow-wrapper .entity-table.mini {
  width: auto !important;
  display: flex;
  font-size: 30px;
  font-weight: bold;
  border: 2px solid;
  padding: 10px;
  border-radius: 6px;
  gap: 20px;
  background: #3c3c3c;
  color: white;
}

.reactflow-wrapper .entity-table.active {
  outline: 5px solid #0b5ed7;
}
.reactflow-wrapper.dark .entity-table.active {
  outline: 5px solid white;
}

.reactflow-wrapper .entity-table .hover-panel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #3c3c3ccc;
  cursor: default;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 35px;
  gap: 10%;
}

.reactflow-wrapper .entity-table .hover-panel.fold {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #3c3c3ccc;
  cursor: default;
  display: block;
  flex-direction: auto;
  justify-content: auto;
  align-items: auto;
  padding-top: none;
  gap: none;
}

.reactflow-wrapper .entity-table .hover-panel div {
  width: 60%;
  max-width: 150px;
  height: 150px;
  max-height: 40%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: 700;
  border-radius: 5px;
  box-shadow: 1px 1px 3px lightgray;
  cursor: pointer;
  transition: 0.2s;
}
.reactflow-wrapper .entity-table .hover-panel.fold div {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.reactflow-wrapper .entity-table .hover-panel div:hover {
  font-size: 28px;
}

.reactflow-wrapper .entity-table .table-toolbar {
  height: auto;
  position: absolute;
  width: 100%;
  bottom: calc(100% + 7px);
  display: flex;
  justify-content: space-between;
}

.reactflow-wrapper .entity-table .table-toolbar .entity-memo {
  width: 100%;
  position: absolute;
  height: 200px;
  top: calc((200px + 100%) * -1);
  background-color: beige;
  box-shadow: 1px 2px 3px black;
  border-radius: 5px;
  transition: 0.3s;
  color: #3c3c3c;
}

.reactflow-wrapper .entity-table .table-toolbar .entity-memo .header {
  height: 30px;
  border-bottom: 1px solid lightgray;
  display: flex;
  justify-content: flex-end;
}
.reactflow-wrapper .entity-table .table-toolbar .entity-memo .header button {
  border: none;
  background-color: beige;
}

.reactflow-wrapper .entity-table .table-toolbar .entity-memo.hidden {
  width: 0;
  height: 0;
  visibility: hidden;
  transition: 0.3s;
}
.reactflow-wrapper .entity-table .table-toolbar .entity-memo.hidden * {
  display: none;
}
.reactflow-wrapper
  .entity-table
  .table-toolbar
  .entity-memo
  .description-wrapper {
  height: calc(100% - 30px - 40px);
}

.reactflow-wrapper
  .entity-table
  .table-toolbar
  .entity-memo
  .description-wrapper
  .description {
  min-width: 190px;
  min-height: 90px;
  height: 100%;
  white-space: pre-wrap;
  overflow-y: auto;
  word-break: break-word;
}

.reactflow-wrapper
  .entity-table
  .table-toolbar
  .entity-memo
  .description-wrapper
  textarea.description {
  border-width: 2px;
  width: 100%;
  height: 100%;
  overflow: auto;
  resize: none;
}
.reactflow-wrapper .entity-table .table-toolbar .entity-memo .button-wrapper {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
}
.reactflow-wrapper
  .entity-table
  .table-toolbar
  .entity-memo
  .button-wrapper
  .memo-info {
  display: flex;
  gap: 5px;
}

.reactflow-wrapper .entity-table .table-toolbar .side {
  padding: 3px;
  display: flex;
  background-color: dodgerblue;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.reactflow-wrapper.dark .entity-table .table-toolbar .side {
  background-color: white;
  color: #3c3c3c;
}
.reactflow-wrapper .entity-table .table-toolbar .side.left {
  font-weight: 700;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.reactflow-wrapper .entity-table .table-toolbar .side.right {
}

.reactflow-wrapper .entity-table .table-toolbar .side.right span {
  cursor: pointer;
  padding: 0 4px;
}

.reactflow-wrapper .entity-table .title {
  background-color: #181f23;
  display: flex;
  height: 40px;
  padding: 10px;
  font-size: 15px;
}
.reactflow-wrapper .entity-table .title div {
  min-width: 50%;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
}
.reactflow-wrapper .entity-table .title div:first-child {
  padding-right: 10px;
}
.reactflow-wrapper .entity-table .title div:last-child {
  padding-left: 10px;
}
.reactflow-wrapper .entity-table .entity-field-body {
  background-color: #3c3c3c;
}

.reactflow-wrapper .edit-tab {
  width: 100%;
  background-color: #f8f8f8;
  padding: 0.2rem;
  display: flex;
  height: 30px;
  justify-content: space-between;
  padding-right: 30px;
}
.reactflow-wrapper .edit-tab .side {
  display: flex;
  gap: 10px;
}
.reactflow-wrapper .edit-tab .right.side .list-button {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reactflow-wrapper .edit-tab .right.side .program-id-label {
  display: flex;
  height: 100%;
  align-items: center;
}

.reactflow-wrapper .entity-table .entity-field-body .header {
  width: 100%;
  display: flex;
  font-size: 15px;
  background-color: #252526;
}
.reactflow-wrapper .entity-table .entity-field-body .header div.isDraggable {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reactflow-wrapper .entity-table .entity-field-body .header div.status {
  width: 80px;
  display: flex;
  justify-content: center;
  border-right: 1px solid black;
  padding: 5px 0;
}
.reactflow-wrapper .entity-table .entity-field-body .header div.column-name {
  width: calc(100% - 80px);
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
}
.reactflow-wrapper .entity-table .entity-field-body .header.service div {
  width: 45%;
}
.reactflow-wrapper .entity-table .entity-field-body .header.entity div {
  width: 50%;
}

.reactflow-wrapper .entity-table .entity-field-body .field-list {
  display: flex;
  flex-direction: column;
}

.reactflow-wrapper .entity-table .entity-field-body .field-list .field-row {
  background: transparents;
  border-top: 1px solid black;
  border-right: 1px solid black;
  color: white;
  height: 35px;
  display: flex;
}
.reactflow-wrapper
  .entity-table
  .entity-field-body
  .field-list
  .field-row.active {
  border: 2px dotted #3026d4;
}
.reactflow-wrapper.dark
  .entity-table
  .entity-field-body
  .field-list
  .field-row.active {
  background-color: gray;
  border-color: white;
  border-style: solid;
}
.reactflow-wrapper
  .entity-table
  .entity-field-body
  .field-list
  .field-row
  .isDraggable {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  border-right: 1px solid black;
  cursor: move;
}

.reactflow-wrapper
  .entity-table
  .entity-field-body
  .field-list
  .field-row
  .arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  border-right: 1px solid black;
  cursor: pointer;
}
.reactflow-wrapper
  .entity-table
  .entity-field-body
  .field-list
  .field-row
  .column-name {
  width: calc(100% - 85px);
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  font-size: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  text-align: left;
}
.reactflow-wrapper
  .entity-table
  .entity-field-body
  .field-list
  .field-row
  .column-name
  div:first-child {
  display: flex;
  gap: 10px;
}
.reactflow-wrapper
  .entity-table
  .entity-field-body
  .field-list
  .field-row
  .column-name
  div:last-child {
  display: flex;
  gap: 5px;
}

.reactflow-wrapper
  .entity-table
  .entity-field-body
  .field-list
  .field-row.service
  .arrow {
  width: 45%;
}

.reactflow-wrapper
  .entity-table
  .entity-field-body
  .field-list
  .field-row.service
  .column-name {
  width: 45%;
}

.reactflow-wrapper
  .entity-table
  .entity-field-body
  .field-list
  .field-row.service
  div:last-child {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reactflow-wrapper
  .entity-table
  .entity-field-body
  .field-list
  .field-row.entity
  .arrow {
  width: 50%;
}

.reactflow-wrapper
  .entity-table
  .entity-field-body
  .field-list
  .field-row.entity
  .column-name {
  width: 50%;
}

.relation-table {
  width: 100%;
  height: 200px;
  overflow: auto;
  background: white;
  border-radius: 5px;
  color: black;
}
.relation-table:hover {
  box-shadow: 1px 1px 5px #75b9ff;
}
.relation-table .relation-row {
  border-bottom: 1px solid lightgray;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  height: 35px;
}

.relation-table .relation-header {
  border-bottom: 1px solid lightgray;
}
.relation-table .relation-header div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.relation-table .columnNm {
  margin: 5px 0;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
}
.relation-table :not(.relation-header) .columnNm {
  padding-left: 4px;
}
.relation-table .columnNm span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.relation-table .button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 0;
}

.entity-code-wrapper {
  height: 100%;
  overflow-y: auto;
}
.entity-code-wrapper .edit-source {
  border: 1px solid #ddd;
  text-align: initial;
}
.entity-code-wrapper .edit-source .edit-source-options {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}

.join-field-choice {
}
.join-field-choice .join-entity-name {
  display: flex;
  align-items: center;
}
.join-field-choice .join-entity-name span {
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
}
.join-field-choice .join-from {
  display: flex;
  align-items: center;
}

.parameter_input_popup_body {
}
.parameter_input_popup_body .parameter_list_wrapper {
  overflow-y: auto;
}
.parameter_input_popup_body .parameter_list_wrapper .parameter_row {
  display: flex;
  border-bottom: 1px solid lightgray;
  padding: 5px 0;
}
.parameter_input_popup_body
  .parameter_list_wrapper
  .parameter_row
  .parameter_col {
  width: 100%;
  display: flex;
  justify-content: center;
}

.react-flow__panel.react-flow__attribution {
  display: none;
}

.entity-sidebar .form-select:disabled {
  background-color: #ccd1d4;
}
