.edit-panel.sirius .editor-grid {
  font-family: "Pretendard", Pretendard, -apple-system, BlinkMacSystemFont,
    "Noto Sans KR", sans-serif !important;
}

/*toolbar button*/
.edit-panel.sirius .editor-gird-toolbar-buttons > button {
  border-radius: 8px !important;
  border: 1px solid #dfdfdf !important;
  margin-left: 10px;
  height: 38px !important;
  padding: 6px 14px 6px 14px !important;
  font-size: 14px !important;
  color: #747474 !important;
}

/*grid border 잡기 -> 전체 wrap으로 border 생성할 경우, 파란색 selector 박스 짤림*/

.edit-panel.sirius .editor-grid-wrap {
  border-radius: 8px;
  padding: 0px;
  padding-top: 10px;
  padding-bottom: 2px;
}

.edit-panel.sirius .editor-grid-wrap > div {
  border-top: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf;
}
.edit-panel.sirius .uni-table .editor-grid-columns .editor-grid-header {
  border-top: none !important;
}

.edit-panel.sirius .editor-grid-wrap > div:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-left: 1px solid #dfdfdf;
}
.edit-panel.sirius .editor-grid-wrap > div:first-child > div:first-child {
  border-top-left-radius: 8px;
}
.edit-panel.sirius .editor-grid-wrap > div:first-child > div:last-child {
  border-bottom-left-radius: 8px;
}

.edit-panel.sirius .editor-grid-wrap > div.last-column {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-right: 1px solid #dfdfdf;
}
.edit-panel.sirius .editor-grid-wrap > div.last-column > div:first-child {
  border-top-right-radius: 8px;
}
.edit-panel.sirius .editor-grid-wrap > div.last-column > div:last-child {
  border-bottom-right-radius: 8px;
}

.edit-panel.sirius .editor-grid-column {
  border-bottom: none;
  background: transparent;
}

/*-------------------border end--------------------------*/

/*grid header
-----------------------------------
*/
.edit-panel.sirius .editor-grid-columns .editor-grid-header {
  background: #ebf0f2;
  color: #777777;
  font-weight: 600;
}

.edit-panel.sirius .editor-grid-header .grid-header-title .grid-label {
  padding-left: 16px;
}

.edit-panel.sirius .editor-grid-header .grid-header-title .grid-header-icon {
  background: transparent;
}

.edit-panel.sirius .editor-grid-columns:has(.grid-label.required) {
  background: #fff8e6; /* 원하는 배경 색상 */
}

/*grid Title*/
.edit-panel.sirius .editor-grid-title {
  align-items: center;
}

/*grid cell*/
.edit-panel.sirius .editor-grid-column:not(.grid-row-header) {
  padding-left: 16px;
  border-right: 1px solid #ebebeb;
}

/*grid cell*/
.edit-panel.sirius .editor-grid-column.read-only {
  background-color: #f5f5f5;
}

.edit-panel.sirius .grid-header-icon {
  background-color: transparent !important;
}

.edit-panel.sirius
  .editor-grid-columns:has(.editor-grid-header .grid-header-icon > *) {
  width: 60px !important;
}
