.edit-panel .sirius .form-check-input:checked {
  background-color: #009b71;
  border-color: #009b71;
}
.edit-panel .sirius .form-check .form-check-input {
  margin-right: 15px;
  margin-left: 0px;
}

.edit-panel.sirius .form-check {
  padding-left: 5px;
}
