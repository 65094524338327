.edit-panel .sirius .di-calender {
  background: url("../../../images/daafIcon/di-calender.svg") no-repeat center;
  background-size: 20px 20px;
}

.edit-panel .sirius .builder-singdatepicker .btn,
.edit-panel .sirius .builder-rangedatepicker .btn {
  border-radius: 8px;
  width: 44px;
  height: 38px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dfdfdf;
  border-left: none;
}

.edit-panel .sirius .builder-singdatepicker .readonly,
.edit-panel .sirius .builder-rangedatepicker .readonly {
  background-color: #f5f5f5;
}

.edit-panel
  .sirius
  .builder-singdatepicker
  input
  .edit-panel
  .sirius
  .builder-rangedatepicker
  input {
  border-right: none;
}
