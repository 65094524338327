/*!
 * sirius runtime css for react v.17
 *
 * Sirius Runtime환경의 CSS를 migration한 CSS
 * - Builder editor의 결과 화면의 design을 Runtime환경과 유사하게 만들기 위함
 * 
 *   Author: Bizentro
 *   Date: 2024-11
 */

/**
 * 팝업
 */
.sirius .pop_wrap .pop_head {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  color: #fff;
  font-size: 24px;
  min-height: auto;
  padding: 0;
}

.sirius .pop_wrap .page {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 18px;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 14px;
}

.sirius .pop_wrap .page::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(211, 211, 211, 0);
}
.sirius .pop_wrap .page::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 50px;
}
.sirius .pop_wrap .page::-webkit-scrollbar-track {
  background-color: lightgray;
  border-radius: 50px;
}

.sirius .pop_wrap .pop_head .tit_pop {
  color: #202020;
  font-size: 20px;
  font-weight: 700;
}

.sirius .pop_wrap .pop_head .btn_close {
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: 24px;
    color: #747474;
    text-align: center;
    line-height: 1rem;
  }
}

.sirius .search-wrap .editor-column,
.sirius .search-wrap .editor-row {
  background: #ffffff !important;
}

/**
* Form
*/
.sirius .tit-grid:before {
  background: none !important;
  width: 0px;
  height: 0px;
  padding-left: 0px;
}

/** input group**/

.sirius.input-group .form-group.row {
  margin: unset;
  margin-left: 0px;
  margin-right: 0px;
}

/** label **/
.sirius .alpha-grid .form-group .form-depend-label {
  background: #ffffff;
}
