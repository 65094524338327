/*!
 * common/main css for react v.17
 *
 * Application 공통으로 적용되는 모든 CSS
 * 
 *   Author: Bizentro
 *   Date: 2021-02
 */

@charset "utf-8";

@import url("./notosanskr-font.css");

/*common reset*/
html {
  font-size: 13px;
}
body,
button,
input,
select,
textarea {
  font-family: "Noto Sans KR", sans-serif;
  color: #333;
  font-size: 13px;
  outline: none;
}
html,
body {
  height: 100%;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
}
a {
  text-decoration: none;
  background-color: transparent;
}

a:hover,
a:active,
a:focus {
  outline: none;
  text-decoration: none;
  color: #72afd2;
}

/*common reset*/
html {
  font-size: 13px;
}
body,
button,
input,
select,
textarea {
  font-family: "Noto Sans KR", sans-serif;
  color: #333;
  font-size: 13px;
  outline: none;
}
html,
body {
  height: 100%;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
}
a {
  text-decoration: none;
  background-color: transparent;
}

a:hover,
a:active,
a:focus {
  outline: none;
  text-decoration: none;
  color: #72afd2;
}
/*
   * Common
   * -----------------------------------------------------------------------------------
   */
.float-right {
  float: right;
}
.float-left {
  float: left;
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

.fa-pr {
  padding-right: 0.4rem;
}

.whole-wrap {
  width: 100%;
  height: auto;
  display: flex;
  background: inherit;
  /*
  background: #fff;
  align-items: center;
  */
}

.w-10p {
  width: 10% !important;
}
.w-15p {
  width: 15% !important;
}
.w-20p {
  width: 20% !important;
}
.w-25p {
  width: 25% !important;
}
.w-30p {
  width: 30% !important;
}
.w-33p {
  width: 33% !important;
}
.w-40p {
  width: 40% !important;
}
.w-50p {
  width: 50% !important;
}
.w-60p {
  width: 60% !important;
}
.w-70p {
  width: 70% !important;
}
.w-80p {
  width: 80% !important;
}
.w-90p {
  width: 90% !important;
}
.w-full {
  width: 100% !important;
}
.pl-0 {
  padding-left: 0px !important;
}
.pl-5 {
  padding-left: 5px !important;
}
.ml-5 {
  margin-left: 5px !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.p-4 {
  padding: 4px !important;
}
.p-6 {
  padding: 6px !important;
}
.p-8 {
  padding: 8px !important;
}
.p-10 {
  padding: 10px !important;
}
.p-20 {
  padding: 20px !important;
}

.read-only {
  color: #c8c8c8 !important;
}

.display-unset {
  display: unset;
}
.no-padding {
  padding: 0 !important;
}
.hide {
  display: none;
}
.input-group {
  flex-wrap: unset !important;
}

.form-label.required:after {
  content: "*";
  top: 50%;
  right: 5px;
  margin-top: -7px;
  color: #f00;
  font-size: 14px;
  line-height: 14px;
  font-weight: 900;
}

.form-label {
  font-size: 13px;
}
.modal-body .form-label {
  font-weight: 600;
}

.button-group-sm > .btn,
.btn-sm {
  font-size: 13px;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.button-group .btn {
  flex: 0 0 auto;
  margin-left: 5px !important;
}
.button-group .btn:last-child {
  margin-right: 0;
}

.btn-outline-secondary.light-font-color {
  color: #efe9e9;
}

.form-select-sm,
.form-control-sm {
  font-size: 0.93rem;
}

.cm-editor {
  resize: vertical;
  overflow: auto !important;
}

/*
   * Page template
   * -----------------------------------------------------------------------------------
   */
.page-wrapper {
  background-color: #ecf0f5;
  height: calc(100vh - 52px);
}
.page-header {
  position: relative;
  padding: 15px 15px 0px 15px;
  background: transparent;
}
.page-header > h1 {
  margin: 0;
  font-size: 24px;
}

.page-header > .breadcrumb {
  float: right;
  background: transparent;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;
  padding: 7px 5px;
  position: absolute;
  top: 15px;
  right: 10px;
  border-radius: 2px;
}
.page-header > h1 > small {
  font-size: 15px;
  display: inline-block;
  padding-left: 4px;
  font-weight: 300;
}

.breadcrumb > li {
  color: #444;
  display: inline-block;
}

.breadcrumb > li.active {
  color: #777;
}
.breadcrumb > li span {
  cursor: pointer;
}
.page-header > .breadcrumb > li > a {
  color: #444;
  text-decoration: none;
  display: inline-block;
}
.page-header > .breadcrumb > li + li:before {
  content: ">\00a0";
}
.breadcrumb > li + li:before {
  padding: 0 5px;
  color: #ccc;
  content: "/\00a0";
}
.page-content {
  min-height: 250px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
}

/*
   * Template - box
   * -----------------------------------------------------------------------------------
   */

.box {
  position: relative;
  border-radius: 3px;
  background: #ffffff;
  border-top: 3px solid #d2d6de;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
}

.box.box-primary {
  border-top-color: #3c8dbc;
}

.box-header.with-border {
  border-bottom: 1px solid #f4f4f4;
}

.box-header:before,
.box-body:before,
.box-footer:before,
.box-header:after,
.box-body:after,
.box-footer:after {
  content: " ";
  display: table;
}
.box-header:after,
.box-body:after,
.box-footer:after {
  clear: both;
}

.box-header .box-title {
  display: inline-block;
  font-size: 18px;
  margin: 0;
  line-height: 1;
}

.box-header > .box-tools {
  position: absolute;
  right: 10px;
  top: 5px;
}

.box-body {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 10px;
  height: calc(100vh - 150px);
}
.box-body .box-control {
  padding: 5px;
  gap: 5px;
  display: flex;
  justify-content: flex-end;
}

.box-line {
  border-bottom: 1px solid #d1cdcd;
}

/*
   * Main
   * -----------------------------------------------------------------------------------
   */
.wrapper {
  height: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.wrapper:before,
.wrapper:after {
  content: " ";
  display: table;
}

.wrapper:after {
  clear: both;
}

.layout-top-nav .content-wrapper,
.layout-top-nav .main-footer {
  margin-left: 0;
}

.content-wrapper {
  min-height: 100%;
  background-color: #fff;
  min-height: 500px;
  z-index: 800;
}

.content-wrapper .content {
  margin: 8px;
}
.main-footer {
  background: #fff;
  padding: 15px;
  color: #444;
  border-top: 1px solid #d2d6de;
}

.main-header {
  position: relative;
  max-height: 100px;
  z-index: 1030;
  width: 100%;
}

.main-header .navbar {
  margin-bottom: 0;
  border: none;
  min-height: 50px;
  border-radius: 0;
  /* margin-left: 50px; */
  width: 90%;
  padding-top: 0;
  padding-bottom: 0;
}
.main-header .navbar-left {
  width: 1250px;
  white-space: pre;
}

.main-header .navbar-left > ul > li {
  max-width: 30%;
}

.main-header .navbar-right {
  width: 400px;
  display: flex;
  justify-content: flex-end;
}
.main-header .navbar-right > ul > li {
  font-size: 12px;
  /* padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px; */
}
.main-header .navbar .nav {
  flex-direction: initial;
}
.main-header .navbar .nav > li > .link {
  color: #fff;
  line-height: 20px;
  position: relative;
  display: block;
  /*
    padding-left: 5px;
    padding-right: 5px;
    */
}
.main-header .navbar .nav > li > .link > .nav-button {
  font-size: 12px;
  padding: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* 
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px; */
}
.main-header .navbar .nav > li > .link > .nav-button.workspace-spaceName {
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
}
.main-header .navbar .nav > li > .link > .nav-button.connected {
  color: lime;
}
.main-header .navbar .nav > li > .link > .nav-button.connected.expired {
  color: tomato;
}
.main-header .navbar .nav > li > .link > .nav-button.disabled {
  color: rgb(192, 188, 188);
}

.main-header .navbar .nav > li > .link > .nav-button.caret:after {
  display: inline-block;
  margin-left: 1em;
  vertical-align: 0em;
  content: "";
  border-top: 0.5em solid;
  border-right: 0.5em solid transparent;
  border-bottom: 0;
  border-left: 0.5em solid transparent;
}
.main-header .navbar .nav > li > .link > .nav-button.user-button {
  max-width: 160px;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
}

.main-header .navbar .nav .toolbar-dropdown {
  position: absolute;
  z-index: 1000;
  display: block;
  min-width: 10rem;
  /*padding: 0.5rem 0;*/
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  /* border: 1px solid rgba(0, 0, 0, 0.15); */
  border-radius: 0.25rem;
}
.main-header .navbar .nav .toolbar-dropdown ul {
  padding-left: 0;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.main-header .navbar .nav .dropdown-item {
  display: flex;
  width: 100%;
  min-width: 150px;
  justify-content: space-between;
  padding: 0.3rem 1.8rem;
  clear: both;
  font-weight: 400;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  background: #252526;
  color: #fff;
}
.main-header .navbar .nav .dropdown-item:hover {
  background: #094771;
}
.main-header .navbar .nav .dropdown-item:first-child {
  padding-top: 0.8rem;
}

.main-header .navbar .nav .dropdown-item:last-child {
  padding-bottom: 0.8rem;
}

.main-header .navbar .nav > li .divider {
  width: 1px;
  background-color: #fff;
  height: 20px;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 5px;
}
.main-header .navbar .nav > li > .link > .btn-app {
  border-radius: 3px;
  position: relative;
  min-width: 30px;
  height: 30px;
  text-align: center;
  color: #666;
  border: 1px solid #ddd;
  background-color: #f4f4f4;
  font-size: 12px;
  margin-right: 5px;
  margin-left: 5px;
}
.main-header .navbar .nav > li:first-child > .link > .btn-app {
  margin-left: 20px;
}
.main-header .navbar .nav > li > .link > .btn-app:hover {
  background-color: #fff;
}

.main-header .navbar .nav > li.user > a > .fa,
.main-header .navbar .nav > li.user > a > .glyphicon,
.main-header .navbar .nav > li.user > a > .ion {
  margin-right: 5px;
}

.main-header .navbar .nav > li > a > .label {
  position: absolute;
  top: 9px;
  right: 7px;
  text-align: center;
  font-size: 9px;
  padding: 2px 3px;
  line-height: 0.9;
}

.main-header .logo {
  display: block;
  float: left;
  height: 50px;
  font-size: 20px;
  line-height: 50px;
  text-align: center;
  /* width: 50px; */
  width: 10%;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  /* padding: 0 15px; */
  font-weight: 300;
  overflow: hidden;
}

.main-header .logo .logo-mini {
  font-family: sans-serif;
  font-weight: 900;
  font-size: 16px;
  text-transform: uppercase;
  font-style: italic;
  letter-spacing: 0.08em;
  padding-right: 5px;
}

.main-header .logo .logo-mini img {
  width: 48px;
  padding: 4px;
}

.main-header .navbar-brand {
  color: #fff;
}

.content-header {
  position: relative;
  padding: 15px 15px 0 15px;
}

.main-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 50px;
  min-height: 100%;
  z-index: 903;
  width: 50px !important;
}

.sidebar {
  padding-bottom: 10px;
  position: relative;
}

.sidebar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebar-menu > li {
  position: relative;
  margin: 0;
  padding: 12px 5px 12px 7px;
}
.contenxt-menu-wrapper {
  position: absolute;
  top: 50px;
  left: 50px;
  min-width: 280px;
  height: calc(100vh - 50px);
  background-color: #1e282c;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-top: 1px solid #333;
  z-index: 902;
  transition: 0.5s;
  color: lightgray;
  user-select: none;
}

.contenxt-menu-wrapper.collapsed {
  position: absolute;
  left: -280px;
  z-index: 901;
  transition: 0.5s;
  opacity: 0;
}

.contenxt-menu-wrapper .loading-wrapper {
  width: 100%;
  height: calc(100vh - 72px);
  background-color: #3c3c3c11;
  position: absolute;
  right: 0px;
  color: #3c3c3c;
  display: flex;
  justify-content: center;
  align-items: center;
  color: lightgray;
}

.contenxt-menu-wrapper .loading-wrapper .loading-msg-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ecf0f5;
  color: #3c3c3c;
  padding: 20px;
  border-radius: 5px;
}

.contenxt-menu-wrapper .title {
  width: 100%;
  height: 40px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.contenxt-menu-wrapper .title div:first-child {
  display: flex;
  gap: 10px;
  font-size: 15px;
  align-items: center;
}
.contenxt-menu-wrapper .title div:last-child {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.contenxt-menu-wrapper .menu-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.contenxt-menu-wrapper .menu-list .context-menu {
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  padding-left: 15px;
  border-radius: 5px;
}
.contenxt-menu-wrapper .menu-list .context-menu:hover {
  background: #3c3c3c;
}
.contenxt-menu-wrapper .search-area {
  padding: 0 10px;
}
.contenxt-menu-wrapper .search-area input {
  background-color: lightgray;
}
.contenxt-menu-wrapper .sub-title {
  width: 100%;
  height: 20px;
  font-weight: 700;
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
}
.contenxt-menu-wrapper .sub-list {
  padding: 10px;
}
.contenxt-menu-wrapper .sub-list .work {
  gap: 5px;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  padding: 10px;
  padding-left: 15px;
  max-width: 230px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contenxt-menu-wrapper .sub-list .work div:first-child {
  width: 85%;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
}
.contenxt-menu-wrapper .sub-list .work button {
  background-color: transparent;
  color: lightgray;
  border: none;
}
.contenxt-menu-wrapper .sub-list .work button:hover {
  color: white;
  background-color: tomato;
  border-radius: 5px;
}

.contenxt-menu-wrapper .sub-list .work:hover {
  background-color: #3c3c3c;
}

.sidebar-menu > li > a {
  display: block;
}

.sidebar-menu li.header {
  padding: 10px 25px 10px 15px;
  font-size: 12px;
}

/* fixed ------*/
.fixed .wrapper {
  overflow: hidden;
}

.fixed .main-header,
.fixed .main-sidebar,
.fixed .left-side {
  position: fixed;
}

.fixed .main-header {
  top: 0;
  right: 0;
  left: 0;
}

.fixed .content-wrapper,
.fixed .right-side {
  padding-top: 50px;
  padding-left: 50px;
}
code {
  word-wrap: break-word;
  background-color: #f9f9f9;
  border: 1px solid #e1e1e1;
  border-radius: 2px;
  color: #666;
  display: block;
  font-family: Consolas;
  line-height: 1.45;
  margin: 0 0 10px;
  overflow: visible;
  padding: 8px;
  white-space: pre-wrap !important;
}

/*
    * Main Page spinner  (https://projects.lukehaas.me/css-loaders/)
    * -----------------------------------------------------------------------------------
    */
.load-container {
  position: relative;
  overflow: hidden;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  top: calc(50vh - 110px);
}
.load-container .circle-loader,
.load-container .circle-loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.load-container .circle-loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(192, 190, 190, 0.2);
  border-right: 1.1em solid rgba(192, 190, 190, 0.2);
  border-bottom: 1.1em solid rgba(192, 190, 190, 0.2);
  border-left: 1.1em solid #3c3c3c;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: circle 1.1s infinite linear;
  animation: circle 1.1s infinite linear;
}

@-webkit-keyframes circle {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes circle {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*
    * 문서 설명용 table
    * -----------------------------------------------------------------------------------
    */
table.doc-table {
  table-layout: fixed !important;
  caret-color: transparent;
  background-color: initial;
  border: 1px solid #ccc;
  border-collapse: collapse;
  border-spacing: 0;
  display: table;
  margin: 0 0 10px;
  max-width: 100%;
}

table.doc-table thead {
  border-bottom: 2px solid #333;
}
table.doc-table tr {
  border: 1px solid #efefef;
}

table.doc-table tr th,
table.doc-table tr td {
  background-clip: padding-box;
  border: 1px solid #e1e1e1;
  padding: 0.4em;
  height: 38px;
}

/*
    * icon
    * -----------------------------------------------------------------------------------
    */
@font-face {
  font-family: "u-icon";
  src: url("fonts/u-icon.eot?xabmpg");
  src: url("fonts/u-icon.eot?xabmpg#iefix") format("embedded-opentype"),
    url("fonts/u-icon.ttf?xabmpg") format("truetype"),
    url("fonts/u-icon.woff?xabmpg") format("woff"),
    url("fonts/u-icon.svg?xabmpg#u-icon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="u-icon-"],
[class*=" u-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "u-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.u-icon-SearchFormLayoutTemplate:before {
  content: "\e900";
}
.u-icon-FilterFormLayoutTemplate:before {
  content: "\e900";
}
.u-icon-GridLayoutTemplate:before {
  content: "\e901";
}
.u-icon-FormLayoutTemplate:before {
  content: "\e902";
}
.u-icon-Tree:before {
  content: "\e903";
}
.u-icon-PopupLayoutTemplate .path1:before {
  content: "\e904";
}
.u-icon-PopupLayoutTemplate .path2:before {
  content: "\e905";
  margin-left: -1em;
}
.u-icon-PopupLayoutTemplate .path3:before {
  content: "\e906";
  margin-left: -1em;
}
.u-icon-PopupLayoutTemplate .path4:before {
  content: "\e907";
  margin-left: -1em;
}
.u-icon-PopupLayoutTemplate .path5:before {
  content: "\e908";
  margin-left: -1em;
}
.u-icon-PopupLayoutTemplate .path6:before {
  content: "\e909";
  margin-left: -1em;
}
.u-icon-PopupLayoutTemplate .path7:before {
  content: "\e90a";
  margin-left: -1em;
}
.u-icon-PopupLayoutTemplate .path8:before {
  content: "\e90b";
  margin-left: -1em;
}
.u-icon-PopupLayoutTemplate .path9:before {
  content: "\e90c";
  margin-left: -1em;
}
.u-icon-PopupLayoutTemplate .path10:before {
  content: "\e90d";
  margin-left: -1em;
}
.u-icon-Container:before {
  content: "\e90e";
}
.u-icon-Row:before {
  content: "\e90f";
}
.u-icon-Column:before {
  content: "\e910";
}
.u-icon-Grid:before {
  content: "\e901";
}
.u-icon-Block:before {
  content: "\e911";
}
.u-icon-Form:before {
  content: "\e912";
}
.u-icon-Input:before {
  content: "\e913";
}
.u-icon-Select:before {
  content: "\e914";
}
.u-icon-Textarea:before {
  content: "\e915";
}
.u-icon-Button .path1:before {
  content: "\e916";
}
.u-icon-Button .path2:before {
  content: "\e917";
  margin-left: -1em;
}
.u-icon-Button .path3:before {
  content: "\e918";
  margin-left: -1em;
}
.u-icon-Checkbox .path1:before {
  content: "\e919";
}
.u-icon-Checkbox .path2:before {
  content: "\e91a";
  margin-left: -1em;
}
.u-icon-Checkbox .path3:before {
  content: "\e91b";
  margin-left: -1em;
}
.u-icon-Checkbox .path4:before {
  content: "\e91c";
  margin-left: -1em;
}
.u-icon-Checkbox .path5:before {
  content: "\e91d";
  margin-left: -1em;
}
.u-icon-RadioButton .path1:before {
  content: "\e91e";
}
.u-icon-RadioButton .path2:before {
  content: "\e91f";
  margin-left: -1em;
}
.u-icon-RadioButton .path3:before {
  content: "\e920";
  margin-left: -1em;
}
.u-icon-RadioButton .path4:before {
  content: "\e921";
  margin-left: -1em;
}
.u-icon-RadioButton .path5:before {
  content: "\e922";
  margin-left: -1em;
}
.u-icon-SingleDatePicker .path1:before {
  content: "\e923";
}
.u-icon-SingleDatePicker .path2:before {
  content: "\e924";
  margin-left: -1em;
}
.u-icon-SingleDatePicker .path3:before {
  content: "\e925";
  margin-left: -1em;
}
.u-icon-SingleDatePicker .path4:before {
  content: "\e926";
  margin-left: -1em;
}
.u-icon-SingleDatePicker .path5:before {
  content: "\e927";
  margin-left: -1em;
}
.u-icon-SingleDatePicker .path6:before {
  content: "\e928";
  margin-left: -1em;
}
.u-icon-RangeDatePicker .path1:before {
  content: "\e929";
}
.u-icon-RangeDatePicker .path2:before {
  content: "\e92a";
  margin-left: -1em;
}
.u-icon-RangeDatePicker .path3:before {
  content: "\e92b";
  margin-left: -1em;
}
.u-icon-RangeDatePicker .path4:before {
  content: "\e92c";
  margin-left: -1em;
}
.u-icon-RangeDatePicker .path5:before {
  content: "\e92d";
  margin-left: -1em;
}
.u-icon-RangeDatePicker .path6:before {
  content: "\e92e";
  margin-left: -1em;
}
.u-icon-RangeDatePicker .path7:before {
  content: "\e92f";
  margin-left: -1em;
}
.u-icon-RangeDatePicker .path8:before {
  content: "\e930";
  margin-left: -1em;
}
.u-icon-RangeDatePicker .path9:before {
  content: "\e931";
  margin-left: -1em;
}
.u-icon-RangeDatePicker .path10:before {
  content: "\e932";
  margin-left: -1em;
}
.u-icon-RangeDatePicker .path11:before {
  content: "\e933";
  margin-left: -1em;
}
.u-icon-FileUpload .path1:before {
  content: "\e934";
}
.u-icon-FileUpload .path2:before {
  content: "\e935";
  margin-left: -1em;
}
.u-icon-FileDownload .path1:before {
  content: "\e936";
}
.u-icon-FileDownload .path2:before {
  content: "\e937";
  margin-left: -1em;
}
.u-icon-Text:before {
  content: "\e938";
}
.u-icon-Heading:before {
  content: "\e939";
}
.u-icon-Tab .path1:before,
.u-icon-TabTemplate .path1:before,
.u-icon-TabContainer .path1:before {
  content: "\e93a";
}
.u-icon-Tab .path2:before,
.u-icon-TabTemplate .path2:before,
.u-icon-TabContainer .path2:before {
  content: "\e93b";
  margin-left: -1em;
}
.u-icon-Tab .path3:before,
.u-icon-TabTemplate .path3:before,
.u-icon-TabContainer .path3:before {
  content: "\e93c";
  margin-left: -1em;
}
.u-icon-Tab .path4:before,
.u-icon-TabTemplate .path4:before,
.u-icon-TabContainer .path4:before {
  content: "\e93d";
  margin-left: -1em;
}
.u-icon-Tab .path5:before,
.u-icon-TabTemplate .path5:before,
.u-icon-TabContainer .path5:before {
  content: "\e93e";
  margin-left: -1em;
}
.u-icon-Hyperlink:before {
  content: "\e93f";
}
.u-icon-Image .path1:before {
  content: "\e940";
}
.u-icon-Image .path2:before {
  content: "\e941";
  margin-left: -1em;
}
.u-icon-Image .path3:before {
  content: "\e942";
  margin-left: -1em;
}
.u-icon-Image .path4:before {
  content: "\e943";
  margin-left: -1em;
}
.u-icon-Image .path5:before {
  content: "\e944";
  margin-left: -1em;
}
.u-icon-Image .path6:before {
  content: "\e945";
  margin-left: -1em;
}
.u-icon-AMChart3WidgetTemplate .path1:before {
  content: "\e946";
}
.u-icon-AMChart3WidgetTemplate .path2:before {
  content: "\e947";
  margin-left: -1em;
}
.u-icon-AMChart3WidgetTemplate .path3:before {
  content: "\e948";
  margin-left: -1em;
}
.u-icon-AMChart3WidgetTemplate .path4:before {
  content: "\e949";
  margin-left: -1em;
}
.u-icon-AMChart3WidgetTemplate .path5:before {
  content: "\e94a";
  margin-left: -1em;
}
.u-icon-AMChart3WidgetTemplate .path6:before {
  content: "\e94b";
  margin-left: -1em;
}
.u-icon-AMChart3WidgetTemplate .path7:before {
  content: "\e94c";
  margin-left: -1em;
}
.u-icon-AMChart3WidgetTemplate .path8:before {
  content: "\e94d";
  margin-left: -1em;
}
.u-icon-AMChart3WidgetTemplate .path9:before {
  content: "\e94e";
  margin-left: -1em;
}
.u-icon-AMChart3WidgetTemplate .path10:before {
  content: "\e94f";
  margin-left: -1em;
}
.u-icon-AMChart3WidgetTemplate .path11:before {
  content: "\e950";
  margin-left: -1em;
}
.u-icon-AMChart3WidgetTemplate .path12:before {
  content: "\e951";
  margin-left: -1em;
}
.u-icon-AMChart3WidgetTemplate .path13:before {
  content: "\e952";
  margin-left: -1em;
}
.u-icon-AMChart3WidgetTemplate .path14:before {
  content: "\e953";
  margin-left: -1em;
}
.u-icon-AMChart3WidgetTemplate .path15:before {
  content: "\e954";
  margin-left: -1em;
}
.u-icon-HtmlTag:before {
  content: "\e955";
}
.u-icon-Table:before {
  content: "\e956";
}
.u-icon-StepWizardTemplate:before,
.u-icon-StepWizardContainer:before {
  content: "\e957";
}
.u-icon-InputGroupTemplate:before,
.u-icon-InputGroup:before {
  content: "\e958";
}
.u-icon-Excel:before {
  content: "\e959";
}

.u-icon-TextEditor .path1:before {
  content: "\e95a";
}
.u-icon-TextEditor .path2:before {
  content: "\e95b";
  margin-left: -1em;
}
.u-icon-TextEditor .path3:before {
  content: "\e95c";
  margin-left: -1em;
}
.u-icon-TextEditor .path4:before {
  content: "\e95d";
  margin-left: -1em;
}
.u-icon-WidgetContainer:before {
  content: "\e95e";
}
.u-icon-NumericMetricTemplate .path1:before {
  content: "\e95f";
}
.u-icon-NumericMetricTemplate .path2:before {
  content: "\e960";
  margin-left: -1em;
}
.u-icon-NumericMetricTemplate .path3:before {
  content: "\e961";
  margin-left: -1em;
}
.u-icon-NumericMetricTemplate .path4:before {
  content: "\e962";
  margin-left: -1em;
}
.u-icon-NumericMetricTemplate .path5:before {
  content: "\e963";
  margin-left: -1em;
}
.u-icon-NumericMetricTemplate .path6:before {
  content: "\e964";
  margin-left: -1em;
}
.u-icon-TextWidgetTemplate .path1:before {
  content: "\e965";
}
.u-icon-TextWidgetTemplate .path2:before {
  content: "\e966";
  margin-left: -1em;
}
.u-icon-TextWidgetTemplate .path3:before {
  content: "\e967";
  margin-left: -1em;
}
.u-icon-FormWidgetTemplate:before {
  content: "\e968";
}
.u-icon-ListWidgetTemplate:before {
  content: "\e969";
}
/*
.u-icon-ChartWidget .path1:before {
  content: "\e96a";
}
.u-icon-ChartWidget .path2:before {
  content: "\e96b";
  margin-left: -1em;
}
.u-icon-ChartWidget .path3:before {
  content: "\e96c";
  margin-left: -1em;
}
.u-icon-ChartWidget .path4:before {
  content: "\e96d";
  margin-left: -1em;
}
.u-icon-ChartWidget .path5:before {
  content: "\e96e";
  margin-left: -1em;
}
.u-icon-ChartWidget .path6:before {
  content: "\e96f";
  margin-left: -1em;
}
.u-icon-ChartWidget .path7:before {
  content: "\e970";
  margin-left: -1em;
}
.u-icon-ChartWidget .path8:before {
  content: "\e971";
  margin-left: -1em;
}
.u-icon-ChartWidget .path9:before {
  content: "\e972";
  margin-left: -1em;
}
.u-icon-ChartWidget .path10:before {
  content: "\e973";
  margin-left: -1em;
}
.u-icon-ChartWidget .path11:before {
  content: "\e974";
  margin-left: -1em;
}
.u-icon-ChartWidget .path12:before {
  content: "\e975";
  margin-left: -1em;
}
.u-icon-ChartWidget .path13:before {
  content: "\e976";
  margin-left: -1em;
}
.u-icon-ChartWidget .path14:before {
  content: "\e977";
  margin-left: -1em;
}
.u-icon-ChartWidget .path15:before {
  content: "\e978";
  margin-left: -1em;
}
*/

.setting-box {
  height: 100%;
  display: flex;
  gap: 30px;
}
.setting-box .tree-box {
  width: 300px;
  max-width: 15%;
  height: 100%;
  border-radius: 5px;
  padding-top: 5px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1%;
}

.setting-box .tree-box > input {
  height: 30px;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding-left: 5px;
}
.setting-box .setting-config-box {
  width: 100%;
  max-width: 80%;
  border: 1px solid lightgray;
  height: 100%;
  border-radius: 5px;
  padding: 5px;
  display: grid;
  grid-template-rows: 1fr 13fr 1fr;
  gap: 10px;
}
.setting-box .contents-row {
  width: 100%;
  display: flex;
  min-height: 10%;
  border-bottom: 1px solid lightgray;
}
.setting-box .contents-row > div {
  display: flex;
  align-items: center;
  padding-left: 5%;
}
.setting-box .contents-row .title {
  width: 30%;
}

.setting-box .contents-row .contents {
  width: 70%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.setting-box .contents-row .contents .disabled {
  cursor: not-allowed;
}
.setting-box .contents-row .contents input {
  border: 1px solid lightgray;
  border-radius: 8px;
  height: 70%;
  padding-left: 10px;
}
.setting-box .contents-row .contents select {
  min-width: 200px;
  border: 1px solid lightgray;
  border-radius: 8px;
  height: 70%;
}
.setting-box .setting-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: overlay;
  gap: 10px;
  padding-top: 10px;
}

.setting-box .setting-container.custom {
  gap: 10px;
}
.setting-box .setting-container.shortkey {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: overlay;
  padding: 0 20px;
}

.setting-box .setting-container.shortkey h4 {
  margin-top: 10px;
}
.setting-box .setting-container.shortkey .keyset {
  display: flex;
  flex-direction: column;
}
.setting-box .setting-container.shortkey .keyset .header {
  display: flex;
  height: 30px;
  border-bottom: 2px solid black;
}
.setting-box .setting-container.shortkey .keyset .header div {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: larger;
}
.setting-box .setting-container.shortkey .keyset .header div:first-child {
  padding-left: 10%;
  width: 30%;
}
.setting-box .setting-container.shortkey .keyset .header div:last-child {
  padding-left: 5%;
  width: 70%;
}
.setting-box .setting-container.list {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: overlay;
  padding: 0 20px;
}
.setting-box .setting-container.list .search-bar {
  display: flex;
  gap: 15px;
  height: 6%;
  max-height: 30px;
}
.setting-box .setting-container.list .search-bar span {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.setting-box .setting-container.list .search-bar select {
  width: 20% !important;
  border-color: lightgray;
}
.setting-box .setting-container.list .search-bar input {
  width: 20%;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding-left: 10px;
}

.setting-box .setting-container .application-detail {
  border: 1px solid lightgray;
  height: 560px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 3px 5px;
  border-radius: 5px;
}
.setting-box .setting-container .application-detail label {
  font-weight: bold;
}

.setting-box .setting-config-box .setting-header {
  width: 100%;
  font-size: larger;
  border-bottom: 2px solid black;
  display: flex;
  align-items: center;
  padding: 0px 5px;
}
.setting-box .setting-config-box .setting-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding-right: 20px;
  align-items: center;
}
.setting-box .setting-config-box .setting-button button {
  max-height: 35px;
}
.setting-box .setting-config-box .setting-button.split {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.setting-box .setting-config-box .setting-button.split div {
  display: flex;
  gap: 5px;
}

.setting-container .vertical-wrapper {
  width: 100%;
  height: 85%;
  display: flex;
  gap: 10px;
}
.vertical-wrapper .vertical-accordian {
  width: 80px;
  height: 100%;
  transition: 0.2s;
}
.vertical-wrapper .vertical-accordian.selected {
  width: calc(100% - 240px);
}
.vertical-wrapper .vertical-accordian .theme-title {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.vertical-wrapper .vertical-accordian .contents {
  height: calc(100% - 90px);
  border: 1px solid lightgray;
  background-color: rgb(180 180 180);
  color: white;
  border-radius: 5px;
}

.vertical-wrapper .vertical-accordian .contents .desc {
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  padding: 20px 0;
  align-items: center;
  font-weight: 700;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.vertical-wrapper .vertical-accordian .contents.chosen {
  background-color: #3c3c3c;
}
.vertical-wrapper .vertical-accordian .contents .desc:hover {
  background-color: white;
  color: #3c3c3c;
  cursor: pointer;
}

.total-search-wrapper {
  position: fixed;
  width: 100%;
  height: 0px;
  bottom: 0;
  left: 0;
  background: white;
  z-index: 1020;
  transition: 0.5s;
  border-top: 1px solid lightgray;
}
.total-search-wrapper.hidden {
  display: none;
}

.total-search-wrapper .control-bar {
  position: absolute;
  top: -15px;
  height: 15px;
  left: 10px;
  background: #5a5d5e;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  padding: 3px 0;
  box-shadow: 0px -1px 5px lightgray;
  display: none;
}
.total-search-wrapper .search-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  cursor: row-resize;
  height: 25px;
  user-select: all;
  background: #f1f3f4;
}
.total-search-wrapper .search-title::selection {
  color: black;
  background: white;
}
.total-search-wrapper .search-title .title {
  width: 100%;
  padding: 3px 10px;
  font-weight: 500;
  border-bottom: 1px solid #b7b7b7;
}
.total-search-wrapper .search-title .title::selection {
  color: black;
  background: transparent;
}

.total-search-wrapper .search-list-wrapper {
  width: 100%;
  height: 370px;
}

.load-wrapper {
  position: absolute;
  background-color: #ffffffdd;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3c3c3c;
  word-spacing: 3px;
  letter-spacing: 1px;
  flex-direction: column;
}
.load-wrapper .circle {
  padding: 20px;
  display: flex;
  align-items: center;
  color: #3c3c3c;
}
.load-wrapper .loadbar-wrapper {
  display: flex;
  gap: 5px;
  height: 0px;
  width: 120px;
  justify-content: center;
  align-items: flex-end;
}
.load-wrapper .loadbar-wrapper div {
  height: 20px;
  width: 20px;
  border: 1px solid lightgray;
  background-color: lightgray;
  border-radius: 100%;
}
@keyframes loadbar1 {
  0% {
    margin-bottom: 0;
  }
  10% {
    margin-bottom: 20px;
  }
  60% {
    margin-bottom: 0;
  }
}
@keyframes loadbar2 {
  10% {
    margin-bottom: 0;
  }
  20% {
    margin-bottom: 20px;
  }
  70% {
    margin-bottom: 0;
  }
}
@keyframes loadbar3 {
  20% {
    margin-bottom: 0;
  }
  30% {
    margin-bottom: 20px;
  }
  80% {
    margin-bottom: 0;
  }
}
@keyframes loadbar4 {
  30% {
    margin-bottom: 0;
  }
  40% {
    margin-bottom: 20px;
  }
  90% {
    margin-bottom: 0;
  }
}
@keyframes loadbar5 {
  40% {
    margin-bottom: 0;
  }
  50% {
    margin-bottom: 20px;
  }
  100% {
    margin-bottom: 0%;
  }
}
.load-wrapper .loadbar-wrapper div.bar1 {
  animation: loadbar1 1.5s infinite linear;
}
.load-wrapper .loadbar-wrapper div.bar2 {
  animation: loadbar2 1.5s infinite linear;
}
.load-wrapper .loadbar-wrapper div.bar3 {
  animation: loadbar3 1.5s infinite linear;
}
.load-wrapper .loadbar-wrapper div.bar4 {
  animation: loadbar4 1.5s infinite linear;
}
.load-wrapper .loadbar-wrapper div.bar5 {
  animation: loadbar5 1.5s infinite linear;
}

.Toastify__toast-body {
  word-break: break-word;
}

input[type="date"].history-date-picker {
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 3px;
  padding: 0 5px;
  color: gray;
  font-family: initial;
}

.custom-scroll::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: rgba(211, 211, 211, 0);
}
.custom-scroll::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 50px;
}
.custom-scroll::-webkit-scrollbar-track {
  background-color: lightgray;
  border-radius: 50px;
}
/* 가로 가운데 */
.hc {
  display: flex;
  justify-content: center;
}

/* 세로 가운데 */
.vc {
  display: flex;
  align-items: center;
}

.builder-cardLayout {
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
  border: 1px solid #ececec;
  overflow: initial;
  padding: 16px;
  display: table;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  background: #fff;
  margin-top: 6px;
  min-height: 50px;
}

@keyframes skeleton-loading {
  0% {
    background-position: -70% 0;
  }
  100% {
    background-position: calc(70% + 100%) 0;
  }
}

.skeleton {
  background: linear-gradient(90deg, #e0e0e0 25%, #f5f5f5 50%, #e0e0e0 75%);
  background-size: 70% 100%;
  animation: skeleton-loading 2.5s infinite linear;
}

@keyframes skeleton-loading-horizontal {
  0% {
    background-position: 0% -70%;
  }
  100% {
    background-position: 0 calc(70% + 100%);
  }
}

.skeleton.horizontal {
  background: linear-gradient(0deg, #e0e0e0 25%, #f5f5f5 50%, #e0e0e0 75%);
  background-size: 100% 70%;
  animation: skeleton-loading-horizontal 2.5s infinite linear;
}
