.edit-panel.sirius .form-select {
  height: 38px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.edit-panel.sirius .form-select.sirius {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23212529' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e")
    no-repeat;
  /* no-repeat 98% 50%/15px auto; */
  background-size: 16px 12px;
  background-position: right 0.75rem center;
  height: 100%;
  color: #202020;
}

.edit-panel.sirius .custom-select-list {
  position: absolute;
  font-size: 14px;
  width: 100%;
  height: auto;
  z-index: 15;
  border: solid 1px #c4ccd8;
  background-color: #ffffff;
  text-align: left;
  margin-top: 1px;
  box-sizing: border-box;
  border-radius: 8px;
}

.edit-panel.sirius .custom-select-list .custom-select-option {
  height: 38px;
  line-height: 48px;
  padding-left: 15px;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.edit-panel.sirius .custom-select-list .custom-select-option:hover {
  background-color: #e7f7f3 !important;
  color: #068965 !important;
  box-sizing: border-box;
}
