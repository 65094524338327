.dashboard-container {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
}

.ls-container {
  .ls-content {
    .mainboard-sample {
      height: 400px;
      width: 270px;

      border-radius: 10px;
      background-color: white;
      margin: 30px;
    }
    .sample-data {
      height: 400px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .mainboard-sample-selected {
      height: 400px;
      width: 270px;

      border-radius: 10px;
      margin: 30px;

      background-color: rgb(246, 249, 255);
      border: #7aa8f8 1px solid;
      box-shadow: 5px 5px 5px rgb(197, 197, 197);
    }
    .mainboard-label {
      margin-top: 10px;
      font-size: 15px;
      text-align: center;
    }
  }
}
