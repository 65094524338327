.detail-content-title {
  font-size: 15px;
  font-weight: 500;
  margin: 5px 0px 5px 3px;
}

.add-spec-btn {
  float: right;
  margin: 10px 5px 0px 0px;
}
