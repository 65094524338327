.login-container {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
}

.ls-container {
  .ls-content {
    .layout-sample {
      background-color: white;
      height: 360px;
      border-radius: 10px;
      margin-left: 30px;
      margin-right: 30px;
      /* display: flex;
      justify-content: center; */
    }
    .layout-selected {
      background-color: rgb(246, 249, 255);
      border: #7aa8f8 1px solid;
      box-shadow: 5px 5px 5px rgb(197, 197, 197);
      height: 360px;
      border-radius: 10px;
      margin-left: 30px;
      margin-right: 30px;
    }
  }
}
