/* 

1. Page
2. Layout
3. Tab 
등 Builder에서 Sirius Layout 관련한 css 모음
*/

.edit-panel:not(.preview-panel) .component-select:not(.focus-delegation) {
  border: 0 !important;
  outline: 2px dotted #3026d4 !important;
  z-index: 99;
}

/* 탭 활성화 부분, 인디케이터는 컴포넌트에서 반영 */
.edit-panel.sirius .editor-tab .sirius-tab {
  position: relative;
  height: 40px;
  min-width: 140px;
  color: #9d9d9d;
  font-weight: 400;
  font-size: 16px;
  padding-top: 2px;
  line-height: 1;
  cursor: pointer;
  background-color: white;
  padding-top: 2px;
  display: flex;
  align-items: self-start;
}
.edit-panel.sirius .editor-tab .sirius-tab.active {
  font-weight: 700;
  color: #009b71;
}
.edit-panel.sirius .editor-tab .sirius-tab.deactive {
}

.edit-panel.sirius .sirius-tabs.left .sirius-tab {
  display: flex;
  align-items: center;
  padding: 0px;
  padding-left: 5px;
}

.sirius-tabs.left .sirius-tab.active {
}

.sirius-tabs.left .sirius-tab.deactive {
}
