.screen-layout-tab-container {
  margin-left: 100px;
  margin-right: 100px;
}

.dropzone-wrapper {
  width: 100%;
  height: 120px;
  border: 1px solid lightgray;
  margin: 3px 0;
  border-radius: 5px;
  padding: 5px;
}

.dropzone-wrapper .drag-active {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 20px;
  background: #00000033;
}

.dropzone-wrapper .dropzone-file-list {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.dropzone-wrapper .dropzone-file-list .title {
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 300;
  text-align: center;
}

.dropzone-wrapper .dropzone-file-list .item {
  padding-left: 30px;
  height: 20px;
}

.file-list {
  /* display: flex; */
  gap: 5px;
  border: 1px lightgray solid;
  border-radius: 4px;
  height: 120px;
}

.file-list .file {
  padding: 3px 6px;
  border: 1px lightgray solid;
  border-radius: 4px;
  color: #0d6efd;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
