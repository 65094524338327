/*!
 * component css for react v.17
 *
 * 공통 component에 적용되는 모든 CSS
 * - confirm, alert, modal..
 * 
 *   Author: Bizentro
 *   Date: 2021-02
 */
/*
 * Confirm/Alert component
 * -----------------------------------------------------------------------------------
 */
.react-confirm-alert-overlay {
  z-index: 99999999;
}
.confirm-ui {
  text-align: center;
  width: 500px;
  padding: 40px;
  background: #28bae6;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
}

.confirm-ui > h1 {
  margin-top: 0;
}

.confirm-ui > p {
  white-space : pre-line;
}

.confirm-ui > button {
  width: 160px;
  padding: 10px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 14px;
}

/*
 * Modal component
 * -----------------------------------------------------------------------------------
 */
.modal-header {
  height: 40px;
  background-color: #eee;
}
.modal-title {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  color: #222;
  letter-spacing: -0.02em;
  font-size: 14px;
}

.modal-body {
  max-height: calc(
    100vh - 40px - 40px - 40px - 6px
  ); /* 40px : modal 위치, 56px : header height, 56px : footer height, 6px : space */
  overflow: auto;
}
.modal-body .template{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal-footer {
  background-color: #eee;
  height: 40px;
  padding: 2px 10px;
  width: 100%;
}

.fixed.modal-footer {
  position: absolute;
  bottom: 0px;
}

.react-draggable .modal-header {
  cursor: move;
}

.modal-body .scroll-top-container{
  position: fixed;
  right: 20px;
  bottom: 42px;
  z-index: 1;
}
.modal-body .scroll-top-container button{
  background-color: #3b16a0;
  color:#fff;
  border: 1px solid rgb(210, 204, 193);
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.modal-body .scroll-top-container button:hover{
  color :rgb(142, 26, 26);
}

/*
 * button component
 * -----------------------------------------------------------------------------------
 */
button{
  text-transform: unset!important;
}
.btn-primary {
  color: #fff;
  background-color: #0096cc;
  background-image: -webkit-linear-gradient(top, #00a7e6 0%, #0096cc 100%);
  background-image: -moz-linear-gradient(top, #00a7e6 0%, #0096cc 100%);
  background-image: -o-linear-gradient(top, #00a7e6 0%, #0096cc 100%);
  background-image: -ms-linear-gradient(top, #00a7e6 0%, #0096cc 100%);
  background-image: linear-gradient(to bottom, #00a7e6 0%, #0096cc 100%);
  background-repeat: repeat-x;
  border-color: #0095ce;
}
.btn-primary :hover {
  color: #fff;
  background-color: #1daee5;
  background-image: -webkit-linear-gradient(top, #1daee5 0%, #00a3df 100%);
  background-image: -moz-linear-gradient(top, #1daee5 0%, #00a3df 100%);
  background-image: -o-linear-gradient(top, #1daee5 0%, #00a3df 100%);
  background-image: -ms-linear-gradient(top, #1daee5 0%, #00a3df 100%);
  background-image: linear-gradient(to bottom, #1daee5 0%, #00a3df 100%);
  background-repeat: repeat-x;
  border-color: #00759f;
}
.btn-danger {
  color: #fff;
  background-color: #db3d44;
  background-image: -webkit-linear-gradient(top, #e15054 0%, #db3d44 100%);
  background-image: -moz-linear-gradient(top, #e15054 0%, #db3d44 100%);
  background-image: -o-linear-gradient(top, #e15054 0%, #db3d44 100%);
  background-image: -ms-linear-gradient(top, #e15054 0%, #db3d44 100%);
  background-image: linear-gradient(to bottom, #e15054 0%, #db3d44 100%);
  background-repeat: repeat-x;
  border-color: #d8262b;
}
.btn-danger:hover {
  color: #fff;
  background-color: #e15f5f;
  background-image: -webkit-linear-gradient(top, #e15f5f 0%, #e15054 100%);
  background-image: -moz-linear-gradient(top, #e15f5f 0%, #e15054 100%);
  background-image: -o-linear-gradient(top, #e15f5f 0%, #e15054 100%);
  background-image: -ms-linear-gradient(top, #e15f5f 0%, #e15054 100%);
  background-image: linear-gradient(to bottom, #e15f5f 0%, #e15054 100%);
  background-repeat: repeat-x;
  border-color: #c22327;
}
.btn-default {
  color: #222;
  background-color: #fbfbfb;
  background-image: -webkit-linear-gradient(top, #fbfbfb 0%, #ececec 100%);
  background-image: -moz-linear-gradient(top, #fbfbfb 0%, #ececec 100%);
  background-image: -o-linear-gradient(top, #fbfbfb 0%, #ececec 100%);
  background-image: -ms-linear-gradient(top, #fbfbfb 0%, #ececec 100%);
  background-image: linear-gradient(to bottom, #fbfbfb 0%, #ececec 100%);
  background-repeat: repeat-x;
  border-color: #ccc;
}
.btn-default:hover {
  color: #222;
  background-color: #fff;
  background-image: -webkit-linear-gradient(top, #fff 0%, #f5f5f5 100%);
  background-image: -moz-linear-gradient(top, #fff 0%, #f5f5f5 100%);
  background-image: -o-linear-gradient(top, #fff 0%, #f5f5f5 100%);
  background-image: -ms-linear-gradient(top, #fff 0%, #f5f5f5 100%);
  background-image: linear-gradient(to bottom, #fff 0%, #f5f5f5 100%);
  background-repeat: repeat-x;
  border-color: #999;
}
.editor-component > .btn {
  display: inline-flex;
  align-items: center;
  height: 36px;
  padding: 0 10px;
  background: #fff;
  border: 1px solid #b0b0b0;
  color: #333;
  font-size: 13px;
  line-height: 18px;
  cursor: pointer;
  box-shadow: none;
  border-radius: 0.9rem;
}
.editor-component > .btn:hover {
  border: 1px solid #9c9696;
  background: #f8f3f3;
}

.editor-component > .btn-search {
  justify-content: center;
  height: 36px;
  background: #d9bf31;
  border: 1px solid #c7ad23;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  border-radius: unset;
  min-width: 10px;
}
.editor-component > .btn-search:hover {
  background: #a18038;
  border: 1px solid #775a1c;
}

.editor-component > .btn-filter {
  justify-content: center;
  height: 36px;
  background: #95c1ff;
  border: 1px solid #95c1ff;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  border-radius: unset;
  min-width: 10px;
}

.editor-component > .btn-filter:hover {
  background: #559bff;
  border: 1px solid #559bff;
}

.editor-component > .btn-popup {
  justify-content: center;
  height: 30px;
  background: #fff;
  border: 1px solid #ccc;
  color: #333;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
}
.editor-component > .btn-popup:hover {
  color: #7098ba;
  border: 1px solid #ccc;
}

.editor-component > .btn-form {
  justify-content: center;
  font-size: 14px;
  text-align: center;
  padding: 0 20px;
  background: #755b20;
  border: 1px solid #453208;
  color: #fff;
}
.editor-component > .btn-form:hover {
  border: 1px solid #453208;
  background: #372806;
}
/*
.btn-sm {
  padding: 5px 12px;
  font-size: 13px;
  line-height: 20px;
  border-radius: 3px;
}
*/
/*
 * scrollbar component
 * -----------------------------------------------------------------------------------
 */

.scrollbar-track-x,
.scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-track-y,
.scrollbar-thumb-y {
  width: 5px !important;
}

.scroll-content:hover ~ .scrollbar-track {
  opacity: 1;
}

/*
 * MUI component
 * -----------------------------------------------------------------------------------
 */
.xmall-input input {
  height: 14px;
  padding : 8.5px 14px;
}

.xmall-select > div {
  height: 14px !important;
  min-height: 14px !important;
}
