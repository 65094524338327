/*!
 * Builder CSS for react v.17
 * 
 * UI Builder에서 사용하는 main css
 * - main 화면 (top, left, right sidebar ...)
 * - Builder editor
 *
 *   Author: Bizentro
 *   Date: 2021-02
 */

:root {
  /* 
    운영환경 기준 : gnb width=80px, local-nav=220px body-left-padding : 32px , body-content 24px
    빌더 : gnb-nav width : 50px
  */
  --left-sidebar-min-width: calc(80px + 220px + 32px - 50px + 12px);
}

legend {
  font-size: 1.1rem;
}
.placeholder {
  width: 100%;
  background-color: #c0d1f5;
}
.form-control-color {
  padding: 1px 2px;
}
input[type="number"].form-control {
  text-align: right;
}
.form-check {
  background-color: #00ff0000 !important;
}
.edit-source {
  border: 1px solid #ddd;
}
.input-group .switch-group .btn:not(.switch-handle) {
  position: absolute;
}
.form-collapse-btn {
  position: absolute;
  top: -15px;
  right: 0px;
  border: 1px solid #212529;
  background-color: #fff;
  padding: 0.1rem 0.375rem;
}
.nav-link {
  transition: none;
}
.control-sidebar.ui {
  background: #222d32;
  color: #b8c7ce;
  transition: 0.5s;
  display: flex;

  & .component-tab {
    width: var(--left-sidebar-min-width);
    position: relative;
    z-index: 4;
    background-color: #1f1f1f;
  }

  & .ui-tree-tab {
    position: relative;
    z-index: 3;
  }
}

.fixed .control-sidebar {
  height: 100%;
  /*overflow-y: auto;*/
}
.control-sidebar > .nav-tabs {
  border-bottom: #1c2529;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

/* Tab 기본색*/
.control-sidebar .nav-tabs > li > button,
.event-editor .nav-tabs > li > button {
  background: #181f23;
  color: #b8c7ce;
  border-top: none;
  border-right: none;
}
/*not actived tab - mouse hover */
.control-sidebar .nav-tabs > li > button:not(.active),
.event-editor .nav-tabs > li > button:not(.active) {
  border-left: 1px solid #141a1d;
  border-bottom: 1px solid #141a1d;
}

/*not actived tab - mouse hover */
.control-sidebar .nav-tabs > li > button:not(.active):hover,
.control-sidebar .nav-tabs > li > button:not(.active):focus,
.control-sidebar .nav-tabs > li > button:not(.active):active,
.event-editor .nav-tabs > li > button:not(.active):hover,
.coevent-editor .nav-tabs > li > button:not(.active):focus,
.event-editor .nav-tabs > li > button:not(.active):active {
  background: #1c2529;
  color: #fff;
}
/* actived tab - mouse hover */
.control-sidebar .nav-tabs > li > button.active,
.control-sidebar .nav-tabs > li > button.active:hover,
.control-sidebar .nav-tabs > li > button.active:focus,
.control-sidebar .nav-tabs > li > button.active:active,
.event-editor .nav-tabs > li > button.active,
.event-editor .nav-tabs > li > button.active:hover,
.event-editor .nav-tabs > li > button.active:focus,
.event-editor .nav-tabs > li > button.active:active {
  background: #222d32;
  color: #fff;
  border-left: 1px solid #222d32;
  border-bottom: 1px solid #222d32;
}

/*
.control-sidebar > .tab-content {
  padding: 10px 15px;
}
*/

.control-sidebar .nav-tabs > li:first-of-type > button,
.control-sidebar .nav-tabs > li:first-of-type > button:hover,
.control-sidebar .nav-tabs > li:first-of-type > button:focus,
.event-editor .nav-tabs > li:first-of-type > button,
.event-editor .nav-tabs > li:first-of-type > button:hover,
.event-editor .nav-tabs > li:first-of-type > button:focus {
  border-left-width: 0;
}

.control-sidebar .nav-tabs > li > button,
.event-editor .nav-tabs > li > button {
  border-radius: 0;
}

.control-sidebar .nav-tabs > li > button .tab-name {
  font-size: 11px;
  padding-left: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.control-sidebar-heading {
  font-weight: 400;
  font-size: 16px;
  padding: 10px 0;
  margin-bottom: 10px;
}

.control-sidebar-subheading {
  display: block;
  font-weight: 400;
  font-size: 14px;
}

.control-sidebar-menu {
  list-style: none;
  padding: 0;
  margin: 0 -15px;
}

.control-sidebar-heading,
.control-sidebar-subheading {
  color: #fff;
}

.control-sidebar-menu > li > a:hover {
  background: #1e282c;
}

.control-sidebar-menu > li > a .menu-info > p {
  color: #b8c7ce;
}

/*
 * - Edit panel의 기본 구조에 대한 CSS
 * - drop-zone 밎 layer 간의 간격 등
 * - comp-toolbar css
 * - edit-xxx : Component/Layout의 경우 간격 및 테두리 지정 
 *             (높이 및 너비는 builder-xxx에서 지정)
 * -----------------------------------------------------------------------------------
 */

.builder-main-wrapper {
  display: grid;
  /* 운영환경 기준 : gnb width=80px, local-nav=220px body-left-padding : 32px */
  grid-template-columns: var(--left-sidebar-min-width) auto;
  transition: 0.5s;

  & .editor-wrapper {
    width: calc(100vw - var(--left-sidebar-min-width) - 50px);
  }

  &.tree-view {
    grid-template-columns: calc(var(--left-sidebar-min-width) + 250px) auto;

    & .editor-wrapper {
      width: calc(100vw - var(--left-sidebar-min-width) - 250px - 50px);
    }
  }
}

.editor-wrapper {
  overflow-x: auto;
  height: calc(100vh - 50px);
  transition: 0.5s;
}

.editor-wrapper::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(211, 211, 211, 0);
}
.editor-wrapper::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 50px;
}
.editor-wrapper::-webkit-scrollbar-track {
  background-color: lightgray;
  border-radius: 50px;
}

.edit-panel {
  background: #e6e6e6;
  height: calc(100vh - 51px); /* 51px : height of the header*/
  min-height: 500px;
  /* text-align: center; */
  transition: 0.5s;
}
.edit-panel.isCollapse {
  width: calc(100vw - 50px);
}

.edit-panel .form-control {
  position: relative;
  width: 100%;
  height: 30px;
  padding: 0 4px;
  border: 1px solid #ccc;
  border-radius: 0;
  color: #444;
  font-size: 13px;
}

.edit-panel .edit-panel-area {
  overflow: auto;
  & .page {
    min-width: calc(100vw - var(--left-sidebar-min-width) - 50px - 24px);
  }
  & .pop_wrap {
    & .page {
      min-width: unset;
    }
  }
}

.edit-panel .edit-panel-area::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(211, 211, 211, 0);
}
/* .edit-panel .edit-panel-area:hover::-webkit-scrollbar {
  width: 5px;
  height: 4px;
} */
.edit-panel .edit-panel-area::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 50px;
}
.edit-panel .edit-panel-area::-webkit-scrollbar-track {
  background-color: lightgray;
  border-radius: 50px;
}

.edit-panel .text-control {
  position: relative;
  width: 100%;
  height: 30px;
  /* line-height: 30px;*/
  padding: 0 4px;
  border: 1px dotted #ccc;
  border-radius: 0;
  color: #444;
  font-size: 13px;
  display: flex;
  align-items: center;
}

.edit-panel .table > :not(caption) > * > * {
  padding: unset;
}
.edit-panel textarea.form-control {
  height: auto;
}

.edit-panel .form-select {
  height: 30px;
  /* border-radius: 0; */
  color: #444;
  font-size: 13px;
}

/*** editor setting start ****/
.edit-panel .icon-dropdown {
  color: #484242;
  background: #fff;
  padding: 0.2rem 0.4rem;
}
.edit-panel .no-caret.dropdown-toggle::after {
  display: none;
}
.edit-panel .show > .icon-dropdown,
.edit-panel .show > .icon-dropdown svg {
  color: inherit;
  background-color: inherit;
  border-color: #aaadb2;
}
.edit-panel .show > .icon-dropdown:focus,
.edit-panel .icon-dropdown:focus {
  box-shadow: 0 0 0 0.25rem rgba(155, 190, 243, 0.5);
}

.edit-panel .icon-dropdown:hover,
.edit-panel .icon-dropdown svg:hover {
  color: inherit;
  background: inherit;
}
.edit-panel .dropdown-header {
  background: #e5ebf0;
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
}
.edit-panel .dropdown-item {
  padding: 0.34rem 1rem;
}
.edit-panel .dropdown-item.indent {
  padding-left: 32px;
}

/*** editor setting end ****/

/* .edit-panel .edit-tab {
  width: 100%;
  background-color: #f8f8f8;
  padding: 0.2rem;
  display: flex;
  gap: 10px;
  position:relative;
} */
.edit-panel .edit-tab {
  width: 100%;
  background-color: #f8f8f8;
  padding: 0.2rem;
  display: flex;
  height: 30px;
  justify-content: space-between;
  padding-right: 30px;
}
.edit-panel .edit-tab .side {
  display: flex;
  gap: 10px;
}
.edit-panel .edit-tab .right.side {
  position: relative;
}
.edit-panel .edit-tab .right.side .program-id-label {
  max-width: 200px;
  color: #333;
  margin-top: 3px;
  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.edit-panel .edit-tab .right.side .memo-body {
  position: absolute;
  top: 30px;
  right: 0px;
  width: 300px;
  height: 400px;
  background-color: white;
  z-index: 930;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 1px 1px 3px black;
  transition: 0.3s;
}

.edit-panel .edit-tab .right.side .memo-body.hidden {
  width: 0px;
  height: 0px;
  visibility: hidden;
  padding: 0px !important;
  transition: 0.3s;
}
.edit-panel .edit-tab .right.side .memo-body.hidden * {
  display: none !important;
}

.edit-panel .edit-tab .right.side .memo-body .header {
  display: flex;
  justify-content: space-between;
  height: 30px;
  align-items: center;
}
.edit-panel .edit-tab .right.side .memo-body .header button {
  background-color: transparent;
  border: none;
}
.edit-panel .edit-tab .right.side .memo-body .header button:hover {
  background-color: lightgray;
  border-radius: 5px;
}
.edit-panel .edit-tab .right.side .memo-body .list {
  height: 350px;
  overflow-y: auto;
  display: grid;
  grid-auto-rows: 70px;
  gap: 15px;
}
.edit-panel .edit-tab .right.side .memo-body .list .memo-contents {
  background-color: beige;
  padding: 5px;
  cursor: pointer;
}
.edit-panel .edit-tab .right.side .memo-body .list .memo-contents:hover {
  background-color: rgb(199, 199, 158);
}
.edit-panel .edit-tab .right.side .memo-body .list .memo-contents .memo {
  height: 70%;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.edit-panel .edit-tab .right.side .memo-body .list .memo-contents .memo-info {
  height: 30%;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.edit-panel .edit-tab .right.side .memo-body .detail-body {
  background-color: beige;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.edit-panel .edit-tab .right.side .memo-body .detail-body .detail-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 3px 5px;
}
.edit-panel
  .edit-tab
  .right.side
  .memo-body
  .detail-body
  .detail-header
  button {
  height: 30px;
  padding: 2px 3px;
  border: none;
  outline: none;
  background-color: transparent;
}
.edit-panel
  .edit-tab
  .right.side
  .memo-body
  .detail-body
  .detail-header
  button:hover {
  background-color: lightgray;
  border-radius: 5px;
}

.edit-panel .edit-tab .right.side .memo-body .detail-body .detail-contents {
  width: 100%;
  height: calc(100% - 40px - 20px);
  overflow-y: auto;
  white-space: pre-wrap;
  word-break: keep-all;
}
.edit-panel .edit-tab .right.side .memo-body .detail-body .detail-info {
  width: 100%;
  height: 20px;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  padding-right: 10px;
  align-items: center;
}
.edit-panel
  .edit-tab
  .right.side
  .memo-body
  .detail-body
  .detail-contents
  textarea {
  width: 100%;
  height: 95%;
  resize: none;
}

.edit-panel .edit-tab .btn-sm,
.properties-component-panel .btn-sm {
  font-size: 0.875rem;
}
.edit-tab .edit-button-group {
  display: flex;
  border-collapse: collapse;
}

.edit-tab .edit-button-group > * {
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  max-height: 30px;
  cursor: pointer;
  background-color: white;
}

.edit-tab .edit-button-group > *.selected {
  background-color: #3c3c3c;
  color: white;
  border: 1px solid #3c3c3c;
}

.edit-tab .edit-button-group > *:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.edit-tab .edit-button-group > *:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.edit-tab .edit-button-group > *:not(select):hover {
  background-color: #3c3c3c;
  color: white;
  border: 1px solid #3c3c3c;
}
.edit-tab .edit-button-group > *.disabled {
  color: lightgray;
}
.edit-tab .edit-button-group > *.disabled:hover {
  background-color: transparent;
  color: lightgray;
  border: 1px solid lightgray;
}
.edit-tab .edit-button-group > *:disabled {
  color: lightgray;
  cursor: no-drop;
}
.edit-tab .edit-button-group > *:disabled:hover {
  background-color: transparent;
  color: lightgray;
  border: 1px solid lightgray;
}

.edit-panel .edit-tab .tool-tip-icon {
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3.25px 6.5px;
  border-radius: 0;
  border-left: 1px solid;
  border-right: 1px solid;
}
.edit-panel .edit-tab .tool-tip-icon.left {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.edit-panel .edit-tab .tool-tip-icon.right {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-left: none;
}
.edit-panel .edit-tab .tool-tip-icon.color {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  gap: 5px;
}
.edit-panel .edit-tab .tool-tip-icon .edit-color-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.edit-panel .edit-tab .tool-tip-icon .edit-color-wrapper input {
  height: 100% !important;
  width: 25px !important;
  padding: 0 !important;
  border: none !important;
}
.edit-panel .edit-tab .tool-tip-icon .edit-color-wrapper button {
  margin: 0px;
  min-width: 10px;
  width: 20px;
  height: 20px;
}
.edit-panel .edit-tab .tool-tip-icon .edit-color-wrapper button div {
  min-width: 10px;
  width: 20px;
  height: 20px;
}

.edit-panel .edit-tab select {
  border: 1px solid black;
  width: 80px;
  text-align: center;
  border-radius: 0;
  border-right: none;
  border-left: none;
}
.edit-panel .edit-tab select.left {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-left: 1px solid black;
  border-right: none;
}
.edit-panel .edit-tab select.right {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-right: 1px solid black;
  border-left: none;
}

.edit-panel .edit-source {
  border: 1px solid #ddd;
  text-align: initial;
}
.edit-panel .edit-source .edit-source-options {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}
.edit-panel .source-container {
  height: calc(
    100vh - 51px - 26px - 40px
  ); /* 51px : height of the header, 26px :Source tab*/
}

.edit-panel .page-container {
  /*
  display: flex;
  flex: 0 0 150px;
  flex-direction: column;
  padding: 0.5rem;
  */
  /* display: inline-grid; */
  border: 1px solid #ddd;
  background: #fff;
  margin-top: 0px;
  width: 100%;
  padding-top: 24px;
  padding-left: 12px;
}
.edit-panel .page-container.popup-container {
  padding-top: 0.5rem;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: block;
}

.edit-panel .page-container.active {
  border: 1px dotted #3026d4;
}

.edit-panel .page {
  /* padding-right: 6px; */
  transition: 200ms all;
}

.edit-panel .drop-zone:only-child {
  height: 40px;
}

.edit-panel .drop-zone {
  flex: 0 0 auto;
  height: 10px;
  transition: 200ms all;
}

/* dashboard */
.edit-panel .page-type-D .page {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.edit-panel .page-type-D .page .widget-dnd {
  min-width: 20px;
  min-height: 100%;
}
.edit-panel .page-type-D .page > .drop-zone {
  min-width: 20px;
  min-height: 160px;
}

.edit-panel .page-type-D .page > .drop-zone:last-of-type {
  width: calc(25% - 20px);
  border: 2px dashed #b9c1e6;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' ><text x='30%' font-size='30' y='55%' fill='rgb(164, 185, 181)' >Drop here</text></svg>");
}

.edit-panel .page-type-D .page > .drop-zone:not(:last-of-type) {
  display: none;
}

.edit-panel .page-type-D .page > .whole-wrap,
.edit-panel .page-type-D .page > .drop-zone:last-of-type {
  margin-top: 10px;
  margin-bottom: 10px;
}

/*
.edit-panel .drop-zone:nth-of-type(2n) {
  display: none;
}
*/

.edit-panel .drop-zone.active {
  background: #00a2ff;
  /*border: 1px dashed #00a2ff;*/
  height: 40px;
  transition: 100ms all;
}

.edit-panel .drop-zone.horizontal-drag {
  width: 10px;
  height: auto;
}
.edit-panel .drop-zone.active.horizontal-drag {
  min-width: 200px;
  height: auto;
}
.edit-panel .editor-grid .drop-zone.horizontal-drag {
  width: 0px;
}
.edit-panel
  .page
  .editor-grid
  .editor-grid-wrap.dragging
  .drop-zone.horizontal-drag {
  background: #e0e7eb;
  border: 1px dotted #1e282c;
  width: 20px;
  transition: 100ms all;
}
.edit-panel
  .page
  .editor-grid
  .editor-grid-wrap.dragging
  .drop-zone.active.horizontal-drag {
  min-width: 100px;
  height: auto;
}
.edit-panel .drop-zone.horizontal-drag.horizontal-drag-full {
  width: 100%;
}

.edit-panel .drop-zone:not(.horizontal-drag).isLast {
  flex: 1 1 auto;
}

.edit-panel .editor-component {
  /* background: #fff; */
  cursor: move;
}
.edit-panel .editor-column {
  /*border: 1px solid #bcb0b0;*/
  border-left: 1px solid #bcb0b0;
  border-top: 1px solid #bcb0b0;
  border-bottom: 1px solid #bcb0b0;
  flex: 1 1 100%;
  padding-right: 10px;
  padding-left: 10px;
  min-height: 40px;
  width: 100%;
  background-color: #fff;
}

.edit-panel .editor-column.isLast {
  border-right: 1px solid #bcb0b0;
}

.edit-panel .editor-grid-header,
.edit-panel .editor-grid-column {
  display: flex;
  flex: 1 1 100%;
  align-items: center;
  /* border: 1px solid #bcb0b0; */
  min-height: 40px;
  width: 100%;
  padding: 3px 6px 3px 6px;
  background-color: #fff;
  white-space: pre;
}

.edit-panel .editor-grid-header {
  justify-content: space-around;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.edit-panel .editor-grid-header.grid-column-hide:not(.grid-row-header),
.edit-panel
  .editor-grid-header.grid-column-hide:not(.grid-row-header)
  .grid-header-title
  .grid-header-icon {
  background-color: #d1d6d9;
}

.edit-panel .editor-grid-header .grid-header-title {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  /* white-space: pre; */
  white-space: nowrap;
  text-align: center;
}
.edit-panel .editor-grid-header .grid-header-title .grid-label.required:before {
  content: "*";
  top: 50%;
  right: 5px;
  margin-top: -7px;
  color: #f00;
  font-size: 14px;
  line-height: 14px;
  font-weight: 900;
}

.edit-panel
  .editor-grid-header
  .grid-header-title:has(.grid-label.header-left) {
  text-align: left;
  padding-left: 2px;
}
.edit-panel
  .editor-grid-header
  .grid-header-title:has(.grid-label.header-center) {
  text-align: center;
}
.edit-panel
  .editor-grid-header
  .grid-header-title:has(.grid-label.header-right) {
  text-align: right;
  padding-right: 60px;
}

.edit-panel .editor-grid-header .grid-header-title .grid-header-icon {
  /* float: right; */
  padding-right: 10px;
  /* padding-left: 10px; */
  position: absolute;
  right: 0px;
  top: 0px;
  margin-top: 6px;
  background: #fff;
  height: calc(100% - 8px);
  display: flex;
  align-items: center;
}
.edit-panel
  .page-container
  .page
  .editor-grid-columns:not(.last-column)
  .editor-grid-header::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  width: 1px;
  height: 12px;
  margin-top: -6px;
  background: #ccc;
}
.edit-panel .editor-grid-column {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.edit-panel .editor-columns {
  display: flex;
  min-height: 30px;
  /* min-height: 40px; */
  /* align-items: center; */
  /* hidden row
  padding-top: 10px;
  padding-bottom: 10px;
  */
}

.edit-panel .ui-modal-footer .editor-columns {
  min-height: unset;
  min-width: 80px;
}
.edit-panel .editor-grid-toolbar {
  display: flex;
  margin-top: 8px;
  margin-left: 8px;
  margin-right: 8px;
  justify-content: space-between;
}
.edit-panel .editor-grid-footer {
  display: flex;
  margin-left: 8px;
}

.edit-panel .editor-grid-footer .grid-footer-icon {
  color: green;
  margin-top: 4px;
  margin-left: 2px;
}

.edit-panel .editor-grid-title {
  display: flex;
  align-items: flex-start;
}
.edit-panel .editor-grid-title.whole-wrap {
  width: 100% !important;
}

.edit-panel .editor-gird-toolbar-buttons {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-end;
  align-items: flex-end;
}
.edit-panel .editor-gird-toolbar-buttons button:not(:last-of-type) {
  margin-right: 4px;
}

.edit-panel .editor-container .editor-columns {
  min-height: 26px;
}

.edit-panel .editor-flex-column {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  min-height: 60px;
  /* hidden row
  padding-left: 10px;
  padding-right: 10px;
  */
}

.edit-panel .editor-block {
  display: flex;
}

.edit-panel .editor-flex-column > .drop-zone {
  flex: 1;
  width: 100%;
  min-height: 4px;
}

.edit-panel .editor-flex-row {
  display: flex;
  flex: 1 1 100%;
  flex-direction: row;
  margin: 10px;
}

.edit-panel .editor-column-container {
  display: flex;
}

.edit-panel .editor-base {
  position: relative;
  /*padding: 0.5rem 1rem;*/
  /*background-color: #fff;*/
  cursor: move;
}
.edit-panel .editor-base.section-button {
  width: fit-content;
}
.edit-panel .editor-row {
  min-height: 40px;
  width: 100%;
  background-color: #fff;
}
.edit-panel .editor-form {
  min-height: 75px;
  width: 100%;
  background-color: #fff;
}
.edit-panel .editor-container,
.edit-panel .editor-input-group {
  width: 100%;
  background-color: inherit;
}

.edit-panel .editor-grid {
  min-height: 60px;

  background-color: #fff;
  transition: 0.5s;
}

.edit-panel .page-container.popup-container .editor-grid {
  width: 100% !important;
}

.edit-panel .editor-grid-wrap {
  display: flex;
  min-height: 80px;
  padding: 10px;
  overflow-x: auto;
}
/*
.edit-panel:not(.isCollapse) .editor-grid {
  max-width: calc( 100vw - 50px - 360px - 21px ) !important;
}
.edit-panel.isCollapse .editor-grid {
  max-width: calc( 100vw - 50p) !important;
}
*/
.edit-panel .editor-grid-toolbar + .editor-grid-wrap {
  padding-top: 6px;
}

.edit-panel .editor-form,
.edit-panel .editor-grid,
.edit-panel .editor-container,
.edit-panel .editor-input-group,
.edit-panel .editor-headling {
  /* border: 1px solid #bcb0b0; */
  padding: 0;
  width: 100%;
}

.edit-panel .editor-form-group {
  border: 1px solid #bcb0b0;
  padding: 2px;
}
.edit-panel .editor-input-group .input-col .editor-form-group {
  border: 0px;
}
.edit-panel .editor-empty-form-group {
  border: 0px solid #bcb0b0;
}
.edit-panel .editor-input-group {
  padding: 2px;
}
.edit-panel .editor-input-group .input-col {
  padding-left: 0px;
  padding-right: 0px;
}
.edit-panel .editor-input-group button {
  min-width: 30px;
}
.edit-panel .editor-form-group .input-col,
.edit-panel .editor-empty-form-group .input-col {
  padding-left: 2px;
  padding-right: 2px;
}

/* hidden row*/
.edit-panel .editor-row {
  border: 1px;
  border-color: transparent;
  border-style: solid;
  padding: 0;
}

.edit-panel .editor-component-wrap {
  align-self: center;
  height: auto;
  /* width : 100%;  input group이 이상하게나옴*/
}
/* .edit-panel .editor-component-wrap:has(>.editor-button-wrap) {
  width: fit-content;
} */
.edit-panel:not(.preview-panel) .component-select:not(.focus-delegation) {
  border: 2px dotted #3026d4 !important;
  z-index: 99;
}
.edit-panel:not(.preview-panel) .input-component-select:not(.focus-delegation) {
  outline: 2px dotted #3026d4 !important;
  border: 0px;
}

.edit-panel:not(.preview-panel) .mui-component-select fieldset {
  border: 2px dotted #3026d4 !important;
}
.edit-panel:not(.preview-panel) .page-container .page .component-hover {
  border: 1px solid #00a2ff;
}
.edit-panel .comp-title,
.edit-panel .comp-toolbar {
  display: none;
}
.edit-panel .component-select {
  position: relative;
}
.edit-panel .component-select > .comp-title:not(.focus-delegation),
.edit-panel .component-select > .comp-toolbar {
  display: inline-block;
  position: absolute;
  flex: 1 1 auto;
  color: #fff;
  top: -23px;
  background-color: #0d6efd;
  border-color: #0d6efd;
  font-size: 0.875rem;
  padding: 0.25rem 0.5rem;
  border-top-right-radius: 0.2rem;
  border-top-left-radius: 0.2rem;
  height: 22px;
  box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;
  z-index: 900;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.edit-panel .component-select.editor-grid-header > .comp-toolbar {
  top: -12px;
}
.edit-panel .component-select > .comp-title:not(.focus-delegation) > span,
.edit-panel .component-select > .comp-toolbar > span {
  padding: 0.25rem 0.5rem;
  cursor: default;
}

.edit-panel .component-select > .comp-title {
  left: -2px;
}
.edit-panel .component-select.editor-button-wrap > .comp-toolbar {
  right: unset;
  min-width: 153px;
}
.edit-panel .component-select > .comp-toolbar {
  right: -2px;
  min-width: 153px;
}
.edit-panel .component-select > .comp-toolbar > span,
.edit-panel .component-select > .comp-title > span {
  cursor: pointer;
  padding: 6px;
}
.edit-panel .component-select > .comp-toolbar > span > svg,
.edit-panel .component-select > .comp-title > span > svg {
  vertical-align: top;
}

.edit-panel .component-select > .comp-toolbar > span.comp-move-icon {
  cursor: move;
  padding: 6px;
}
.edit-panel .component-select > .comp-toolbar > span:hover,
.edit-panel .component-select > .comp-title > .collapse-button:hover {
  background-color: #0a66f0;
  box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;
}
.edit-panel .editor-button-wrap {
  /* width: max-content; */
  /* width: fit-content; */
}

.edit-panel .editor-mui-button {
  width: max-content;
}
.edit-panel .editor-headling,
.edit-panel .editor-html {
  min-height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  background: #fff;
}

.edit-panel .editor-html .sun-editor-editable {
  width: 100%;
  padding: unset;
}

.edit-panel .editor-tab {
  width: 100%;
  border-bottom: 1px solid #bcb0b0;
  box-shadow: 0 6px 8px -6px #c1a6a6;
  -webkit-box-shadow: 0 6px 8px -6px #c1a6a6;
  -moz-box-shadow: 0 6px 8px -6px #c1a6a6;
}

.edit-panel .editor-tab .editor-empty-tabpanel,
.edit-panel .editor-step-wizard .editor-empty-steppanel {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  padding: 10px;
  min-height: 40px;
}

.edit-panel .editor-tab .editor-actived-tab {
  background-color: #1976d2;
  color: #ffffff;
}

.edit-panel .editor-tab .editor-tab-panel,
.edit-panel .editor-step-wizard,
.edit-panel .editor-step-wizard .editor-step-panel {
  width: 100%;
}

.edit-panel .editor-step-wizard .step-wrap {
  margin: 16px 0;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.edit-panel .editor-step-wizard .step-wrap .step-item {
  position: relative;
  float: left;
  display: flex;
  align-items: center;
  height: 80px;
  padding: 20px;
  border-radius: 40px;
  background: #e8ebed;
  width: 100%;
  text-align: left;
}

.edit-panel .editor-step-wizard .step-wrap .step-item .no-step {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 46px;
  height: 46px;
  border-radius: 46px;
  background: #fff;
  color: #6a6f74;
  font-size: 30px;
  line-height: 1;
}

.edit-panel .editor-step-wizard .step-wrap .step-item .cont-step {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-left: 10px;
}

.edit-panel .editor-step-wizard .step-wrap .step-item .cont-step .tit-step {
  color: #6a6f74;
  font-size: 20px;
  line-height: 20px;
  /* font-weight: 700 */
}

.edit-panel .editor-step-wizard .step-wrap .step-item .cont-step .txt-step {
  color: #a4a7ab;
  font-size: 13px;
  line-height: 14px;
  /* font-weight: 500 */
}

.edit-panel .editor-step-wizard .step-wrap .step-item + .step-item {
  margin-left: 76px;
}

.edit-panel .editor-step-wizard .step-wrap .step-item + .step-item :before {
  content: "";
  position: absolute;
  left: -46px;
  top: 50%;
  width: 17px;
  height: 24px;
  margin-top: -12px;
  background: url("../../images/icon/ico_step_arrow.png") no-repeat 0 0/100%;
}

.edit-panel .editor-step-wizard .step-wrap .step-item.on {
  background: #0066cb;
}

.edit-panel .editor-step-wizard .step-wrap .step-item.on .cont-step .tit-step {
  color: #fff;
}

.edit-panel .editor-step-wizard .step-wrap .step-item.on .cont-step .txt-step {
  color: #fff;
}
.edit-panel .editor-step-wizard .step-item:not(.active) {
  cursor: pointer;
}

.edit-panel .editor-step-wizard .step-content .step-pane.active {
  overflow-y: auto;
}

.edit-panel .editor-step-wizard .step-content .step-pane {
  padding-right: 10px;
}

.edit-panel .editor-step-wizard .step-wizard-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 6px;
}
.edit-panel .editor-step-wizard .step-wizard-buttons button {
  padding-right: 25px;
  padding-left: 25px;
  border-radius: unset;
  font-size: 15px;
  height: 40px;
}

/* 
.edit-panel .editor-step-wizard .step-wizard-buttons .btn.disabled,
.edit-panel .editor-step-wizard .step-wizard-buttons .btn:disabled{
  opacity: .5;
} */

/** simple design **/
.edit-panel .editor-step-wizard.step-simple .step-item {
  height: 60px;
}
.edit-panel .editor-step-wizard.step-simple .step-wizard-buttons .btn {
  height: 30px;
  line-height: 0;
}

.editor-widget-containter {
  width: 100%;
  min-height: 40px;
  background-color: inherit;
}

.editor-widget-containter .widget-header {
  color: #444;
  display: block;
  padding: 10px;
  position: relative;
  border-bottom: 1px solid #e5e0e0;
}

.editor-widget-containter .widget-title {
  display: inline-block;
  font-size: 16px;
  margin: 0;
  line-height: 1;
}
.editor-widget-containter .widget-tools {
  position: absolute;
  right: 10px;
  top: 5px;
}

.editor-widget-containter .widget-body {
  width: 100%;
  min-width: 100%;
  height: 100%;
  /* overflow: hidden; */
}
.editor-widget-containter .widget-header + .widget-body {
  height: auto;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 0px;
  padding-left: 10px;
}
.editor-widget-containter .widget-header + .widget-body .sun-editor-editable {
  padding: 0px;
}
.editor-widget-containter .widget-header:before,
.widget-body:before,
.widget-footer:before,
.widget-header:after,
.widget-body:after,
.widget-footer:after {
  content: " ";
  display: table;
}
.editor-widget-containter .widget-body .widget-template {
  height: 100%;
  width: 100%;
}
.editor-widget-containter .widget-body .widget-template,
.editor-widget-containter .widget-body .widget-template div,
.editor-widget-containter .widget-body .widget-template span {
  overflow: hidden;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
}

.editor-widget-containter .editor-amchart3 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.editor-widget-containter .editor-chart-image {
  margin-top: 20px;
}

.edit-panel .layout-collapse {
  height: 20px !important;
  min-height: 20px !important;
  border: 3px double #ce1313 !important;
}
.edit-panel .layout-collapse::before {
  content: "";
  background-image: url('data:image/svg+xml;utf8,<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8zm7-8a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 4.293V.5A.5.5 0 0 1 8 0zm-.5 11.707-1.146 1.147a.5.5 0 0 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 11.707V15.5a.5.5 0 0 1-1 0v-3.793z"></path></svg>');
  background-size: 15px 15px;
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  color: #393b42;
  display: block;
  padding-left: 20px;
}

.edit-panel .layout-collapse > [class*="editor-"],
.edit-panel .layout-collapse > [draggable="true"] {
  display: none;
  transition: 200ms all;
}

.edit-panel .ui-modal-footer {
  border: 1px solid #ddd;
  background: #fff;
  position: relative;
  left: 50%;
  transform: translateY(-100%) translateX(-50%);
}

.edit-panel .ui-modal-footer .editor-column > .whole-wrap {
  height: 100%;
}

.edit-panel .ui-modal-footer .editor-column > .drop-zone:not(.isLast),
.edit-panel .ui-modal-footer .editor-column > .whole-wrap + .drop-zone {
  display: none;
}

.edit-panel .form-group .search-form-label {
  /* margin-bottom: 0; */
  color: #000;
  /* font-size: 14px; */
  font-weight: 600;
  text-align: right !important;
  white-space: nowrap;
  padding-right: 6px;
}
.edit-panel .form-group .search-form-label.required:after {
  content: "*";
  top: 50%;
  right: 5px;
  padding-right: 3px;
  margin-top: -7px;
  color: #f00;
  font-size: 14px;
  line-height: 14px;
  font-weight: 900;
}
.edit-panel .form-group .save-form-label {
  background: #f5f5f5;
  text-align: left !important;
}
.edit-panel .form-group .save-form-label.required:after {
  content: "*";
  top: 50%;
  right: 5px;
  margin-top: -7px;
  color: #f00;
  font-size: 14px;
  line-height: 14px;
  font-weight: 900;
}
.edit-panel .form-group .form-label {
  margin-bottom: unset;
  padding-top: 4px;
  text-align: left;
}

.edit-panel .form-group.row {
  margin: 0px;
}

.edit-panel .editor-form-group .input-group > .btn {
  width: 30px;
  height: 30px;
  padding: 0;
  padding-bottom: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

/* login */
.edit-panel .page-type-L .page > .whole-wrap:has(> .login_out_wrap.s2) {
  height: calc(100% - 60px);
}

.edit-panel .page-type-L .page .login_out_wrap {
  display: flex;
  width: 100%;
  height: calc(100% - 60px);
  justify-content: center;
  align-items: center;
}

.edit-panel .page-type-L .page .login_out_wrap.s3 .login_wrap {
  justify-content: flex-end;
  border-left: 5px solid #d9dde1;
  margin-left: 50px;
}

.edit-panel .page-type-L .page .login_out_wrap.s3 {
  height: auto;
  padding: 0;
  border: 5px solid #d9dde1;
  border-radius: 20px;
  -webkit-border-radius: 20px;
}

.edit-panel .page-type-L .page .login_out_wrap > .editor-flex-column {
  flex-direction: unset;
}

.edit-panel
  .page-type-L
  .page
  .login_out_wrap.s1
  > .editor-flex-column
  > .editor-component-wrap,
.edit-panel
  .page-type-L
  .page
  .login_out_wrap.s2
  > .editor-flex-column
  > .editor-component-wrap {
  width: 50%;
}

.edit-panel
  .page-type-L
  .page
  .login_out_wrap.s1
  > .editor-flex-column
  > .whole-wrap {
  width: 50%;
}

.edit-panel .page-type-L .page .login_out_wrap.s2 {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.edit-panel
  .page-type-L
  .page
  .login_out_wrap.s2
  > .editor-flex-column
  > .whole-wrap {
  width: 50%;
}

.edit-panel .page-type-L .page .login_out_wrap.s2 > .login_wrap {
  position: absolute;
  align-items: center;
}

.edit-panel .page-type-L .page .login_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex: 0 0 50%; */
}

.edit-panel .page-type-L .page .login_wrap .editor-mui-button {
  width: 100%;
}

.edit-panel .page-type-L .page .login_form .tit_login {
  color: #000;
  font-size: 30px;
  font-weight: 300;
  line-height: 30px;
}
.edit-panel .page-type-L .page .login_form .find a {
  display: block;
  color: #000;
  font-size: 16px;
  font-weight: 400;
}
.edit-panel .page-type-L .page .login_form .find a:hover {
  display: block;
  color: #0066cb;
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
}
.edit-panel .page-type-L .page .login_form .find a:active {
  display: block;
  color: #0066cb;
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
}

.edit-panel .page-type-L .page .login_wrap .login_form .btn_submit {
  display: block;
  width: 100%;
  height: 60px;
  background: #0066cb;
  border-radius: 5px;
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  line-height: 60px;
  text-align: center;
  border: 0px;
}

.edit-panel .page-type-L .page .login_wrap .login_form .btn_submit:hover {
  background: #0053a5;
}

.edit-panel .page-type-L .page .login_wrap .login_form .find a {
  display: block;
  color: #000;
  font-size: 16px;
  font-weight: 400;
}

/*
 * Edit panel - builder-xxx => watermark
 * -----------------------------------------------------------------------------------
 */
.edit-panel .builder-container {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' ><text x='50%' font-size='30' y='70%' fill='rgb(164, 185, 181)' >Container</text></svg>");
}

.edit-panel .builder-row {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' ><text x='50%' font-size='30' y='70%' fill='rgb(164, 185, 181)' >Row</text></svg>");
}

.edit-panel .builder-column {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' ><text x='50%' font-size='30' y='70%' fill='rgb(164, 185, 181)' >Column</text></svg>");
}

.edit-panel .builder-block {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' ><text x='50%' font-size='30' y='80%' fill='rgb(164, 185, 181)' >Block</text></svg>");
}
.edit-panel .builder-form {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' ><text x='50%' font-size='30' y='60%' fill='rgb(164, 185, 181)' >Form</text></svg>");
}

/*
 * Properties panel
 * -----------------------------------------------------------------------------------
 */
.control-sidebar .builder-help {
  min-width: 48px;
}
.control-sidebar .fieldset-border {
  border: 1px solid #7e7b7b;
}

.control-sidebar .properties-header .form-select {
  display: inline-block;
  width: auto;
}

.control-sidebar .popover-header {
  color: #000;
}
.control-sidebar .properties-component-panel .accordion {
  height: calc(
    100vh - 50px - 34px - 42px
  ); /* 50px : height of the header , 33px : height of the tab*/
  min-height: 500px;
}

.control-sidebar .properties-component-panel .form-text {
  color: #eaeef1;
}
.control-sidebar .properties-component-panel .accordion {
  height: calc(
    100vh - 50px - 34px - 42px
  ); /* 50px : height of the header , 33px : height of the tab*/
  min-height: 500px;
}
.control-sidebar .properties-component-panel > canvas {
  display: none !important;
}
.control-sidebar .service-component-panel .accordion,
.control-sidebar .basic-component-panel .accordion {
  height: calc(
    100vh - 50px - 33px
  ); /* 50px : height of the header , 33px : height of the tab*/
  min-height: 500px;
}
/* 아코디언 깊은 뎁스 스타일 수정 */
.control-sidebar
  .service-component-panel
  .accordion-body
  .accordion-item
  .accordion-header
  button {
  background-color: #3c3c3c;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.3rem;
  /* font-size: 14px; */
  display: flex;
  gap: 5px;
}
.control-sidebar
  .service-component-panel
  .accordion-body
  .accordion-item
  .accordion-body
  .sidebar-item {
  /* padding-top: 0.5rem;
  padding-bottom: 0.5rem; */
  padding: 0;
  align-items: center;
  display: flex;
}

.control-sidebar .accordion-item,
.control-sidebar .accordion-item:first-of-type,
.control-sidebar .accordion-item:last-of-type,
.control-sidebar .accordion-item .accordion-button,
.control-sidebar .accordion-item:first-of-type .accordion-button,
.control-sidebar .accordion-item:last-of-type .accordion-button,
.control-sidebar .accordion-item:first-of-type .accordion-button.collapsed,
.control-sidebar .accordion-item .accordion-button.collapsed,
.control-sidebar .accordion-item:last-of-type .accordion-button.collapsed,
.control-sidebar .accordion-item .accordion-collapse,
.control-sidebar .accordion-item:first-of-type .accordion-collapse,
.control-sidebar .accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.control-sidebar .accordion-item {
  background-color: inherit;
  border-bottom: 1px solid #333333;
  border-top: 0;
  border-right: 0;
  border-left: 0;
}
.control-sidebar .accordion-button {
  padding: 0.7rem 0.8rem 0.7rem 0.8rem;
  color: #fff;
  background-color: #252526;
}
.control-sidebar .accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #333333;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
.control-sidebar .accordion-button:focus {
  z-index: 3;
  border-color: #657181;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(3, 44, 107, 0.25);
}
.control-sidebar .accordion-button::after {
  background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23e7f1ff%27%3E%3Cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e);
}

.control-sidebar .accordion-body {
  padding: 0.25rem 1.25rem;
  padding-right: unset;
  background-color: #3c3c3c;
  color: #fff;
  font-size: 12px;
}

.control-sidebar .accordion-body > div:last-child {
  border-bottom: 0;
}

.control-sidebar .accordion-body .sidebar-item {
  display: flex;
  user-select: none;
  align-items: center;
  align-content: flex-start;
  line-height: 1.5;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: 0.3rem;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 1px solid #303030;
  background-color: inherit;
  color: inherit;
  box-shadow: inherit;
}

.control-sidebar .component-panel::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: rgba(211, 211, 211, 0);
}
.control-sidebar .component-panel::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 50px;
}
.control-sidebar .component-panel::-webkit-scrollbar-track {
  background-color: lightgray;
  border-radius: 50px;
}

.control-sidebar .basic-component-panel .accordion-body > div:last-child,
.control-sidebar
  .basic-component-panel
  .accordion-body
  > div:nth-last-child(2):nth-child(odd) {
  border-bottom: none;
}

.control-sidebar .basic-component-panel .accordion-body .sidebar-item {
  display: inline-flex;
  width: 50%;
}

.control-sidebar .basic-component-panel .element-component-list-wrapper {
  overflow-y: auto;
}

.control-sidebar .accordion-body .sidebar-item > .fa-pr > svg {
  vertical-align: baseline;
}
.control-sidebar .properties-component-panel .accordion-body {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-right: 4px;
}
.control-sidebar .properties-component-panel .empty-properties {
  padding: 10px;
  color: #fff;
}

.control-sidebar
  .properties-component-panel
  .popover-body
  li:not(:first-child) {
  padding-top: 6px;
}

/* selectbox readonly */
.control-sidebar .form-select[readonly] {
  pointer-events: none;
  background-color: #e9ecef;
  opacity: 1;
}
.control-sidebar .properties-component-panel .popover {
  max-width: 340px;
  min-width: 300px;
}

.dataModel-container {
  background: #e6e6e6;
  height: calc(100vh - 51px); /* 51px : height of the header*/
  min-height: 500px;
  width: calc(
    100vw - 50px - 360px
  ); /* 50px : width of the left sidebar, 360px : heighto of .control-sidebar  */
  min-width: 800px;
  text-align: center;
  transition: 0.5s;
}
.dataModel-container.isCollapse {
  width: calc(100vw - 50px);
}

.dataModel-container .query-render-panel {
  height: 65%;
}

.dataModel-container .query-bottom-menu {
  background: #252526;
  position: relative;
  height: 35%;
}

.dataModel-container .query-bottom-menu .Tab.header {
  position: absolute;
  height: 30px;
  /* top: -30px; */
  bottom: 100%;
  display: flex;
  gap: 1px;
  margin-left: 1px;
  align-items: flex-end;
}
.dataModel-container .query-bottom-menu .Tab.header:hover {
  height: 35px;
}
.dataModel-container .query-bottom-menu .Tab.header .title {
  height: 30px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: #252526;
  color: white;
  padding: 3px 8px;
  transition: 0.3s;
}
.dataModel-container .query-bottom-menu .Tab.header .title:hover {
  cursor: pointer;
  padding: 4px 10px;
  height: 35px;
}

.dataModel-container .query-bottom-menu .Tab.body {
  width: 100%;
  height: 100%;
  color: white;
  text-align: justify;
}

/*
 * UI BUILDER TREE 
 * -----------------------------------------------------------------------------------
 */
.builderTree-container {
  position: absolute;
  width: 250px;
  height: calc(100vh - 50px);
  top: 0;
  left: 0;
  transition: 0.5s;
  opacity: 1;
}
.builderTree-container.collapsed {
  left: -250px;
  opacity: 0;
}
.builderTree-container .builderTree-body {
  width: 100%;
  height: 100%;
  background-color: #4a4747;
  transition: 0.5s;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.builderTree-container .builderTree-body .builderTree {
  padding: 5px;
  color: white;
  overflow-y: auto;
  height: 100%;
}

.builderTree-container .builderTree-body .builderTree::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: rgba(211, 211, 211, 0);
}
/* *:hover::-webkit-scrollbar {
  width: 4px;
  height: 4px;
} */
.builderTree-container .builderTree-body .builderTree::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 50px;
}
.builderTree-container .builderTree-body .builderTree::-webkit-scrollbar-track {
  background-color: lightgray;
  border-radius: 50px;
}
.builderTree-container.collapsed .builderTree-body {
  background-color: #333333;
}
.builderTree-container.collapsed .builderTree-arrow {
  display: none;
}

.builderTree-container .builderTree-arrow {
  writing-mode: vertical-rl;
  letter-spacing: 2px;
  position: absolute;
  left: 100%;
  top: 2px;
  padding: 0 2px;
  cursor: pointer;
  background-color: dodgerblue;
  border: 1px solid dodgerblue;
  color: white;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  margin-bottom: 3px;
}

.builderTree-container .builderTree-arrow svg {
  vertical-align: baseline;
  margin-bottom: 3px;
  margin-top: 2px;
}

.MuiTreeView-root::-webkit-scrollbar {
  width: 3px;
  background-color: lightgray;
}
.MuiTreeView-root::-webkit-scrollbar-thumb {
  background-color: gray;
}
.MuiTreeView-root::-webkit-scrollbar-track {
  background-color: #303030;
}

.MuiTreeItem-root {
  margin-top: 5px;
}

.workspace {
}
.workspace .default-path-toggle {
  display: flex;
  justify-content: flex-end;
}
.workspace .bold {
  font-weight: bold;
}
.workspace .history-list {
  display: flex;
  font-weight: 700;
  gap: 10px;
  align-items: center;
}
.workspace .history-list .setting-btn {
  cursor: pointer;
  color: #0096cc;
}
.workspace .workspace-list-contents {
  display: flex;
}
.workspace .workspace-list-contents .quick-button {
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
}
.workspace .quick-button:hover {
  color: #0095ce;
}
.workspace-empty-alert {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #bebebebf;
  flex-direction: column;
  gap: 20px;
  height: 600px;
}
.workspace-empty-alert .alert-msg {
  font-weight: bold;
  font-size: larger;
  color: #3c3c3c;
}

/*
  * widget Progress-circle 등
  * -----------------------------------------------------------------------------------
  */
.progress-circle {
  font-size: 20px;
  margin: 10px;
  position: relative; /* so that children can be absolutely positioned */
  padding: 0;
  width: 5em;
  height: 5em;
  background-color: #f2e9e1;
  border-radius: 50%;
  line-height: 5em;
}

.progress-circle:after {
  border: none;
  position: absolute;
  top: 0.35em;
  left: 0.35em;
  text-align: center;
  display: block;
  border-radius: 50%;
  width: 4.3em;
  height: 4.3em;
  background-color: white;
  content: " ";
}
/* Text inside the control */
.progress-circle span {
  position: absolute;
  line-height: 5em;
  width: 5em;
  text-align: center;
  display: block;
  color: #53777a;
  z-index: 2;
}
.left-half-clipper {
  /* a round circle */
  border-radius: 50%;
  width: 5em;
  height: 5em;
  position: absolute; /* needed for clipping */
  clip: rect(0, 5em, 5em, 2.5em); /* clips the whole left half*/
}
/* when p>50, don't clip left half*/
.progress-circle.over50 .left-half-clipper {
  clip: rect(auto, auto, auto, auto);
}
.value-bar {
  /*This is an overlayed square, that is made round with the border radius,
    then it is cut to display only the left half, then rotated clockwise
    to escape the outer clipping path.*/
  position: absolute; /*needed for clipping*/
  clip: rect(0, 2.5em, 5em, 0);
  width: 5em;
  height: 5em;
  border-radius: 50%;
  border: 0.45em solid #53777a; /*The border is 0.35 but making it larger removes visual artifacts */
  /*background-color: #4D642D;*/ /* for debug */
  box-sizing: border-box;
}
/* Progress bar filling the whole right half for values above 50% */
.progress-circle.over50 .first50-bar {
  /*Progress bar for the first 50%, filling the whole right half*/
  position: absolute; /*needed for clipping*/
  clip: rect(0, 5em, 5em, 2.5em);
  background-color: #53777a;
  border-radius: 50%;
  width: 5em;
  height: 5em;
}
.progress-circle:not(.over50) .first50-bar {
  display: none;
}

/* Progress bar rotation position */
.progress-circle.p0 .value-bar {
  display: none;
}
.progress-circle.p1 .value-bar {
  transform: rotate(4deg);
}
.progress-circle.p2 .value-bar {
  transform: rotate(7deg);
}
.progress-circle.p3 .value-bar {
  transform: rotate(11deg);
}
.progress-circle.p4 .value-bar {
  transform: rotate(14deg);
}
.progress-circle.p5 .value-bar {
  transform: rotate(18deg);
}
.progress-circle.p6 .value-bar {
  transform: rotate(22deg);
}
.progress-circle.p7 .value-bar {
  transform: rotate(25deg);
}
.progress-circle.p8 .value-bar {
  transform: rotate(29deg);
}
.progress-circle.p9 .value-bar {
  transform: rotate(32deg);
}
.progress-circle.p10 .value-bar {
  transform: rotate(36deg);
}
.progress-circle.p11 .value-bar {
  transform: rotate(40deg);
}
.progress-circle.p12 .value-bar {
  transform: rotate(43deg);
}
.progress-circle.p13 .value-bar {
  transform: rotate(47deg);
}
.progress-circle.p14 .value-bar {
  transform: rotate(50deg);
}
.progress-circle.p15 .value-bar {
  transform: rotate(54deg);
}
.progress-circle.p16 .value-bar {
  transform: rotate(58deg);
}
.progress-circle.p17 .value-bar {
  transform: rotate(61deg);
}
.progress-circle.p18 .value-bar {
  transform: rotate(65deg);
}
.progress-circle.p19 .value-bar {
  transform: rotate(68deg);
}
.progress-circle.p20 .value-bar {
  transform: rotate(72deg);
}
.progress-circle.p21 .value-bar {
  transform: rotate(76deg);
}
.progress-circle.p22 .value-bar {
  transform: rotate(79deg);
}
.progress-circle.p23 .value-bar {
  transform: rotate(83deg);
}
.progress-circle.p24 .value-bar {
  transform: rotate(86deg);
}
.progress-circle.p25 .value-bar {
  transform: rotate(90deg);
}
.progress-circle.p26 .value-bar {
  transform: rotate(94deg);
}
.progress-circle.p27 .value-bar {
  transform: rotate(97deg);
}
.progress-circle.p28 .value-bar {
  transform: rotate(101deg);
}
.progress-circle.p29 .value-bar {
  transform: rotate(104deg);
}
.progress-circle.p30 .value-bar {
  transform: rotate(108deg);
}
.progress-circle.p31 .value-bar {
  transform: rotate(112deg);
}
.progress-circle.p32 .value-bar {
  transform: rotate(115deg);
}
.progress-circle.p33 .value-bar {
  transform: rotate(119deg);
}
.progress-circle.p34 .value-bar {
  transform: rotate(122deg);
}
.progress-circle.p35 .value-bar {
  transform: rotate(126deg);
}
.progress-circle.p36 .value-bar {
  transform: rotate(130deg);
}
.progress-circle.p37 .value-bar {
  transform: rotate(133deg);
}
.progress-circle.p38 .value-bar {
  transform: rotate(137deg);
}
.progress-circle.p39 .value-bar {
  transform: rotate(140deg);
}
.progress-circle.p40 .value-bar {
  transform: rotate(144deg);
}
.progress-circle.p41 .value-bar {
  transform: rotate(148deg);
}
.progress-circle.p42 .value-bar {
  transform: rotate(151deg);
}
.progress-circle.p43 .value-bar {
  transform: rotate(155deg);
}
.progress-circle.p44 .value-bar {
  transform: rotate(158deg);
}
.progress-circle.p45 .value-bar {
  transform: rotate(162deg);
}
.progress-circle.p46 .value-bar {
  transform: rotate(166deg);
}
.progress-circle.p47 .value-bar {
  transform: rotate(169deg);
}
.progress-circle.p48 .value-bar {
  transform: rotate(173deg);
}
.progress-circle.p49 .value-bar {
  transform: rotate(176deg);
}
.progress-circle.p50 .value-bar {
  transform: rotate(180deg);
}
.progress-circle.p51 .value-bar {
  transform: rotate(184deg);
}
.progress-circle.p52 .value-bar {
  transform: rotate(187deg);
}
.progress-circle.p53 .value-bar {
  transform: rotate(191deg);
}
.progress-circle.p54 .value-bar {
  transform: rotate(194deg);
}
.progress-circle.p55 .value-bar {
  transform: rotate(198deg);
}
.progress-circle.p56 .value-bar {
  transform: rotate(202deg);
}
.progress-circle.p57 .value-bar {
  transform: rotate(205deg);
}
.progress-circle.p58 .value-bar {
  transform: rotate(209deg);
}
.progress-circle.p59 .value-bar {
  transform: rotate(212deg);
}
.progress-circle.p60 .value-bar {
  transform: rotate(216deg);
}
.progress-circle.p61 .value-bar {
  transform: rotate(220deg);
}
.progress-circle.p62 .value-bar {
  transform: rotate(223deg);
}
.progress-circle.p63 .value-bar {
  transform: rotate(227deg);
}
.progress-circle.p64 .value-bar {
  transform: rotate(230deg);
}
.progress-circle.p65 .value-bar {
  transform: rotate(234deg);
}
.progress-circle.p66 .value-bar {
  transform: rotate(238deg);
}
.progress-circle.p67 .value-bar {
  transform: rotate(241deg);
}
.progress-circle.p68 .value-bar {
  transform: rotate(245deg);
}
.progress-circle.p69 .value-bar {
  transform: rotate(248deg);
}
.progress-circle.p70 .value-bar {
  transform: rotate(252deg);
}
.progress-circle.p71 .value-bar {
  transform: rotate(256deg);
}
.progress-circle.p72 .value-bar {
  transform: rotate(259deg);
}
.progress-circle.p73 .value-bar {
  transform: rotate(263deg);
}
.progress-circle.p74 .value-bar {
  transform: rotate(266deg);
}
.progress-circle.p75 .value-bar {
  transform: rotate(270deg);
}
.progress-circle.p76 .value-bar {
  transform: rotate(274deg);
}
.progress-circle.p77 .value-bar {
  transform: rotate(277deg);
}
.progress-circle.p78 .value-bar {
  transform: rotate(281deg);
}
.progress-circle.p79 .value-bar {
  transform: rotate(284deg);
}
.progress-circle.p80 .value-bar {
  transform: rotate(288deg);
}
.progress-circle.p81 .value-bar {
  transform: rotate(292deg);
}
.progress-circle.p82 .value-bar {
  transform: rotate(295deg);
}
.progress-circle.p83 .value-bar {
  transform: rotate(299deg);
}
.progress-circle.p84 .value-bar {
  transform: rotate(302deg);
}
.progress-circle.p85 .value-bar {
  transform: rotate(306deg);
}
.progress-circle.p86 .value-bar {
  transform: rotate(310deg);
}
.progress-circle.p87 .value-bar {
  transform: rotate(313deg);
}
.progress-circle.p88 .value-bar {
  transform: rotate(317deg);
}
.progress-circle.p89 .value-bar {
  transform: rotate(320deg);
}
.progress-circle.p90 .value-bar {
  transform: rotate(324deg);
}
.progress-circle.p91 .value-bar {
  transform: rotate(328deg);
}
.progress-circle.p92 .value-bar {
  transform: rotate(331deg);
}
.progress-circle.p93 .value-bar {
  transform: rotate(335deg);
}
.progress-circle.p94 .value-bar {
  transform: rotate(338deg);
}
.progress-circle.p95 .value-bar {
  transform: rotate(342deg);
}
.progress-circle.p96 .value-bar {
  transform: rotate(346deg);
}
.progress-circle.p97 .value-bar {
  transform: rotate(349deg);
}
.progress-circle.p98 .value-bar {
  transform: rotate(353deg);
}
.progress-circle.p99 .value-bar {
  transform: rotate(356deg);
}
.progress-circle.p100 .value-bar {
  transform: rotate(360deg);
}

div.data-load-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: #a9a9a99f;
}
div.data-load-box {
  background-color: #ecf0f5;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 1px 1px 3px gray;
  color: #3c3c3c;
}

div.grid-data-load-box {
  height: 700px;
  width: 99%;
  position: absolute;
  background: #3c3c3caa;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 1;
  gap: 15px;
}

/*
  * Code mirror , event builder 등.
  * -----------------------------------------------------------------------------------
  */
.code-mirror-wraper {
  position: relative;
}
.code-mirror-wraper .resize-area {
  position: absolute;
  top: 2px;
  right: 2px;
  background-color: #fff;
  cursor: pointer;
}
.code-mirror-wraper.fullscreen .resize-area {
  top: 4px;
  right: 4px;
  font-size: 22px;
}

.code-mirror-wraper.fullscreen {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 3;
}
.code-mirror-wraper.fullscreen,
.code-mirror-wraper.fullscreen > div,
.code-mirror-wraper.fullscreen > div .cm-editor {
  height: 100%;
}

.event-builder-nav-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.event-editor-body {
  min-height: 500px;
  padding: 0;
}
.event-editor {
  /* background: #181f23; */
  position: relative;
}

.text-editor {
  height: 100%;
  width: 100%;
  position: absolute;
}
.text-editor > textarea {
  height: 100%;
}
.event-editor .event-block-snapshot {
  /* min-height: 500px; */
  background-color: #1e1e1e;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.event-editor > ul.nav-tabs {
  width: 300px;
  position: relative;
  z-index: 2;
}
.event-editor.code-editor > ul.nav-tabs {
  width: 150px;
}
.event-editor .edit-source {
  border: none !important;
}
.event-editor .cm-editor.cm-focused {
  outline: none;
}
.react-draggable:has(.event-editor.fullscreen),
.react-draggable:has(.code-mirror-wraper.fullscreen) {
  transform: none !important;
  transition: none !important;
}
.react-draggable:has(.code-mirror-wraper.fullscreen) {
  transform: none !important;
  transition: none !important;
}
.react-draggable:has(.event-editor.open-event-detail),
.react-draggable:has(.event-editor.open-js-function) {
  margin-left: unset !important;
  transform: unset !important;
}

.event-editor.fullscreen .resize-area {
  top: 4px;
  right: 4px;
  font-size: 22px;
}

.event-editor.fullscreen {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 3;
}
.event-editor.fullscreen,
.event-editor.fullscreen .tab-content,
.event-editor.fullscreen .tab-content .tab-pane,
.event-editor.fullscreen .tab-content .tab-pane.active > div,
.event-editor.fullscreen .tab-content .tab-pane.active > div,
.event-editor.fullscreen .tab-content .tab-pane.active .edit-source .cm-editor {
  height: 100% !important;
}

.code-toolbar {
  display: flex;
  position: absolute;
  top: 0px;
  right: 0px;
  align-items: center;
  height: 33.5px;
  justify-content: flex-end;
  padding-right: 12px;
  width: 100%;
  z-index: 1;
  gap: 10px;
}

.code-toolbar .editor-btn {
  cursor: pointer;
}

.code-toolbar .form-select {
  width: 150px;
  background-color: inherit;
  color: inherit;
  padding-top: 0px;
}
.event-editor.eclipse .cm-editor .cm-cursor {
  border-left-color: #000 !important;
}

/*
  * 도움말 문서 화면 (Event API, Component Method 설명)
  * -----------------------------------------------------------------------------------
  */
.doc-page-wrap {
  padding: 1rem;
}
.doc-page-wrap .event-code {
  border: 1px solid #c4bcbc;
  padding: 1rem;
  font-style: italic;
  font-size: 1.1rem;
  margin: 1rem 0;
}
.doc-page-wrap .event-desc code {
  padding-top: 0rem;
}
.doc-page-wrap .doc-title {
  font-weight: 800;
}
.doc-page-wrap .event-params ul,
.doc-page-wrap .event-return ul {
  padding-left: 20px;
  line-height: 1.8;
  margin: 0;
  padding-inline-start: 1.5em;
  list-style: square;
}
.doc-page-wrap .event-params ul li,
.doc-page-wrap .event-return ul li {
  padding-bottom: 1rem;
}
.doc-page-wrap ol {
  display: block;
  list-style-type: circle;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.doc-page-wrap .event-params ul li ol li,
.doc-page-wrap .event-return ul li ol li {
  padding-bottom: 0.1rem;
}

.doc-page-wrap .event-args-var,
.doc-page-wrap .event-args-type,
.doc-page-wrap .event-args-name {
  padding-right: 4px;
  padding-left: 4px;
  color: #ab0e2c;
  font-weight: 500;
}
.doc-page-wrap .event-args-type {
  font-style: italic;
}
.doc-page-wrap .event-return-type {
  padding-left: 4px;
  font-style: italic;
  font-size: 1.1rem;
}

.doc-page-wrap .event-return-desc {
  padding-left: 4px;
}

.doc-page-wrap .event-fnc-mst {
  display: flex;
  gap: 0.4rem;
}

.doc-page-wrap .event-fnc-mst .editor-widget-containter {
  opacity: 1;
  min-height: 160px;
  border-width: 3px 1px 1px;
  border-style: solid;
  border-color: rgb(60, 141, 188) rgb(210, 214, 222) rgb(210, 214, 222);
  border-image: initial;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 1px;
  border-radius: 3px;
  width: 25%;
}
.doc-page-wrap .event-fnc-dtl > span,
.doc-page-wrap .fnc-block-summary span {
  cursor: pointer;
  color: royalblue;
  transition: 1s;
}
.doc-page-wrap .event-fnc-dtl > span:hover,
.doc-page-wrap .fnc-block-summary span:hover {
  color: rgb(15, 30, 77);
  text-decoration: none;
}
.doc-page-wrap .fnc-doc {
  padding-top: 2rem;
}
.doc-page-wrap .fnc-object-name {
  color: blue;
  font-weight: 600;
}

.doc-page-wrap .fnc-doc .fnc-block {
  padding-top: 2rem;
  border-bottom: 2px solid #cdcdcd;
}
.doc-page-wrap pre {
  font-size: 13px;
  font-family: "Noto Sans KR", sans-serif;
}
.doc-page-wrap .fnc-title {
  font-size: 1.2rem;
  padding-top: 1rem;
}
.doc-page-wrap .fnc-block-summary,
.doc-page-wrap .fnc-props-summary {
  padding: 1.1rem;
  border-top: 1px solid #cdcdcd;
  border-bottom: 1px solid #cdcdcd;
}
.doc-page-wrap .fnc-block-summary ul {
  display: flex;
  flex-wrap: wrap;
}
.doc-page-wrap .fnc-block-summary ul li {
  width: 25%;
  padding: 2px 0;
}
.doc-page-wrap .fnc-props-summary ul {
  list-style: inside;
}
.event-list-props {
  width: 100%;
  padding: 4px 0px 4px 4px;
}
.event-list-group {
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
}
.event-list-group .list-group-item {
  background-color: #403c3c;
  border: 1px solid rgb(255 255 255 / 13%);
  color: #fff;
  display: block;
  padding: 0.5rem 1rem;
  position: relative;
  text-decoration: none;
  border-top: none;
}
.event-list-group .list-group-item:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.event-list-group .list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-top: 1px solid rgb(255 255 255 / 13%);
}

.widget-content {
  align-items: center;
  flex-direction: row;
  padding: 1rem;
}
.widget-content .widget-content-left .widget-heading {
  font-weight: 700;
  opacity: 0.9;
}
.widget-content .widget-content-left .widget-subheading {
  opacity: 0.6;
}
.widget-content .widget-content-wrapper {
  align-items: center;
  display: flex;
  flex: 1 1;
  position: relative;
}

.floating-property-tab {
  position: absolute;
  width: 360px;
  height: calc(100vh - 80px - 10px);
  border-radius: 12px;
  background-color: #303030;
  top: 80px;
  left: calc(100% - 400px);
  z-index: 1030;
  box-shadow: 4px 5px 3px rgba(101, 101, 101, 0.855);

  &.min {
    width: 160px;
    height: unset;
    transition: 0.3s;
    & .floating-tab-header {
      width: 100%;
    }
    & .floating-tab-body {
      display: none;
    }
  }

  &.show {
    display: block;
  }

  &.hidden {
    display: none;
  }

  & .floating-tab-header {
    height: 30px;
    cursor: move;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    display: flex;
    justify-content: flex-end;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      color: gray;
      font-weight: bold;
      padding: 0px 10px;
      cursor: pointer;
    }
  }

  & .floating-tab-body {
    height: calc(100% - 30px);
    overflow: overlay;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    padding-bottom: 3px;

    &::-webkit-scrollbar {
      width: 3px;
      background-color: lightgray;
    }
    &::-webkit-scrollbar-thumb {
      background-color: gray;
    }
    &::-webkit-scrollbar-track {
      background-color: #303030;
    }
  }
}
