.reactflow-wrapper.blockly-wrapper {
  width: 100%;
  /* height: calc(100vh - 50px); */
  position: relative;
  overflow: hidden;
}

.blockly-wrapper .blocklyDiv,
.blockly-wrapper #blocklyDiv {
  width: 100%;
  height: calc(100% - 30px);
  overflow: auto;
  cursor: grab;
}

.blockly-wrapper .blocklyDiv .blocklySvg,
.blockly-wrapper #blocklyDiv .blocklySvg {
  height: 100%;
}
.blockly-wrapper .blocklyDiv .blocklyTrash,
.blockly-wrapper #blocklyDiv .blocklyTrash {
  display: none;
}

.blockly-wrapper .blocklyDiv.show {
  height: calc(100vh - 80px);
  width: auto;
  position: relative;
  z-index: 1;
}

.blockly-wrapper .blocklyDiv.hidden {
  position: absolute;
  visibility: hidden;
  height: 0;
  z-index: 0;
  display: none;
}

.blocklyToolboxDiv.blocklyNonSelectable {
  background-color: #fff;
  outline: 1px solid #c6c6c6; /*#3c3c3c;*/
  width: 210px;
  height: calc(100% - 10px) !important;
  /* height: 600px !important; */
  z-index: 1;
  cursor: pointer;
  margin-top: 5px;
  margin-left: 5px;
  border-radius: 10px;
  overflow-y: auto;
  /* margin-top: 200px; */
}
.blocklyScrollbarHandle {
  visibility: hidden;
}

.blocklyFlyoutButton {
  fill: #888;
  cursor: default;
  width: 100px !important;
  height: 50px !important;
}
.blocklyFlyoutButton .blocklyText {
  fill: black !important;
}

.blocklyFlyout {
  /* border-radius: 10px; */
  /* border-left: solid lightgrey 0.5px; */
  border-right: solid lightgrey 0.5px;
  border-bottom: solid lightgrey 0.5px;
}

.blocklyFlyout .blocklyFlyoutBackground {
  fill: #f5f5f5;
  /* width: 355px; */
  height: calc(100% - 10px);
}

.blocklySvg #snapShot {
  background-color: #1e1e1e;
}

#parameterFlyout {
  /* border-radius: 10px; */
}

.blocklyFlyout #parameterFlyoutBackground {
  fill: #d3e3fd;
  /* height: calc(100% - 10px); */
}

#globalVariableFlyout {
  /* border-radius: 10px; */
}
.blocklyFlyout #globalVariableFlyoutBackground {
  fill: #d9f0d7;
}

.blocklyCommentTextarea {
  width: 160;
  height: 80;
  /* background-color: #fef49c; */
  background-color: white;
  border: 0;
  display: block;
  margin: 0;
  outline: 0;
  padding: 3px;
  resize: none;
  text-overflow: hidden;
}

path.blocklyIconShape {
  fill: red;
  stroke: #fff;
  stroke-width: 1px;
}

.blocklyTreeIcon {
  font-size: 13px;
  font-weight: 500;
  color: #3c3c3c;
  font-family: "Noto Sans KR", sans-serif;
}

.blocklyTreeLabel {
  font-size: 13px;
  font-weight: 500;
  color: #3c3c3c;
  font-family: "Noto Sans KR", sans-serif;
}

.blocklyTreeRow {
  padding: 7px;
  padding-left: 3px !important;
  padding-right: 7px;
  margin-bottom: 3px;
  height: initial;
  /* border-radius: 0 150px 150px 0px !important; */
}
.blocklyTreeRowContentContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.eventhandler-panel {
  background: #e6e6e6;
  height: calc(100vh - 51px);
  min-height: 500px;
  width: calc(100%);
  min-width: 800px;
  /* text-align: center; */
  transition: 0.5s;
}

.mirrorContainer {
  position: absolute;
  bottom: 100px !important;
  left: 0px !important;
  height: 150px;
  width: 200px;
  z-index: 2;
  outline: 1px solid black;
}

path.blocklyPath.blockly-ws-search-highlight.blockly-ws-search-current {
  fill: grey;
}

.blockly-ws-search {
  background: #f5f5f5;
  border: solid lightgrey 0.5px;
  /* border-radius: 8px 8px 0px 0px; */
  box-shadow: 0px 0px 0px !important;
  justify-content: center;
  align-items: center;
  padding: 0.25em;
  position: absolute;
  z-index: 70;
  height: 35px;
  width: 270px;
}
.blockly-ws-search-input {
  border: none;
  padding-left: 10px;
}
.blockly-ws-search-input input {
  border: none;
  background: #f5f5f5;
}
.blockly-ws-search button {
  border: none;
}
.blockly-ws-search-actions {
  display: flex;
}
.blockly-ws-search-container {
  display: flex;
  height: 50px;
  width: 275px;
}
.blockly-ws-search-content {
  display: flex;
  height: 45px;
  width: 270px;
}

.blocklyLabelStyle > .blocklyFlyoutLabelText {
  font-size: 16px !important;
}

.blocklyConnectionLine {
  stroke: #ff0000;
  stroke-width: 4px;
  stroke-linecap: round;
}

.minimap {
  position: absolute;
  /* background-color: rgba(211, 211, 211, 0.8); */
}

.mapDragger {
  cursor: move;
  fill: rgb(0, 0, 255);
  /* stroke-width: 0.5;
  stroke: rgb(0, 0, 0); */
  /* outline: 0.5px solid black; */
  fill-opacity: 0.1;
}

.openAnimation {
  transform: translateX(0px);
  transition: 0.5s;
}
.closeAnimation {
  transform: translateX(-300px);
  transition: 0.5s;
}
.eventTree-container {
  position: absolute;
  width: 300px;
  height: calc(100vh - 30px);

  top: 30px;
  left: 0px;
  background-color: #4a4747;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.eventTree-container .eventTree-body .eventTree {
  padding: 5px;
  color: white;
  overflow-y: auto;
  height: 100%;
}
.eventTree-container .eventTree-body .eventTree::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: rgba(211, 211, 211, 0);
}
.eventTree-container .eventTree-body .eventTree::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 50px;
}
.eventTree-container .eventTree-body .eventTree::-webkit-scrollbar-track {
  background-color: lightgray;
  border-radius: 50px;
}

.eventTree-container .eventTree-arrow {
  position: absolute;
  left: 100%;
  padding: 0 2px;
  cursor: pointer;
  background-color: dodgerblue;
  border: 1px solid dodgerblue;
  color: white;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

/* .blockly-minimap {
  z-index: 999;
  position: absolute;
  box-shadow: none;
  width: 200px;
  height: 200px;
  bottom: 5px;
  left: 5px;
} */

.blockly-minimap {
  width: 300px !important;
  height: 200px !important;
  right: 20px;
  left: unset !important;
}

/* ===================================================================절취선========================================================== */
/* 이벤트 빌더 레이아웃 */
.eventhandler-wrapper {
  width: 100%;
  height: calc(100vh - 50px);
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 7fr;
}
.eventhandler-wrapper .eventhandler-left-menu-wrapper {
  background-color: #3c3c3c;
  padding: 10px;
  overflow-y: auto;
  border-left: 1px solid #1e282c;
  border-top: 1px solid #1e282c;
}

.eventhandler-wrapper .eventhandler-left-menu-wrapper::-webkit-scrollbar {
  width: 3px;
  background-color: lightgray;
}
.eventhandler-wrapper .eventhandler-left-menu-wrapper::-webkit-scrollbar-thumb {
  background-color: gray;
}
.eventhandler-wrapper .eventhandler-left-menu-wrapper::-webkit-scrollbar-track {
  background-color: #303030;
}

.eventhandler-wrapper .eventhandler-left-menu-wrapper .category {
  background-color: #1e282c;
  padding: 8px 15px;
  margin: 6px 0;
  border-radius: 15px;
  color: whitesmoke;
  cursor: pointer;

  font-size: 12px;
  display: flex;
  user-select: none;
}

.eventhandler-wrapper .eventhandler-left-menu-wrapper .category:hover {
  background-color: whitesmoke;
  color: #1e282c;
}

.eventhandler-wrapper .eventhandler-left-menu-wrapper .category.title {
  background-color: #804996;
  font-size: 15px;
  padding: 10px 15px;
}
.eventhandler-wrapper .eventhandler-left-menu-wrapper .category.title:hover {
  cursor: default;
  background-color: #804996;
  color: whitesmoke;
}

.eventhandler-wrapper
  .eventhandler-left-menu-wrapper
  .category
  span:first-child {
  margin-right: 5px;
}

.eventhandler-wrapper
  .eventhandler-left-menu-wrapper
  .category
  span:nth-child(2) {
  display: block;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 80%;
}

.eventhandler-wrapper
  .eventhandler-left-menu-wrapper
  .category
  span:last-child {
  transition: 0.5s;
}
.eventhandler-wrapper
  .eventhandler-left-menu-wrapper
  .category
  span:last-child.fold {
  transform: rotate(180deg);
}

@keyframes listOpen {
  1% {
    display: block;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
    overflow: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
    transition: 0.5s;
  }
}
@keyframes listClose {
  1% {
    opacity: 1;
    visibility: visible;
    transition: 0.5s;
  }
  100% {
    display: none;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
    overflow: hidden;
  }
}

.eventhandler-wrapper .eventhandler-left-menu-wrapper .child-wrapper.fold {
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  overflow: hidden;
  transition-delay: 0.1s;
}
.eventhandler-wrapper .eventhandler-left-menu-wrapper .child-wrapper.expand {
  opacity: 1;
  visibility: visible;
  transition: 0.5s;
}

.eventhandler-wrapper .eventhandler-left-menu-wrapper .child-wrapper .child {
  color: whitesmoke;
  font-size: 12px;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 4px 30px;
  border-radius: 15px;
  cursor: pointer;
  margin: 3px 0;
  margin-left: 20px;
  transition: 0.1s;
  transition-delay: 0;
}
.eventhandler-wrapper
  .eventhandler-left-menu-wrapper
  .child-wrapper
  .child.selected {
  background-color: whitesmoke;
  color: #1e282c;
}

.eventhandler-wrapper
  .eventhandler-left-menu-wrapper
  .child-wrapper.expand
  .child {
  visibility: visible;
  /* transition: 0.2s;
  transition-delay: 0.3s; */
  opacity: 1;
}
.eventhandler-wrapper
  .eventhandler-left-menu-wrapper
  .child-wrapper.fold
  .child {
  visibility: hidden;
  transition: 0.2s;
  opacity: 0;
}

.eventhandler-wrapper
  .eventhandler-left-menu-wrapper
  .child-wrapper
  .child:hover {
  background-color: rgb(108, 108, 108);
  color: #dddddd;
}

.blockly-wrapper .edit-source.show {
  position: inherit;
  z-index: 1;
  visibility: visible;
}

.blockly-wrapper .edit-source.hidden {
  position: absolute;
  z-index: 0;
  visibility: hidden;
}
