/**
 * Color define
 *  - header bg (main-header) : #3c3c3c
 *  - left sidebar(left-side) bg : #333333
 *  - right sidebar - bg (control-sidebar) : #1F1F1F
 *                  - tab bg : #181f23 , actived tab : #1F1F1F
 *                  - tab line : #141a1d
 */
.skin-dark .main-header .top-left-buttons {
  background-color: #3c3c3c;
}

.skin-dark .main-header .navbar {
  background-color: #3c3c3c;
}

.skin-dark .main-header .navbar .nav > li > a {
  color: #fff;
}

.skin-dark .main-header .navbar .nav > li > .link > .nav-button:hover,
.skin-dark .main-header .navbar .navbar-right .nav > li:hover {
  background-color: #1e1e1e;
}
.skin-dark .main-header .navbar .nav > li > .link > .nav-button:hover,
.skin-dark .main-header .navbar .navbar-right .nav > li:hover {
  background-color: #1e1e1e;
}
.skin-dark .main-header .navbar .nav > li > .link > .nav-button.disabled:hover {
  background-color: #3c3c3c;
}
.skin-dark .main-header .navbar .nav > li > .link > .nav-button {
  border-right: 1px solid #1e1e1e;
}
.skin-dark .main-header .navbar .nav > li:first-child > .link > .nav-button {
  border-left: 1px solid #1e1e1e;
}
.skin-dark .main-header .navbar .nav > li > a:hover,
.skin-dark .main-header .navbar .nav > li > a:active,
.skin-dark .main-header .navbar .nav > li > a:focus,
.skin-dark .main-header .navbar .nav .open > a,
.skin-dark .main-header .navbar .nav .open > a:hover,
.skin-dark .main-header .navbar .nav .open > a:focus,
.skin-dark .main-header .navbar .nav > .active > a {
  background: rgba(0, 0, 0, 0.1);
  color: #f6f6f6;
}

.skin-dark .main-header .navbar .nav > li > .link > .nav-button.disabled {
  color: rgb(192, 188, 188);
}
.skin-dark
  .main-header
  .navbar
  .nav.preview
  > li
  > .link
  > .nav-button:not(.disabled) {
  background-color: #1e1e1e;
}

.skin-dark .main-header .navbar .sidebar-toggle {
  color: #fff;
}

.skin-dark .main-header .navbar .sidebar-toggle:hover {
  color: #f6f6f6;
  background: rgba(0, 0, 0, 0.1);
}

.skin-dark .main-header .navbar .sidebar-toggle {
  color: #fff;
}

.skin-dark .main-header .navbar .sidebar-toggle:hover {
  background-color: #555299;
}

@media (max-width: 767px) {
  .skin-dark .main-header .navbar .dropdown-menu li.divider {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .skin-dark .main-header .navbar .dropdown-menu li a {
    color: #fff;
  }

  .skin-dark .main-header .navbar .dropdown-menu li a:hover {
    background: #555299;
  }
}

.skin-dark .main-header .logo {
  background-color: #3c3c3c;
  color: #fff;
  border-bottom: 0 solid transparent;
}

.skin-dark .main-header .logo:hover {
  background-color: #4d4c4c;
}

.skin-dark .main-header li.user-header {
  background-color: #605ca8;
}

.skin-dark .content-header {
  background: transparent;
}

.skin-dark .main-sidebar,
.skin-dark .left-side {
  background-color: #333333;
}

.skin-dark .sidebar-menu > li.header {
  color: #4b646f;
  background: #1a2226;
}

.skin-dark .sidebar-menu > li {
  color: rgb(192, 188, 188);
  border-left: 3px solid transparent;
}

.skin-dark .sidebar-menu > li:hover,
.skin-dark .sidebar-menu > li.active,
.skin-dark .sidebar-menu > li.menu-open > a {
  color: #fff;
  background: #1e282c;
}

.skin-dark .sidebar-menu > li.active {
  border-left-color: #fff;
}
.skin-dark .sidebar a {
  color: #b8c7ce;
}

.skin-dark .sidebar a:hover {
  text-decoration: none;
}

.skin-dark .control-sidebar {
  background: #1f1f1f;
  color: #b8c7ce;
}

.skin-dark .control-sidebar > .nav-tabs,
.event-editor.vscode > .nav-tabs {
  border-bottom: #1c2529;
}

/* Tab 기본색*/
.skin-dark .control-sidebar .nav-tabs > li > button,
.event-editor.vscode > .nav-tabs > li > button {
  background: #181f23;
  color: #b8c7ce;
}
/*not actived tab - mouse hover */
.skin-dark .control-sidebar .nav-tabs > li > button:not(.active),
.event-editor.vscode > .nav-tabs > li > button:not(.active) {
  border-left: 1px solid #262626;
  /*border-bottom: 1px solid #141a1d; */
  border-bottom: 1px solid #262626;
  border-right: 1px solid #262626;
}

/*not actived tab - mouse hover */
.skin-dark .control-sidebar .nav-tabs > li > button:not(.active):hover,
.skin-dark .control-sidebar .nav-tabs > li > button:not(.active):focus,
.skin-dark .control-sidebar .nav-tabs > li > button:not(.active):active,
.event-editor.vscode .nav-tabs > li > button:not(.active):hover,
.event-editor.vscode .nav-tabs > li > button:not(.active):focus,
.event-editor.vscode .nav-tabs > li > button:not(.active):active {
  background: #1c2529;
  color: #fff;
}
/* actived tab - mouse hover */
.skin-dark .control-sidebar .nav-tabs > li > button.active,
.skin-dark .control-sidebar .nav-tabs > li > button.active:hover,
.skin-dark .control-sidebar .nav-tabs > li > button.active:focus,
.skin-dark .control-sidebar .nav-tabs > li > button.active:active,
.event-editor.vscode .nav-tabs > li > button.active,
.event-editor.vscode .nav-tabs > li > button.active:hover,
.event-editor.vscode .nav-tabs > li > button.active:focus,
.event-editor.vscode .nav-tabs > li > button.active:active {
  background: #1f1f1f;
  color: #fff;
  border-left: 1px solid #262626;
  border-right: 1px solid #262626;
  border-bottom: 1px solid #6c6ccf;
  opacity: 0.9;
}

.event-editor.vscode .nav-tabs > li > button.active,
.event-editor.vscode .nav-tabs > li > button.active:hover,
.event-editor.vscode .nav-tabs > li > button.active:focus,
.event-editor.vscode .nav-tabs > li > button.active:active {
  border-bottom: none;
  border-top: 2px solid #6c6ccf;
}

.skin-dark .control-sidebar-heading,
.skin-dark .control-sidebar-subheading {
  color: #fff;
}

.skin-dark .control-sidebar-menu > li > a:hover {
  background: #1e282c;
}

.skin-dark .control-sidebar-menu > li > a .menu-info > p {
  color: #b8c7ce;
}

.skin-dark .main-header .navbar .nav .dropdown-item {
  background: #1f1f1f;
  color: #fff;
}
.skin-dark .main-header .navbar .nav .dropdown-item:hover {
  background: #094771;
}
.skin-dark .main-header .navbar .nav .toolbar-dropdown ul {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  background-color: #1f1f1f;
}

.skin-dark .control-sidebar .accordion-item {
  border-bottom: 1px solid #333333;
}
.skin-dark .control-sidebar .accordion-button {
  color: #fff;
  background-color: #1f1f1f;
}
.skin-dark .control-sidebar .accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #333333;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
.skin-dark .control-sidebar .accordion-button:focus {
  border-color: #657181;
  box-shadow: 0 0 0 0.25rem rgba(3, 44, 107, 0.25);
}

.skin-dark .control-sidebar .accordion-button::after {
  background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23e7f1ff%27%3E%3Cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e);
}

.skin-dark .control-sidebar .accordion-body {
  background-color: #3c3c3c;
  color: #fff;
}

.skin-dark .code-toolbar {
  background: #181f23;
  border-bottom: 1px solid #262626;
  color: #b8b2b2;
}
