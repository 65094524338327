.aa{
    background-color: red;
}

.container {
    width: 300px;
    height: 470px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    padding-left: 10px;
    background-color: gray;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-left:auto;
    margin-right:auto;
}

.result_container {
    width: 280px;
    height: 100px;
    background-color: black;
    color: orange;
    text-align: right;
    padding-top: 10%;
    padding-right: 3%;
    font-size: 30px
}

.button_container {
    width: 280px;
    height: 350px;
    background-color: black;
}

.rollback_button {
    width: 140px;
    height: 70px;
    background-color: orange;
    color: white;
    font-size: 20px;
}

.number_button {
    width: 70px;
    height: 70px;
    background-color: darkgrey;
    color: white;
    font-size: 15px;
}

.calc_button {
    width: 70px;
    height: 70px;
    background-color: white;
    color:black;
    font-size: 15px;
}

.flex_container {
    display: grid;
    
    grid-template-rows: repeat(2, 150px);
    grid-template-columns: repeat(4, 1fr);
    align-content: space-between;
}

.item {
    width: auto;
    height: 150px;
}