@keyframes outline_blink_effect {
  50% {
    outline-color: #fff;
  }
}
.border-blink {
  animation: 1s linear infinite outline_blink_effect;
}

.reactflow-wrapper.workflow {
  width: 100%;
  height: calc(100vh - 50px);
  position: relative;
  overflow: hidden;
}

.workflow .edit-tab {
  width: 100%;
  background-color: #f8f8f8;
  padding: 0.2rem;
  display: flex;
  height: 30px;
  justify-content: space-between;
  padding-right: 30px;
}
.workflow .edit-tab .side {
  display: flex;
  gap: 10px;
}
.workflow .edit-tab .right.side .list-button {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.workflow .edit-tab .right.side .program-id-label {
  display: flex;
  height: 100%;
  align-items: center;
}

.reactflow-wrapper.workflow .flow-editor-wrapper {
  width: 100%;
  height: calc(100% - 30px);
  position: relative;
}

.reactflow-wrapper.workflow .edit-source {
  width: 100%;
  /* height: ; */
  overflow: auto;
}
.reactflow-wrapper.workflow .edit-source .edit-source-options {
  display: flex;
  justify-content: flex-end;
  height: 30px;
}

.reactflow-wrapper.workflow .edit-source .codeMirror-area {
  width: 100%;
  height: calc(100% - 30px);
  overflow: auto;
}

.flow-editor-wrapper .workflow-node-list.light {
  position: absolute;
  top: 55px;
  left: 3px;
  width: 200px;
  height: 300px;
  background: white;
  outline: 1px solid gray;
  z-index: 10;
  border-radius: 5px;
  box-shadow: 1px 1px 3px black;
  overflow-y: auto;
}
.flow-editor-wrapper .workflow-node-list.dark {
  position: absolute;
  top: 55px;
  left: 3px;
  width: 200px;
  height: 300px;
  outline: 1px solid gray;
  z-index: 10;
  border-radius: 5px;
  box-shadow: 1px 1px 3px gray;
  overflow-y: auto;
  background: #3c3c3c;
}
.flow-editor-wrapper .workflow-node-list .node.light {
  height: 50px;
  font-weight: 500;
  display: flex;
  color: #3c3c3c;
  border-bottom: 1px solid #3c3c3c;
  padding-left: 10px;
  gap: 10px;
  cursor: pointer;
  user-select: none;
}
.flow-editor-wrapper .workflow-node-list .node.dark {
  height: 50px;
  font-weight: 500;
  display: flex;
  border-bottom: 1px solid gray;
  padding-left: 10px;
  gap: 10px;
  cursor: pointer;
  user-select: none;
  color: white;
}

.flow-editor-wrapper .workflow-node-list .node .icon {
  display: flex;
  align-items: center;
  height: 100%;
}
.flow-editor-wrapper .workflow-node-list .node .label {
  display: flex;
  align-items: center;
  height: 100%;
}
.workflow-memo {
  height: 100%;
  background-color: beige;
  box-shadow: 1px 2px 3px black;
  border-radius: 5px;
}
.workflow-memo .header {
  height: 30px;
  border-bottom: 1px solid lightgray;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}
.workflow-memo .header .title {
  width: calc(100% - 45px);
}
.workflow-memo .header .button-area {
  width: 45px;
  display: flex;
}
.workflow-memo .header .button-area button {
  border: none;
  background-color: beige;
}
.workflow-memo .description-wrapper {
  height: calc(100% - 30px);
  overflow-y: auto;
  padding: 5px;
}
.workflow-memo .description-wrapper .description {
  min-width: 190px;
  min-height: 90px;
  height: 100%;
  white-space: pre-wrap;
}

.workflow-memo .description-wrapper textarea.description {
  border-width: 2px;
  width: 100%;
  height: calc(98% - 40px);
  overflow: auto;
  resize: none;
}
.workflow-memo .description-wrapper .button-area {
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 10px;
  gap: 5px;
}

.workflow-process-edge-wrapper {
  width: 173px;
  height: 51px;
  border-radius: 50px;
  padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f3;
  color: gray;
  border: 1px solid #3c3c3c;
  box-shadow: 1px 1px 3px gray;
  font-size: 20px;
  font-weight: 500;
}
.workflow-process-edge-wrapper.traced {
  outline: 5px solid tomato;
}

@keyframes invalid-node {
  0% {
    background-color: #f2f2f3;
  }
  50% {
    background-color: tomato;
    color: white;
  }
  100% {
    background-color: #f2f2f3;
  }
}

.workflow-process-edge-wrapper.invalid {
  background-color: tomato;
  animation: invalid-node 1.5s linear infinite;
}

.workflow-process-node-wrapper {
  width: 323px;
  height: 111px;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid black;
  box-shadow: 1px 2px 3px black;
}
.workflow-process-node-wrapper.LoopControlKeyword {
  width: 280px;
  height: 81px;
}

.debug-info {
  position: absolute;
  height: 30px;
  width: 60%;
  top: -40px;
  right: 0;
  z-index: 1;
  border: 1px solid #3c3c3c;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 5px;
  background-color: white;
}
.debug-info div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.debug-info div:first-child {
  border-right: 1px solid black;
}

.debug-info.before div:first-child {
  background-color: dodgerblue;
  color: white;
}

.debug-info.after div:last-child {
  background-color: dodgerblue;
  color: white;
}

.workflow-process-node-wrapper.selected {
  outline: 5px solid dodgerblue;
}
.workflow-process-node-wrapper.traced {
  outline: 5px solid tomato;
}
.workflow-process-node-wrapper .header {
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
  padding: 0 5px;
  background-color: white;
  border-bottom: 1px solid lightgray;
}
.workflow-process-node-wrapper .header .title {
  display: flex;
  gap: 3px;
  font-size: 20px;
  font-weight: bold;
}

.workflow-process-node-wrapper .header .title .name {
  /* max-width: 180px; */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}
.workflow-process-node-wrapper .header .control-button {
  display: flex;
}

.workflow-process-node-wrapper .header button {
  border: none;
  background-color: transparent;
}
.workflow-process-node-wrapper .workflow-process-node {
  width: 100%;
  height: calc(100% - 40px);
  background-color: #f2f2f3;
  padding: 5px 20px;
}
.workflow-process-node-wrapper .workflow-process-node.LoopControlKeyword {
  width: 100%;
  height: calc(100% - 40px);
  background-color: #636363;
  color: whitesmoke;
  padding: 5px 20px;
  display: flex;
  align-items: center;
}
.workflow-process-node-wrapper .workflow-process-node .process-type {
  display: flex;
  gap: 25px;
  margin-bottom: 5px;
  height: 40%;
  font-size: 16px;
  font-weight: bold;
  justify-content: flex-start;
  align-items: center;
}

.workflow-process-node-wrapper .workflow-process-node .process-name {
  display: flex;
  align-items: center;
  font-weight: bold;
}
.workflow-process-node-wrapper .workflow-process-node .service-edit-button {
  margin-top: 5px;
  width: 100%;
}

.workflow-process-node-wrapper.service-node .workflow-process-node {
  padding-top: 0;
  height: 100%;
}

.workflow-process-node-wrapper.service-node .workflow-process-node {
  background-color: rgb(169, 212, 255);
}
.workflow-process-node-wrapper.service-node
  .workflow-process-node
  .service-border {
  height: calc(100% - 37px);
  border: 1px solid lightslategray;
  border-top: none;
  border-bottom: none;
  padding: 0 5px;
  display: flex;
}

.workflow-process-node-wrapper.service-node
  .workflow-process-node
  .service-border
  > div:first-child {
  width: 80%;
}
.workflow-process-node-wrapper.service-node
  .workflow-process-node
  .service-border
  > div:last-child {
  width: 20%;
  display: flex;
  align-items: center;
}

.workflow-process-node-wrapper .workflow-process-node .code-node-wrapper {
  display: flex;
  gap: 20px;
}
.workflow-process-node-wrapper
  .workflow-process-node
  .code-node-wrapper
  .code-type {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  width: 25%;
}

/*********** Node 묶음 *************/
.workflow-bundle-node-wrapper {
  border: 1px solid rgb(118, 187, 255);
  border-radius: 5px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.526);
}

.workflow-bundle-node-wrapper.selected {
  outline: 3px solid dodgerblue;
}

.workflow-bundle-node-wrapper .header {
  height: 40px;
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
  align-items: center;
}

.workflow-bundle-node-wrapper .header button {
  border: none;
  background-color: transparent;
}
.workflow-bundle-node-wrapper .header div:last-child {
  display: flex;
  gap: 15px;
}
.workflow-bundle-node-wrapper .handle-wrapper {
  visibility: hidden;
}

.bundle-detail-popup {
  width: 100%;
  position: absolute;
}

.bundle-detail-popup .workflow-node-list {
  position: absolute;
  top: 30px;
  left: -100px;
  width: 200px;
  height: 300px;
  background: white;
  outline: 1px solid gray;
  z-index: 100;
  border-radius: 5px;
  box-shadow: 1px 1px 3px black;
  overflow-y: auto;
}

/****************************WF 팝 업 내 부******************************/
.workflow-modal-template .fieldset-border {
  border: 1px solid lightgray;
  border-radius: 5px;
}

.workflow-modal-template .source-container {
  border: 1px solid lightgray;
}

.workflow-modal-template .grid-title {
  margin-top: 15px;
  margin-bottom: 3px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.workflow-modal-template .guide-tooltip {
  color: gray;
  font-size: 10px;
  margin-left: 5px;
  font-style: italic;
  display: flex;
  height: 100%;
  align-items: center;
}

.workflow-modal-template .guide-tooltip:hover {
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
}

.workflow-modal-template .grid-wrapper {
}
.workflow-modal-template .grid-wrapper .header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-top: 1px solid lightgray;
  border-left: 1px solid lightgray;
}
.workflow-modal-template .grid-wrapper .header:first-child {
  border-left: none;
}
.workflow-modal-template .grid-wrapper .header.required::after {
  content: "*";
  color: red;
}

.workflow-modal-template .grid-wrapper .grid-body {
  max-height: 400px;
  min-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

.workflow-modal-template .grid-wrapper .grid-body .row {
  border-bottom: 1px solid lightgray;
}

.workflow-modal-template .grid-wrapper .cell {
  display: flex;
  align-items: center;
  height: 40px;
  gap: 5px;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
}

.workflow-modal-template .grid-wrapper .cell.center {
  justify-content: center;
}

.workflow-modal-template .grid-wrapper .cell:last-child {
  padding-right: 20px;
}

.workflow-modal-template .grid-wrapper .cell input {
}
.workflow-modal-template .grid-wrapper .cell.remark {
  display: flex;
  justify-content: center;
  align-items: center;
}
.data-model-entity-select-wrapper {
}
.data-model-entity-select-wrapper .search-bar {
  display: flex;
  margin: 15px 0;
  justify-content: space-between;
}
.data-model-entity-select-wrapper .search-bar div {
  width: 320px;
  display: flex;
  align-items: center;
}

.data-model-entity-list-wrapper {
  display: flex;
  gap: 15px;
  justify-content: space-around;
  flex-direction: row;
  width: 100%;
  height: 450px;
}
.data-model-entity-list-wrapper .list {
  border: 1px solid lightgray;
  border-radius: 5px;
  width: 320px;
  height: 100%;
}
.data-model-entity-list-wrapper .list .header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  background-color: lightgray;
  color: #3c3c3c;
  border-bottom: 1px solid lightgray;
}
.data-model-entity-list-wrapper .list .header > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.data-model-entity-list-wrapper .list .header > div:first-child {
  border-right: 1px solid lightgray;
}
.data-model-entity-list-wrapper .list .body {
  overflow-y: auto;
  height: calc(100% - 30px);
  width: 100%;
}
.data-model-entity-list-wrapper .list .body .dm-row {
  width: 100%;
  display: flex;
  border-bottom: 1px solid lightgray;
  cursor: pointer;
}
.data-model-entity-list-wrapper .list .body .dm-row.selected {
  background-color: #3c3c3c;
  color: white;
}

.data-model-entity-list-wrapper .list .body .dm-row:hover {
  background-color: lightgray;
  color: #3c3c3c;
}

.data-model-entity-list-wrapper .list .body .cell {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
}
.data-model-entity-list-wrapper .list .body .empty {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: lightgray;
}
.data-model-entity-list-wrapper .list.data-binding .header > div {
  width: 40%;
}
.data-model-entity-list-wrapper .list.data-binding .header > div:last-child {
  width: 20%;
}
.data-model-entity-list-wrapper .list.data-binding .body .cell {
  width: 40%;
}
.data-model-entity-list-wrapper .list.data-binding .body .cell:last-child {
  width: 20%;
}

.database-wrapper .database-list-wrapper {
  min-height: 250px;
  max-height: 450px;
  overflow-y: auto;
  border: 1px solid lightgray;
}
.database-wrapper .database-list-wrapper .loading {
  width: 100%;
  height: 250px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.database-wrapper .database-list-wrapper .entity {
  border-bottom: 1px solid lightgray;
  padding: 5px 0px 5px 10px;
  cursor: pointer;
}

.database-wrapper .database-list-wrapper .entity.selected {
  background-color: #3c3c3c;
  color: white;
}

.database-wrapper .database-list-wrapper .entity:hover {
  background-color: lightgray;
  color: #3c3c3c;
}

.workflow-modal-template {
  padding: 0 10px;
}
.workflow-modal-template .fr {
  display: flex;
  justify-content: flex-end;
  gap: 3px;
}

.select-entity-wrapper {
  width: 100%;
}

.process-entity-list-wrapper {
}

.process-entity-list-wrapper .process-list {
  height: 500px;
}

.condition-input-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  overflow-x: auto;
  padding: 5px;
  max-height: 500px;
}
.condition-input-wrapper .condition-input {
  border-radius: 5px;
  background-color: rgb(184, 219, 255);
  padding: 5px 15px;
  box-shadow: 1px 1px 5px dodgerblue;
  cursor: pointer;
}
.condition-input-wrapper .condition-input .param-name {
  width: 145px;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  overflow: hidden;
}
.condition-input-wrapper .condition-input.disabled {
  background-color: lightgray;
  box-shadow: 1px 1px 5px gray;
}

.connector-popup .connector-input-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  overflow-x: auto;
  padding: 5px;
  max-height: 500px;
}

.connector-popup .connector-input-wrapper .connector-input {
  border-radius: 5px;
  background-color: rgb(184, 219, 255);
  padding: 5px 15px;
  box-shadow: 1px 1px 5px dodgerblue;
  cursor: pointer;
}
.connector-popup .connector-input-wrapper .connector-input .param-name {
  width: 145px;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  overflow: hidden;
}
.connector-popup .connector-input-wrapper .connector-input .param-type {
}
.connector-popup .connector-input-wrapper .connector-input.disabled {
  background-color: lightgray;
  box-shadow: 1px 1px 5px gray;
}
.workflow-connector-else {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(211, 211, 211, 0.685);
  border-right: 1px solid gray;
  border-bottom: 1px solid gray;
  border-radius: 5px;
  padding: 2px;
  z-index: 1;
  font-size: 10px;
}
.workflow-connector-node-wrapper.selected {
  outline: 5px solid dodgerblue;
}
.workflow-connector-node-wrapper {
  width: 173px;
  height: 51px;
  background-color: rgb(255, 199, 125);
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  border-radius: 5px;
  box-shadow: 1px 2px 3px black;
  font-weight: bold;
  padding-right: 50px;
  padding-left: 20px;
}
.workflow-connector-node-wrapper div {
  white-space: pre-wrap;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 100%;
}
.workflow-connector-node-wrapper.no-property {
  min-width: auto;
  width: 83px;
  height: 51px;
  padding: 0;
  border-radius: 5px;
  background-color: lightgray;
}

.workflow-connector-node-wrapper button {
  border: none;
  background-color: transparent;
  position: absolute;
  right: 15px;
}

.workflow-connector-node-wrapper.no-property button {
  right: 0;
  position: inherit;
}

.diamond-container {
  position: relative;
  width: 300px;
  height: 150px;
}

.workflow-condition-node {
  width: 0;
  height: 0;
  position: relative;
  border: 71.2px solid transparent;
  border-right: 131.4px solid transparent;
  border-left: 132px solid transparent;
  border-bottom-color: rgb(168, 212, 233);
  top: -71.2px;
}

.workflow-condition-node::after {
  content: "";
  position: absolute;
  left: -132px;
  top: 70.5px;
  width: 0;
  height: 0;
  border: 71.2px solid transparent;
  border-right: 131.4px solid transparent;
  border-left: 132.4px solid transparent;
  border-top-color: rgb(168, 212, 233);
}

.workflow-node .selected-marker {
  /* visibility: hidden; */
  position: absolute;
  width: 0;
  height: 0;
  border: 79.2px solid transparent;
  border-right: 141.4px solid transparent;
  border-left: 139px solid transparent;
  border-bottom-color: dodgerblue;
  top: -86.2px;
  left: -6px;
  display: none;
}
.workflow-node .selected-marker::after {
  content: "";
  position: absolute;
  left: -139px;
  top: 79.5px;
  width: 0;
  height: 0;
  border: 78.2px solid transparent;
  border-right: 143.4px solid transparent;
  border-left: 139.4px solid transparent;
  border-top-color: dodgerblue;
}

.workflow-node .selected-marker.selected {
  display: block;
}

.workflow-node .selected-marker.traced {
  display: block;
  border-bottom-color: tomato;
}
.workflow-node .selected-marker.traced::after {
  border-top-color: tomato;
}

@keyframes condition_blink_effect {
  0% {
    border-bottom-color: rgba(30, 143, 255, 0.332);
  }
  50% {
    border-bottom-color: dodgerblue;
  }
  100% {
    border-bottom-color: rgba(30, 143, 255, 0.332);
  }
}

@keyframes condition_bottom_blink_effect {
  0% {
    border-top-color: rgba(30, 143, 255, 0.332);
  }
  50% {
    border-top-color: dodgerblue;
  }
  100% {
    border-top-color: rgba(30, 143, 255, 0.332);
  }
}

.workflow-node .selected-marker.bundling {
  display: block;
  animation: 1s linear infinite condition_blink_effect;
}
.workflow-node .selected-marker.bundling::after {
  display: block;
  animation: 1s linear infinite condition_bottom_blink_effect;
}

@keyframes condition_bottom_breakpoint_blink_effect {
  0% {
    border-top-color: rgba(255, 99, 71, 0.332);
  }
  50% {
    border-top-color: tomato;
  }
  100% {
    border-top-color: rgba(255, 99, 71, 0.332);
  }
}
@keyframes condition_top_breakpoint_blink_effect {
  0% {
    border-bottom-color: rgba(255, 99, 71, 0.332);
  }
  50% {
    border-bottom-color: tomato;
  }
  100% {
    border-bottom-color: rgba(255, 99, 71, 0.332);
  }
}
.workflow-node .selected-marker.condition-breakpoint {
  animation: 1s linear infinite condition_top_breakpoint_blink_effect;
}

.workflow-node .selected-marker.condition-breakpoint::after {
  animation: 1s linear infinite condition_bottom_breakpoint_blink_effect;
}

.workflow-condition-btn {
  border: none;
  margin-bottom: 10px;
  background-color: rgb(168, 212, 233);
}

.condition-content {
  position: absolute;
  top: -18px;
  left: 0;
  width: 264px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  /* height: 50px;
  width: 200px;
  left: -100px;
  top: 67px;
  position: absolute;
  z-index: 100; */

  text-align: center;
  font-size: 17px;
  font-weight: bold;
}
.condition-content .name {
  width: 180px;
  max-width: 180px;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
}

.workflow-condition-node .header .title {
  display: flex;
  gap: 3px;
  font-size: 20px;
  font-weight: bold;
}

.workflow-condition-node .header .title .name {
  /* max-width: 180px; */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}
.workflow-condition-node .header .control-button {
  display: flex;
}

.workflow-condition-node .header button {
  border: none;
  background-color: white;
}

.workflow-node {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 1px;
}

.workflow-node .handle-wrapper {
  visibility: hidden;
}
/* 주석은 연결 못하도록 */
.workflow-node.comment:hover .handle-wrapper {
  visibility: hidden;
}

@keyframes bundle_blink_effect {
  0% {
    outline-style: solid;
    outline-color: dodgerblue;
    outline-width: 5px;
  }
  50% {
    outline-width: 10px;
    outline-style: solid;
    outline-color: dodgerblue;
  }
  100% {
    outline-width: 5px;
    outline-style: solid;
    outline-color: dodgerblue;
  }
}

/* 주석은 연결 못하도록 */
.workflow-node.bundling {
  animation: 1s linear infinite bundle_blink_effect;
}

.workflow-node:hover .handle-wrapper {
  visibility: visible;
}

.workflow-node.bundle .handle-wrapper {
  visibility: hidden;
}

.targetHandle {
  width: 15px;
  height: 15px;
  background-color: #3c3c3c;
  border: 2px solid white;
}

.targetHandle.react-flow__handle-top {
  top: -5px;
}
.targetHandle.react-flow__handle-bottom {
  bottom: -5px;
}
.targetHandle.react-flow__handle-left {
  left: -5px;
}
.targetHandle.react-flow__handle-right {
  right: -5px;
}

/*******************워크 플로우 스타일**********************************************/
/* .customNodeBody {
  width: 150px;
  height: 80px;
  border: 3px solid black;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.customNode:before {
  content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  height: 20px;
  width: 40px;
  transform: translate(-50%, 0);
  background: #d6d5e6;
  z-index: 1000;
  line-height: 1;
  border-radius: 4px;
  color: #fff;
  font-size: 9px;
  border: 2px solid #222138;
} */

.edgebutton-foreignobject div {
  background: transparent;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30px;
  background-color: lightgray;
  border-radius: 100%;
  border: 1px solid black;
  font-size: 20px;
}

.entity-validation .grid-wrapper {
  border-collapse: collapse;
  max-height: 400px;
  min-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}
.entity-validation .grid-wrapper .header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-top: 3px solid lightgray;
  border-bottom: 2px solid lightgray;
  border-left: 1px solid lightgray;
}
.entity-validation .grid-wrapper .header:first-child {
  border-left: none;
}
.entity-validation .grid-wrapper .header.required::after {
  content: "*";
  color: red;
}

.entity-validation .grid-wrapper .cell {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  gap: 5px;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  border-left: 1px solid lightgray;
}
.entity-validation .grid-wrapper .cell:first-child {
  border-left: none;
}

/* 자동완성 */
.workflow-modal-template .cm-tooltip-autocomplete.cm-tooltip.cm-tooltip-below {
  /* position: fixed !important;
  top: 230px !important;
  left: 15px !important; */
}
.workflow-modal-template .col-label {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.iterator-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 5px;
  border: 1px solid black;
  box-shadow: 1px 2px 3px black;
}
.iterator-wrapper.bundling {
  animation: 1s linear infinite bundle_blink_effect;
}

.iterator-wrapper.selected {
  outline: 5px solid dodgerblue;
}

.iterator-wrapper.dark {
  border-color: lightgray;
  box-shadow: 1px 2px 3px gray;
}

.iterator-wrapper .header {
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
  padding: 0 5px;
  background-color: white;
  border-bottom: 1px solid lightgray;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.iterator-wrapper .header .title {
  display: flex;
  gap: 10px;
  font-size: 20px;
  font-weight: bold;
}

.iterator-wrapper .header .title > * {
  display: flex;
  align-items: center;
}
.iterator-wrapper .header div:last-child {
  display: flex;
  gap: 15px;
}

.iterator-wrapper .header .indicator {
  border: 1px solid gray;
  border-radius: 3px;
  padding: 0 15px;
  font-size: 15px;
}

.iterator-wrapper .header .indicator:hover {
  background-color: gray;
  color: white;
}

.iterator-wrapper .header button {
  border: none;
  background-color: white;
}

.iterator-wrapper .option {
  height: 30px;
  width: 100%;
  background-color: #f2f2f3;
  font-size: 15px;
  padding-left: 30px;
  display: flex;
  align-items: center;
}

.node-group.over {
  background-color: #3c3c3caa;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
}

.iterator-wrapper .handle-wrapper {
  visibility: hidden;
}

.iterator-wrapper:hover .handle-wrapper {
  visibility: visible;
}

.connector-validation-wrapper .validation-button {
  width: 100%;
  height: 150px;
  border-radius: 5px;
}

.react-flow__resize-control.handle {
  width: 25px !important;
  height: 25px !important;
}

.flow-editor-wrapper .node-comment {
  content: "comment";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: white;
  border-radius: 5px;
  background-color: #344e2dcc;
}

.flow-editor-wrapper .node-invalid {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: white;
  border-radius: 5px;
  background-color: #ff3c00ce;
  flex-direction: column;
}

.process-popup .process-detail .wrapper {
  width: 100%;
  height: 100%;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 20px;
}

.process-popup .process-detail .wrapper .process-desc {
  width: 100%;
  height: 250px;
  border: 1px solid lightgray;
  border-radius: 5px;
  white-space: pre-wrap;
  word-break: keep-all;
  padding: 5px;
}

.program-id-label.prev-service {
  display: flex;
  gap: 5px;
}

.program-id-label.prev-service .service-name {
  cursor: pointer;
}

.program-id-label.prev-service .service-name:hover {
  color: gray;
}

.program-id-label.current-service {
  font-weight: 700;
}

.workflow-modal-template .exception .title {
  border-bottom: 1px solid dodgerblue;
}

li .cm-completionIcon {
  width: auto !important;
}

/************** Debug Expression ********************/
.debug-expression {
  position: absolute;
  top: 0px;
  right: 0;
  width: 450px;
  border: 1px solid rgb(235, 235, 235);
  height: calc(100vh - 80px);
  z-index: 1;
  transition: 0.5s;
  background-color: white;
}
.debug-expression.hidden {
  right: -450px;
  transition: 0.5s;
}

.debug-expression .tab-wrapper {
  display: flex;
  width: 100%;
  height: 30px;
  position: relative;
}
.debug-expression .tab-wrapper .tab {
  padding: 3px 20px;
  border: 1px solid #3c3c3c;
  border-bottom: none;
  cursor: pointer;
}
.debug-expression .tab-wrapper .tab:hover {
  background-color: rgb(170, 170, 170);
}

.debug-expression .tab-wrapper .tab:first-child {
  border-top-left-radius: 5px;
}
.debug-expression .tab-wrapper .tab:last-child {
  border-top-right-radius: 5px;
}
.debug-expression .tab-wrapper .tab.selected {
  background-color: #3c3c3c;
  color: lightgray;
  transition: 0.3s;
}
.debug-expression .tab-wrapper .close {
  position: absolute;
  transition: 0.3s;
  right: 0;
  bottom: 0;
  padding: 3px 5px;
  border: 1px solid #3c3c3c;
  border-bottom: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  cursor: pointer;
}

.debug-expression .variable {
  width: 100%;
  height: calc(100% - 30px);
  border: 1px solid #3c3c3c;
}

.debug-expression .variable .variable-list {
  width: 100%;
  height: 60%;
  overflow: auto;
}

.debug-expression .variable .variable-list .variable-list-row {
  display: grid;
  grid-template-columns: 5fr 5fr 2fr;
  border-bottom: 1px solid lightgray;
}

.debug-expression .variable .variable-list .variable-list-row.contents:hover {
  background-color: #3c3c3c;
  color: lightgray;
  cursor: pointer;
}

.debug-expression
  .variable
  .variable-list
  .variable-list-row
  .variable-list-cell {
  padding: 5px;
  padding-left: 15px;
  background-color: transparent;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.debug-expression .variable .variable-list .variable-list-row.selected {
  background-color: #3c3c3c;
  color: lightgray;
}

.debug-expression
  .variable
  .variable-list
  .variable-list-row.header
  .variable-list-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  padding: 0;
}

.debug-expression .variable .variable-list .breakpoint-list-row {
  display: grid;
  grid-template-columns: 1fr 2fr 3fr 3fr 1fr;
  border-bottom: 1px solid lightgray;
}

.debug-expression .variable .variable-list .breakpoint-list-row:hover {
  background-color: #3c3c3c;
  color: white;
  cursor: auto;
}

.debug-expression .variable .variable-list .breakpoint-list-row.other {
  font-style: italic;
  color: lightgray;
}

.debug-expression
  .variable
  .variable-list
  .breakpoint-list-row.header
  .variable-list-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  padding: 0;
}
.debug-expression
  .variable
  .variable-list
  .breakpoint-list-row
  .variable-list-cell {
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 5px 0;
}
.debug-expression .variable-list .variable-list-cell button {
  border: none;
  outline: none;
  background: none;
}

.debug-expression .variable .variable-console {
  width: 100%;
  height: calc(40% - 31.25px);
  border-top: 1px solid #3c3c3c;
  padding: 10px;
  overflow: auto;
}

.debug-expression .variable .variable-console textarea {
  width: 100%;
  height: 100%;
  border: none;
  resize: none;
}

/**/
.debug-expression .expression {
  width: 100%;
  height: calc(100% - 30px);
  border: 1px solid #3c3c3c;
}

.debug-expression .expression .variable-list {
  width: 100%;
  height: 60%;
  overflow: auto;
}

.debug-expression .expression .variable-list .variable-list-row {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  border-bottom: 1px solid lightgray;
}

.debug-expression .expression .variable-list .variable-list-row.contents:hover {
  background-color: #3c3c3c;
  color: lightgray;
  cursor: pointer;
}

.debug-expression
  .expression
  .variable-list
  .variable-list-row
  .variable-list-cell {
  padding: 5px;
  padding-left: 15px;
  background-color: transparent;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
}

.debug-expression .expression .variable-list .variable-list-row.selected {
  background-color: #3c3c3c;
  color: lightgray;
}

.debug-expression
  .expression
  .variable-list
  .variable-list-row.header
  .variable-list-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  padding: 0;
}

.debug-expression .expression .variable-console {
  width: 100%;
  height: calc(40% - 32px);
  border-top: 1px solid #3c3c3c;
  padding: 10px;
}

.debug-expression .expression .variable-console textarea {
  width: 100%;
  height: 100%;
  border: none;
  resize: none;
}

.debug-expression .expression .variable-input {
  width: 100%;
  height: 32px;
}

.debug-expression .variable .process-traced {
  padding: 5px;
  width: 100%;
  border-bottom: 1px solid black;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
}
.debug-expression .variable .process-traced:hover {
  background-color: #3c3c3c;
  color: whitesmoke;
  cursor: pointer;
}

.debug-console-wrapper {
  position: absolute;
  bottom: 0px;
  left: 0;
  height: 400px;
  width: 100%;
  border-top: 1px solid lightgray;
  z-index: 1;
}

.debug-console-wrapper.hidden {
  display: none;
}

.debug-console-wrapper .header {
  display: flex;
  justify-content: space-between;
  height: 30px;
  background-color: white;
  align-items: center;
  padding: 0 15px;
  cursor: row-resize;
}

.debug-console-wrapper .header button {
  background-color: transparent;
  border: none;
  outline: none;
}
.debug-console-wrapper .terminal-console {
  background-color: #3c3c3c;
  width: 100%;
  height: 100%;
  color: lightgray;
  resize: none;
  padding: 0;
  margin: 0;
}

.workflow-cs-wrapper {
  background: #3c3c3c;
  width: 300px;
  height: 100%;
  position: absolute;
  right: -300px;
  top: 0;
  z-index: 1;
  transition: 0.3s;
}

.workflow-cs-wrapper .resize-btn {
  position: absolute;
  top: 0;
  left: -15px;
  width: 15px;
  background-color: #3c3c3c;
  height: 35px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: pointer;
  display: none;
  align-items: center;
}

.workflow-cs-wrapper .resize-btn.show {
  display: flex;
}

.workflow-cs-wrapper.show {
  right: 0;
}

.workflow-cs-wrapper .workflow-cs-title {
  color: white;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  background-color: #1e282c;
}

.workflow-cs-wrapper .workflow-cs-title .file-name {
  max-width: 50%;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
}

.workflow-cs-wrapper .workflow-cs-list {
  height: 95%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px;
}
.workflow-cs-wrapper .workflow-cs-list .cs-service-wrapper {
  height: 37px;
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 20px 180px 70px;
  gap: 5px;
  padding: 5px 2px;
}

.workflow-cs-wrapper .workflow-cs-list .cs-service-wrapper.selected {
  background-color: rgba(211, 211, 211, 0.557);
  border-radius: 3px;
}

.workflow-cs-wrapper .workflow-cs-list .cs-service-wrapper .service-number {
  color: white;
  display: flex;
  align-items: center;
}
.workflow-cs-wrapper .workflow-cs-list .cs-service-wrapper .service-name {
  display: flex;
  height: 100%;
  align-items: center;
  background: white;
  border-radius: 3px;
  cursor: pointer;
}
.workflow-cs-wrapper .workflow-cs-list .cs-service-wrapper .service-name > div {
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 3px;
}
.workflow-cs-wrapper .workflow-cs-list .cs-service-wrapper .service-edit {
  display: flex;
  gap: 3px;
}

.cs-log-console-grid {
  background: #3c3c3c;
  height: 100%;
}
.cs-log-console-grid .cs-log-console-grid-row {
  display: grid;
  grid-template-columns: 150px 200px auto 150px 150px;
  min-height: 35px;
  background: rgb(242, 242, 242);
  gap: 10px;
  border-bottom: 1px dotted #c0bcc8;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}
.cs-log-console-grid .cs-log-console-grid-row > div {
  width: 100%;
  height: 100%;
  border-left: 1px solid lightgray;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.cs-log-console-grid .cs-log-console-grid-row.row-header {
  border-bottom: 1px solid black;
  height: 30px;
  background: lightgray;
}
.cs-log-console-grid .cs-log-console-grid-row.row-header > div {
  justify-content: center;
}

/******************************* Bundling Node tab *****************************/

.workflow-bundle-tab-wrapper {
  position: absolute;
  top: 30px;
  left: calc(50% + 200px);
  background-color: white;
  border: 1px solid gray;
  border-radius: 5px;
  box-shadow: 1px 1px 3px black;
  width: 700px;
  z-index: 1;
}
.workflow-bundle-tab-wrapper > div {
  padding: 0 5px;
}

.workflow-bundle-tab-wrapper .node {
  width: 250px;
  height: 30px;
  display: flex;
  align-items: center;
  border: 1px solid lightgray;
  border-radius: 3px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  font-weight: bold;
}

.workflow-process-node-wrapper.bundle .workflow-process-node {
  font-size: 25px;
}

.workflow-bundle-modal-wrapper {
  position: absolute;
  top: 30px;
  left: calc(50% + 200px);
  background-color: white;
  border: 1px solid gray;
  border-radius: 5px;
  box-shadow: 1px 1px 3px black;
  width: 700px;
}
