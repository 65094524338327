.content-title {
  font-size: 20px;
  font-weight: 600;
  margin: 10px 0px 10px 0px;
}

.add-btn {
  display: flex;
  margin: 10px 10px 10px auto;
}

.del-btn {
  float: right;
  margin: 3px 10px 3px 0;
}
