.edit-panel.sirius .editor-input-group .editor-component-wrap .btn-popup {
  margin: 0;
  width: 100%;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.edit-panel.sirius
  .editor-input-group
  .editor-component-wrap:has(+ div + .editor-component-wrap .btn-popup)
  .input-col {
  padding-right: 0px;
}

.edit-panel.sirius
  .editor-input-group
  .editor-component-wrap:has(+ div + .editor-component-wrap .btn-popup)
  .input-col
  input {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.edit-panel.sirius
  .editor-input-group
  .editor-component-wrap
  .builder-checkbox
  * {
  margin: 0;
  padding: 0;
}

.edit-panel.sirius
  .editor-input-group
  .editor-component-wrap:has(.editor-form-group) {
  width: 100%;
}

.edit-panel.sirius .form-group {
  & .form-label {
    &.required {
      & + input {
        background-color: #fff8e6;
      }
      & + .form-select {
        background-color: #fff8e6;
      }
      & + .input-group input {
        background-color: #fff8e6;
      }
    }
  }

  & div[readonly] {
    background-color: #f5f5f5 !important;
  }

  & input[readonly] {
    background-color: #f5f5f5 !important;
  }
}
